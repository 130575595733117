<template>
  <div class="contact">
    <My-new-nav ref="header"></My-new-nav>
    <div class="contact-container">
      <div class="contact-info">
        <div class="contact-info-title">医智诺竭诚为您服务</div>
        <div class="contact-info-item">
          <div>访问我们</div>
          <div>{{ $COMPANY_INFO.address }}</div>
          <div class="link " style="margin-top: 16px;" @click.stop="openMap">交通指引<i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="contact-info-item">
          <div>联系我们</div>
          <div class="phone">029-81774859</div>
          <div>echartnow@echartnow.com</div>
        </div>
        <div class="contact-info-item">
          <div>工作时间</div>
          <div>每周一至周五（法定节假日除外）</div>
          <div>8:30 AM ~ 18:00 PM</div>
        </div>
      </div>
      <div class="contact-from">
        <div class="title">留下您的联系方式</div>
        <div class="from-row">
          <input type="text" v-model="form.name" maxlength="50" placeholder="姓名">
        </div>
        <div class="from-row">
            <input type="text" v-model="form.email" maxlength="50" placeholder="邮箱">
        </div>
        <div class="from-row">
            <input type="text" v-model="form.phone" maxlength="11" placeholder="电话">
        </div>
        <div class="from-row">
            <input type="text" v-model="form.weChat" maxlength="50" placeholder="微信">
        </div>
        <div class="from-row">
            <input type="text" v-model="form.remarks" maxlength="50" placeholder="备注">
        </div>
        <div class="from-row btn-cont">
            <div class="btn" @click="submit">确定</div>
        </div>
      </div>
      
    </div>
    <div v-if="isShowMessage" class="message-box">
      <div class="message-info">提交成功</div>
    </div>
    <ecn-map v-if="isShowMap" @close="isShowMap = false"></ecn-map>
  </div>
</template>

<script>
import EcnMap from './ecnMap.vue';
export default {
  components: {
    EcnMap
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        weChat: '',
        remarks: '',
      },
      isShowMessage: false,
      isShowMap: false,
    }
  },
  methods: {
    openMap() {
      this.isShowMap = true
      // window.open(this.mapLink)
    },
    submit() {
      const form = this.form;
      if (form.name || form.email || form.phone || form.weChat || form.remarks) {
        // this.$message({
        //   message: '提交成功!',
        //   type: 'success',
        //   offset: 100
        // });
        this.isShowMessage = true
        setTimeout(() => {
          this.isShowMessage = false
        }, 1000)

        this.form = {
          name: '',
          email: '',
          phone: '',
          weChat: '',
          remarks: '',
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.contact {
    background: #fff;
    padding-top: 192px;
    input {
        background: none;
        outline: none;
        border: none;
    }

    .contact-container {
        padding: 0 54px;
        padding-bottom: 50px;
        background-color: #fff;

        .contact-from {
            .title {
                font-size: 54px;
                color: #0A0D10;
                font-weight: 500;
                line-height: 45px;
                margin: 120px 0 60px;
            }

            .from-row {
                width: 100%;

                input {
                    width: 100%;
                    border-bottom: 1px solid #C7CCDA;
                    font-size: 48px;
                    color: #0A0D10;
                    line-height: 48px;
                    padding-top: 54px;
                    padding-bottom: 16px;
                }

                input:focus {
                    border-bottom: 1px solid #6A7581;
                }

                input::placeholder {
                    color: #A7AEC2;
                }
            }

            .btn-cont {
                margin-top: 72px;
            }

            .btn {
              width: calc(100% - 80px);
              display: inline-block;
              font-size: 38px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 80px;
              background: #0D6EFD;
              border-radius: 8px;
              padding: 15px 40px;
              text-align: center;
              cursor: pointer;
            }

        }

        .contact-info {
            font-size: 48px;
            font-weight: 400;
            color: #0A0D10;

            .contact-info-title {
                font-size: 54px;
                font-weight: 500;
                margin-bottom: 36px;
            }

            .contact-info-item {
                padding-top: 36px;
                line-height: 60px;
                .link,.phone{
                  color: #2C68FF;
                }
                .link{
                  font-weight: 500;
                }
                >div:nth-child(1) {
                    font-size: 42px;
                    color: #A7AEC2;
                    margin-bottom: 16px;
                }
            }
        }
    }
    .message-box{
      width: 500px;
      height: 260px;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.6);
      position: fixed;
      top: 40%;
      left: calc(50% - 250px);
      .message-info{
        font-size: 42px;
        color: #fff;
        line-height: 260px;
        text-align: center;
      }
    }
}
</style>