<template>
  <div class="product-pharmacy"> 
     <newsHeader></newsHeader>
     <div class="">
       <div class="view-width flexRowBetFir padding-item">
         <div class="product-img"><img class="img1" src="../../assets/image/product/pharmacy/project.png" alt="专业化药房系统"></div>
         <div class='flexColFirCen'>
           <div class="project-name margin-top-87">专业化药房系统</div>
           <div class="product-description">专业化药房系统，提供智慧化药店管理服务，满足药店日常电子处方开立审核，进行专业化用药咨询和个性化患者管理服务，让药房更智能。</div>
         </div>
       </div>
       <div class="bg-gray">
          <div class="view-width padding-item11">
            <ecn-title title="重构医疗产业价值服务链"></ecn-title>
            <div class="model-item flexRowBetFir padding-item1">
              <div class='flexColFirCen'>
                <div class="project-name margin-top-131">智慧化药店管理服务</div>
                <div class="product-description">集进、销、存、财务、统计分析、营销管理为一体，满足终端日常管理，上云更安全。</div>
              </div>
              <div class="product-img"><img class="img2" src="../../assets/image/product/pharmacy/model1.png" alt="学科化专家团队建设"></div>
            </div>
          </div>
       </div>
       <div class="view-width flexRowBetCen card-list">
         <div class="card-item" v-for="(item, index) in cardList">
           <div class='flexColFirCen'>
             <div class="project-name">{{item.title}}</div>
             <div class="product-description">{{item.description}}</div>
           </div>
         </div>
       </div>
     </div>
     <My-new-footer></My-new-footer>
  </div>
</template>

<script>
import newsHeader from '@/views/newsHeader/index'
export default {
    name:'product-pharmacy',
    components: {
        newsHeader
    },
    data(){
        return{
          cardList: [
            {
              title: "电子处方平台服务",
              description: "满足药店开方审方需求，平台联动医生开具处方，提高药房的服务水平，服务更高效。",
            },
            {
              title: "个性化患者管理服务",
              description: "针对药店的入店客户进行全生命周期的患者管理服务，自定义分组和标签，提升药店服务质量。",
            },
            {
              title: "专业化用药咨询服务",
              description: "患者与药房药师在线即时咨询，针对性进行医师、药品推荐，提升药店的专业化服务水平。",
            }
          ]
        }
    },
    mounted(){
       
    },
    methods: {
       
    }  
}
</script>

<style lang="scss" scoped>
  .product-pharmacy{
    font-family: SourceHanSansCN-Regular;
    padding-top: 74px;
    div{
      box-sizing: border-box;
    }
    .margin-top-87{
      margin-top: 87px;
    }
    .margin-top-131{
      margin-top: 131px;
    }
    .padding-item{
      padding: 88px 72px 72px 62px;
    }
    .padding-item1{
      padding: 36px 46px 0 72px;
    }
    .padding-item11{
      padding: 88px 0 112px;
    }
    .project-name{
      width: 445px;
      font-family: YouSheBiaoTiHei;
      color: #212121;
      line-height: 70px;
      margin-bottom: 22px;
      font-size: 60px;
    }
    .product-description{
      width: 445px;
      font-weight: 400;
      font-size: 20px;
      color: #212121;
      font-family: PingFangSC-Regular, PingFang SC;
      line-height: 32px;
      text-align: justify;
    }
    .model-item{
      .project-name{
        width: 462px;
        text-align: left;
        font-size: 28px;
        line-height: 30px;
      }
      .product-description{
        width: 462px;
        text-align: justify;
      }
    }
    .flexRowBetCen{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .flexRowBetFir{
      display: flex;
      justify-content: space-between;
    }
    .flexColFirCen{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .flexColCen2{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .product-img{
      img{
        display: block;
      }
      .img1{
        width: 692px;
        height: 532px;
      }
      .img2{
        width: 641px;
        height: 503px;
      }
      
    }
    .ecn-title ::v-deep {
      .title{
        font-size: 28px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: normal;
        color: rgba(0,0,0,0.9);
        letter-spacing: 3px;
      }
    }
    .bg-gray{
      background-color: #F9F9FD;
    }
    .card-list{
      box-sizing: border-box;
      padding: 120px 0 120px 3px;
      .card-item{
        width: 408px;
        height: 560px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        box-shadow: 0 1px 10px 1px #ccc;
        border-radius: 14px;
        .project-name{
          width: 320px;
          text-align: left;
          font-size: 28px;
          line-height: 30px;
        }
        .product-description{
          width: 320px;
          text-align: justify;
          margin-bottom: 60px;
        }
      }
      .card-item:nth-of-type(1){
        background: url("../../assets/image/product/pharmacy/model2.png") no-repeat center;
        background-size: 100% 100%;
      }
      .card-item:nth-of-type(2){
        background: url("../../assets/image/product/pharmacy/model3.png") no-repeat center;
        background-size: 100% 100%;
      }
      .card-item:nth-of-type(3){
        background: url("../../assets/image/product/pharmacy/model4.png") no-repeat center;
        background-size: 100% 100%;
      }
    }
  }
</style>