<template>
  <div class="position-info-box main-container" @scroll="scroll">
    <My-new-nav ref="header" class="header-banner" :isHome='false'></My-new-nav>
    <div class="view-width view-pt-80 position-info">
      <div class="position-title">{{ data.jobName }}</div>
      <div class="position-address">
        <span class="pisition-title-address">{{ data.address ? data.address : '地点不限'}}</span>
        <span class="vertival-line"></span>
        <span class="pisition-title-number">{{ data.recruitNumber ? data.recruitNumber : '人数不限'}}</span>
        <span class="vertival-line"></span>
        <span class="pisition-title-culture">{{ data.degree ? data.degree : '学历不限'}}</span>
      </div>
      <div class="position-desc">
        <div class="position-desc-title position-content-title">职位描述</div>
        <div class="position-content-box">
         {{data.duty}}
        </div>
      </div>

      <div class="position-require">
        <div class="position-require-title position-content-title">任职要求</div>
        <div class="position-content-box">
          {{data.requireInfo}}
        </div>
      </div>
      <div class="position-company">
        <div class="position-company-title position-content-title">公司信息</div>
        <div class="position-content-box">
          <div class="company-phone position-content-label">联系方式：029-81123622</div>
          <div class="company-email position-content-label">简历邮箱：hr@echartnow.com</div>
          <div class="company-phone position-content-label">邮箱简历投递格式：附件名字+应聘岗位+工作地点，例：张三+陕西商务岗位+西安。</div>
          <div class="company-address position-content-label">集团办公地点：陕西省西安市高新区锦业一路6号永利金融中心14楼1401-02-03。</div>
        </div>
      </div>
    </div>
    <My-new-footer></My-new-footer>
  </div>
</template>
<script>
import scrollHeaderStyle from "@/views/newJoinus/utils";

import { queryPosition } from "../../../../common/api.js"
export default {
  components:{
  },
  data() {
    return {
      pageType: "positionInfoPage",
      positionId: '',
      data: {//职位数据
        title: '',
        address: '',
        number: '',
        culture: '',
        jobDesc: '',
        jsbRequire: '',
      },
    }
  },
  mounted() {
    this.setHeaderStyle()
    this.positionId = this.$route.query.positionid;
    this.queryPositionInfo()
    this.$store.commit("changeActiveMenu", '/joinUs');
  },
  watch:{
    $route(to){
      if(to.query && to.query.positionid){
        this.positionId = this.$route.query.positionid;
        this.queryPositionInfo()
      }
    },
  },
  methods: {
    scroll(e){
      let context = this;
      scrollHeaderStyle(e.srcElement.scrollTop, context, this.pageType )
    },
    queryPositionInfo(){
      let params = {
       id: this.positionId
      }
      queryPosition(params).then((res) => {
        if(res?.responseCode == 0){
          this.data = {...res.responseBody}
        }
      })
    },
    setHeaderStyle() {
      let option = {
        backgroundColor:'',
        navShow:'',
        textColor:'',
        activeColor:'',
        isTransparent:'',
      }
      option.backgroundColor ='#ffffff'
      option.navShow = true
      option.textColor ='#91969c'
      option.activeColor ='#000'
      option.isTransparent = false
      this.$refs.header.set(option)
    }
  }
}
</script>
<style lang="scss" scoped>
.position-info-box {
  background-color: #f9f9fd;
  //overflow-x: hidden;
  letter-spacing: 1px;
  .header-banner{
    height: 75px;
  }
  .position-info{
    padding-bottom: 40px;

    .position-title{
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #212121;
    }
    .position-address{
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #52647C;
      margin-top: 8px;
      margin-bottom: 40px;

      .pisition-title-address{
        display: inline-block;
        max-width: 170px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: bottom;
      }

      .vertival-line{
        display: inline-block;
        width: 1px;
        height: 11px;
        border-right: 1px solid #52647C;
        margin: 0 10px;
      }
    }
    .position-content-title{
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #212121;
      margin-bottom: 19px;
    }
    .position-content-label{
      margin-bottom: 24px;
    }
    .position-content-box{
      margin-bottom: 40px;
      white-space: pre-line;
      font-size: 16px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #333333;
      line-height: 24px;
    }
  }
}
</style>