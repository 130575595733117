<template>
  <div class="footer-bigbox">
    <div class="footer-box">
      <div class="footer-right">
        <div class="foo">
          <p>友情链接</p>
          <i>
            <a target="_Blank" href="https://www.nmpa.gov.cn/"
              >国家药品监督管理局</a
            >
          </i>
        </div>
        <div class="foo">
          <p>产品</p>
          <!-- <i>数字化运营系统</i>
          <i>领医未来</i>
          <i>专业化药房</i>
          <i>K7领客</i>
          <i>医智诺就医助手</i> -->
          <i><a  @click="routerJump('/product')">苁蓉-数字化运营平台</a></i>
          <i><a  @click="routerJump('/projectApp')">领医未来</a></i>
          <i><a  @click="routerJump('/productPatient')">医智诺就医助手</a></i>
          <i><a  @click="routerJump('/productPharmacy')">专业化药房</a></i>
          <!-- <div>数字化运营系统</div>
          <div>领医未来</div>
          <div>专业化药房</div>
          <div>K7领客</div>
          <div>医智诺就医助手</div> -->
        </div>
        <div class="foo">
          <p>解决方案</p>
          <!-- @click="routerJump('/app/1')" -->
          <i><a  @click="routerJump('/operationSolution')">数字化运营解决方案</a></i>
          <i><a  @click="routerJump('/projectSolution')">数字化项目管理解决方案</a></i>
          <i><a  @click="routerJump('/ecologicalSolution')">数字化运营生态解决方案</a></i>
        </div>
        <!-- <div class="foo">
          <p>新闻中心</p>
          <i><a  @click="routerJump('/newsCenter')">医智诺新闻动态</a></i>
        </div> -->
        <div class="foo">
          <p>关于我们</p>
          <i><a  @click="routerJump('/aboutUs')">集团介绍</a></i>
          <i><a  @click="routerJump('/culture')">企业文化</a></i>
          <i><a  @click="routerJump('/contact')">联系我们</a></i>
          <!-- <i><a  @click="routerJump('/brand')">"医智诺"品牌</a></i> -->
        </div>
        <div class="foo">
          <p>加入我们</p>
          <i><a  @click="routerJump(`/joinUs?showPoster=${ Date.now() }`)">校园招聘</a></i>
          <i><a  @click="routerJump('/socialRecruit')">社会招聘</a></i>
        </div>
        <!-- <div class="foo">
          <div class="attention">
            <img src="../../assets/image/code.png" alt="">
          </div>
          <div class="txt">微 信 关 注 我 们</div>
        </div> -->
      </div>
    </div>
    <div class="content-backups" style="text-align: center;">
      <div class="content-backups-info">
        <span>{{ $COMPANY_INFO.address }}&nbsp;&nbsp;&nbsp;&nbsp;备案号：<a class="footer-icp" href="https://beian.miit.gov.cn" target="_blank">{{ $COMPANY_INFO.icp }}</a></span>
        <!-- <span style="margin-left: 20px">备案号：<a class="footer-icp" href="https://beian.miit.gov.cn" target="_blank">陕ICP备18017834号-1</a>  |  (陕)网械平台备字〔2019〕第00002号
          互联网药品信息服务资格证书编号：（陕)-经营性-2018-0003</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import "../../assets/styles/footer.scss";
import "../../assets/styles/public.scss";
export default {
  methods: {
    routerJump(m) {
      this.$router.push({ path: m });
      this.$store.commit("changeActiveMenu", m);
      window.scrollTo(0, 0);
    },
  },
};
</script>
  
<style lang="scss"  scoped>
  .footer-right {
    a {
      cursor: pointer;
    }
  }
  .footer-icp{
    color: inherit;
    &:hover{
      color: rgba(13, 110, 253, 0.9);
    }
  }
</style>
