
import Vue from 'vue'
import Vuex from 'vuex'
 
Vue.use(Vuex)
 
const state = {
  activeMenu: '/'
}
 
const mutations = {
    changeActiveMenu(state,n){ 
      state.activeMenu = n
      console.log(state.activeMenu,'state.activeMenu')
    }
}
const actions = {

}
export default new Vuex.Store({
  state,
  mutations,
  actions
});