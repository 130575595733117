<template>
  <div class="ecn-map" @touchmove.stop @touch.stop>
    <div class="close" @click.stop="close"><i class="el-icon-close"></i></div>
    <div>
        <baidu-map :center="center" zoomToAccuracy='true' :zoom="zoom" @ready="handler" class="map-container"
            @click="getClickInfo"></baidu-map>
    </div>
    <div class="address" @click.stop="openBaiduMap">
      <div class="address-info">
        <div>陕西医智诺集团</div>
        <div>{{ $COMPANY_INFO.address }}</div>
      </div>
      <div class="address-icon">
        <img src="../../assets/image/Mobile/navigation.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      center: this.$COMPANY_INFO.location,
      zoom: 20,
      // mapLink: 'http://api.map.baidu.com/geocoder?location=108.890299,34.196709&address=陕西医智诺信息科技有限公司&output=html&src=www.echartnow.com'
      mapLink: `http://api.map.baidu.com/geocoder?location=${this.$COMPANY_INFO.location.lng},${this.$COMPANY_INFO.location.lat}&address=${this.$COMPANY_INFO.address}&output=html&src=www.echartnow.com`
    }
  },
  methods: {
    handler({ BMap, map }) {
      var point = new BMap.Point(this.center.lng, this.center.lat)
      map.centerAndZoom(point, 20)
      var marker = new BMap.Marker(point) // 创建标注
      map.addOverlay(marker) // 将标注添加到地图中
    },
    getClickInfo(e) {
      this.center.lng = e.point.lng
      this.center.lat = e.point.lat
    },
    openBaiduMap() {
      location.href = this.mapLink
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.ecn-map{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10000;
}
.close{
  font-size: 70px;
  color: #333;
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 10005;
}
.map-container {
  width: 100%;
  height: 100vh;
  // transform: scale(2, 2);
}
.address{
  width: 100%;
  height: 315px;
  background-color: #fff;
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 10005;
  .address-info{
    color: #0A0D10;
    width: calc(100% - 300px);
    padding: 52px 0 52px 36px;
    &>div:first-child{
      font-size: 48px;
      line-height: 100px;
      font-weight: 700;
    }
    &>div:last-child{
      font-size: 36px;
    }
  }
  .address-icon {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 120px;
      height: 120px;
    }
  }
}
</style>