<template>
  <div class="mobile-footer">
    <div class="mobile-footer-link">
      <div style="display:flex;justify-content:space-between;height:125px;line-height:125px;"><span>友情链接</span><span> <i><a target="_Blank" href="https://www.nmpa.gov.cn/" style="color:#ccc;font-style:normal;">国家药品监督管理局</a></i></span></div>
      <el-collapse  v-model="activeNames" >
        <el-collapse-item title="产品" name="1">
          <div class="title top" @click="routerJump('/developing_mobile')">苁蓉-数字化运营平台</div>
          <div class="title" @click="routerJump('/developing_mobile')">领医未来</div>
          <div class="title" @click="routerJump('/developing_mobile')">医智诺就医助手</div>
          <div class="title bottom" @click="routerJump('/developing_mobile')">专业化药房</div>
        </el-collapse-item>
        <el-collapse-item title="解决方案"  name="2">
          <div class="title top" @click="routerJump('/developing_mobile')">数字化运营解决方案</div>
          <div class="title bottom" @click="routerJump('/developing_mobile')">数字化项目管理解决方案</div>
          <div class="title bottom" @click="routerJump('/developing_mobile')">数字化运营生态解决方案</div>
        </el-collapse-item>
        <el-collapse-item title="新闻中心" name="3" style="display: none;">
          <div class="title bottom" @click="routerJump('/developing_mobile')">医智诺新闻动态</div>
        </el-collapse-item>
        <el-collapse-item title="关于我们" name="4">
          <div class="title top" @click="routerJump('/aboutUs_mobile')">集团介绍</div>
          <div class="title" @click="routerJump('/culture_mobile')">企业文化</div>
          <div class="title bottom" @click="routerJump('/contact_mobile')">联系我们</div>
        </el-collapse-item>
        <el-collapse-item title="加入我们"  name="5" class='icon-no'>
<!--          <div class="title top" @click="routerJump('/developing_mobile')">校园招聘</div>-->
<!--          <div class="title bottom" @click="routerJump('/developing_mobile')">社会招聘</div>-->
        </el-collapse-item>
        <div class="el-collapse-item__header follow-us" style="border-bottom: 0;">
          关注我们
          <img src="../../assets/image/Mobile/wechat.png" alt="" @click="isShowQrcode = true">
          <img src="../../assets/image/Mobile/sinawb.png" alt="" @click="linkWb">
        </div>
      </el-collapse>

    </div>
    <div class="mobile-footer-bottom">
      <div>{{ $COMPANY_INFO.address }}</div>
      <div>备案号：<a class="footer-icp" href="https://beian.miit.gov.cn" target="_blank">{{ $COMPANY_INFO.icp }}</a></div>
      <!-- <div>备案号：<a class="footer-icp" href="https://beian.miit.gov.cn" target="_blank">陕ICP备18017834号-1</a> | (陕)网械平台备字 [2019] 第00002号</div> -->
      <!-- <div>互联网药品信息服务资格证书编号：(陕)-经营性-2018-0003</div> -->
    </div>
    <div v-if="isShowQrcode" class="dlg-view" @touchmove.stop @touch.stop>
      <div class="dlg-empty" @click.stop="isShowQrcode = false"></div>
      <div class="dlg-content">
        <img src="../../assets/image/code.png" height="600px" alt="">
        <span>请使用微信扫码打开</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      activeNames:[],
      isShowQrcode: false
    }
  },
  methods:{
    routerJump(m){
      let header = document.getElementById('app')
      header.scrollTop = 0
      this.$store.commit('changeActiveMenu',m)
      window.scrollTo(0,0)
      this.$router.push({path:m})
    },
    linkWb() {
      window.open('https://weibo.com/u/7722100895')
    }
  },
}
</script>

<style lang="scss" scoped>
.mobile-footer{
  widows: 100%;
  padding: 120px 50px;
  background: #131313;
}
// .mobile-footer-address{
//   width: 100%;
//   height: 200px;
//   background: #131313;
//   display: flex;
//   align-items: center;
// }
// .mobile-footer-left-info{
//   // float: right;
//   font-size: 26px;
//   height: 125px;
//   padding:0 20px;
//   color: #ccc;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }
.mobile-footer-link{
  // padding: 0 20px;
  color: #ccc;
  font-size: 39px;
}
.mobile-footer-bottom{
  min-height: 150px;
  width: 100%;
  color: #ccc;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  // padding-bottom: 20px;
  margin-top: 15px;
  div{
    width: 100%;
    min-height: 55px;
    text-align: left;
    line-height: 45px;
    font-size: 26px;
    padding: 5px 0;
  }
  .footer-icp{
    color: inherit;
    &:hover{
      color: rgba(13, 110, 253, 0.9);
    }
  }
}
::v-deep .el-collapse{
  border-bottom: 0;
}
::v-deep .el-collapse-item__header{
  background-color: #131313;
  color: #ccc;
  font-size: 39px;
  height: 120px;
  // padding: 0 61px;
  border-bottom: 1px #ccc solid;
}
::v-deep .el-collapse-item__header.is-active {
  background-color: #131313;
  color: #ccc;

}
::v-deep .el-collapse-item__wrap {
  background-color: #131313;
  color: #ccc;
  text-indent: 45px;
  // padding: 0 61px;
}
::v-deep .el-collapse-item__wrap:last-child {
  border-bottom: 0;
}

.title{
  background: #131313;
  color: #ccc;
  font-size: 39px;
  height: 120px;
  line-height: 120px;
}

::v-deep .el-collapse-item__content {
  padding-bottom: 0;
  div {
    border-bottom: 1px #ccc solid;
    padding-bottom: 0;
  }
  div:last-child {
    border-bottom: 0;
  }
}

.follow-us{
  img{
    width: 60px;
    height: 48px;
    margin-left: 60px;
  }
}
.dlg-view, .dlg-empty{
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 2000;
}
.dlg-empty{
	background-color: rgba(0, 0, 0, 0.6);
}
.dlg-view{
  display: flex;
  justify-content: center;
  align-items: center;
}
.dlg-content{
	display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
	z-index: 2001;
  span{
    color: #fff;
    font-size: 42px;
    margin-top: 20px;
  }
}
::v-deep .icon-no .el-icon-arrow-right {
  display: none;
}
</style>
