<template>
  <div> 
      <!-- nav -->
      <!-- <My-nav v-if="!isMobile"></My-nav> -->
    <!-- banner -->
    <My-banner :bannerImg="bannerImg" v-if="!isMobile"></My-banner>
    <My-mobilBanner  :slideString="slideString" :bannerImg="bannerImg" id="nav" v-if="isMobile"></My-mobilBanner>
    <!-- tab-bar -->
    <div class="tab-bar" v-if="!isMobile">
        <div  class="tab-bar-li" v-for="(item,index) in tabList" :key="index" :class="{'tab_backgroundA': index == tabIndex}" @click="tabIndex = index">
            <img :src="index == tabIndex?item.liveIcon : item.icon"/>
            <span>{{item.title}}</span>
        </div>
    </div>
    <div  v-if="isMobile">
        <My-swiper-tab :tabList="tabList" key="product" :tabIndex="tabIndex" @changeTab="_changeTab"></My-swiper-tab>
    </div>
    <!-- ecn-box plate -->
    <transition name="el-fade-in-linear">
        <div v-show="tabIndex == 0">
                    <div class="ecn-box" >
                    <div class="ecn-title platform-biosphere" style="background-color:#F6FAFF;">
                    <h1>医智诺平台生态圈</h1>
                    <p>以人为本提升健康服务，跨界融合助力医疗创新</p>
                    </div>
                    <div class="ecn-img platform-biosphere" style="background-color:#F6FAFF;">
                    <img src="../../assets/image/ecn_plate_Img.png"/>
                    </div>
                </div>
        </div>
    </transition>
    <transition name="el-fade-in-linear">
        <div v-show="tabIndex == 3">
            <div class="ecn-box">
                <!-- <div  class="line"></div> -->
                <div class="ecn-title treatment" >
                <h1>占据诊疗服务第一入口</h1>
                <!-- <p>Five in One of Medical Education, Research, Management and Service</p> -->
                </div>
                <div class="ecn-img treatment">
                    <div :class="isMobile? 'mobile-treatment':'treatment'">
                        <ul>
                            <li>
                                <img src="../../assets/image/treat.png"/>
                                <div class="circls-lines">
                                    <span class="circle"></span>
                                    <span class="circl-line"></span>
                                </div>
                                <p>患者管理</p>
                            </li>
                            <li>
                                <img src="../../assets/image/treat2.png"/>
                                <div class="circls-lines">
                                    <span class="circle"></span>
                                    <span class="circl-line"></span>
                                </div>
                                <p>构建私域患者池</p>
                            </li>
                            <li>
                                <img src="../../assets/image/treat3.png"/>
                                <div class="circls-lines">
                                    <span class="circle"></span>
                                    <span class="circl-line"></span>
                                </div>
                                <p>全链路闭环线上诊疗服务场景</p>
                            </li>
                            <li>
                                <img src="../../assets/image/treat4.png"/>
                                <div class="circls-lines">
                                    <span class="circle"></span>
                                    <span class="circl-line"></span>
                                </div>
                                <p>学科建设</p>
                            </li>
                            <li>
                                <img src="../../assets/image/treat5.png"/>
                                <div class="circls-lines">
                                    <span class="circle"></span>
                                    <span class="circl-line"></span>
                                </div>
                                <p>数据可视化</p>
                            </li>
                            <li>
                                <img src="../../assets/image/treat6.png"/>
                                <div class="circls-lines">
                                    <span class="circle"></span>
                                    <span class="circl-line"></span>
                                </div>
                                <p>收益阳光化</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="ecn-title five-econ">
                    <h1>医教研管服五位一体</h1>
                    <!-- <p>Five in One of Medical Education, Research, Management and Service</p> -->
                </div>
                <div class="ecn-img five-econ">
                    <img src="../../assets/image/five_econ.png"/>
                </div>
                <div class="ecn-title future_manange">
                    <h1>领医未来</h1>
                </div>
                <div class="ecn-img future_manange">
                    <div :class="isMobile? 'mobile-future_manange':'future_manange'">
                        <ul>
                            <li>
                                <img src="../../assets/image/phone1.png"/>
                                <p>在线接诊</p>
                                <p>建立您的线上诊室</p>
                            </li>
                            <li>
                                <img src="../../assets/image/phone2.png"/>
                                <p>图文咨询</p>
                                <p>医患互动  实时沟通</p>
                            </li>
                            <li>
                                <img src="../../assets/image/phone3.png"/>
                                <p>会议培训</p>
                                <p>最新的科研医护消息</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <transition name="el-fade-in-linear">
        <div v-show="tabIndex == 2">
            <div class="ecn-box">
                <div class="ecn-title store">
                    <h1>打通诊疗最后一米 占据销售第一入口</h1>
                    <!-- <p>Get through the last meter of treatment, occupy the first entrance of sales</p> -->
                </div>
                <div class="ecn-img store">
                    <div :class="isMobile?'mobile-pharmacy': 'pharmacy'">
                        <ul>
                            <li>
                                <img src="../../assets/image/pharmacy1.png"/>
                                <span>激活存量</span>
                                <p>线上线下<br>药店业务场景联动</p>
                            </li>
                            <li>
                                <img src="../../assets/image/pharmacy2.png"/>
                                <span>提高患者复购率</span>
                                <p>助力药店链接医生与患者</p>
                            </li>
                            <li>
                                <img src="../../assets/image/pharmacy3.png"/>
                                <span>专业化药房解决方案</span>
                                <p>搭建数字化药房<br>提升产品议价能力</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="ecn-title pharmacy_manange">
                    <h1>药房后台管理系统展示</h1>
                </div>
                <div class="ecn-img pharmacy_manange">
                    <div  :class="isMobile?'mobile-pharmacy_manange':'pharmacy_manange'">
                        <div class="pharmacy_li"> 
                            <div class="pharmacy_manange_img">
                                <img src="../../assets/image/ph_manange5.png"/>
                            </div>
                            <div class="pharmacy_manange_desc">
                                <button>用户管理</button>
                                <p>用户关注量、用户基本信息<br>随访推送管理、评价管理</p>
                            </div>
                        </div>
                        <div class="pharmacy_li">
                            <div class="pharmacy_manange_desc">
                                <button>药品仓库管理</button>
                                <p>订单管理、药品信息、器械信息</p>
                            </div>
                            <div class="pharmacy_manange_img">
                                <img src="../../assets/image/ph_manange1.png"/>
                            </div>
                        </div>
                        <div class="pharmacy_li"> 
                            <div class="pharmacy_manange_img">
                                <img src="../../assets/image/ph_manange2.png"/>
                            </div>
                            <div class="pharmacy_manange_desc">
                                <button>药店信息管理</button>
                                <p>药店的基本信息<br>图片上传、药师资料上传</p>
                            </div>
                        </div>
                        <div class="pharmacy_li">
                            <div class="pharmacy_manange_desc">
                                <button>咨询回复</button>
                                <p>用药相关的咨询回复<br>投诉建议相关回复</p>
                            </div>
                            <div class="pharmacy_manange_img">
                                <img src="../../assets/image/ph_manange3.png"/>
                            </div>
                        </div>
                        <div class="pharmacy_li" style="margin-bottom:0;">
                            <div class="pharmacy_manange_img">
                                <img src="../../assets/image/ph_manange4.png"/>
                            </div>
                            <div class="pharmacy_manange_desc">
                                <button>科普管理</button>
                                <p>精准推送、在线发布、直播会议</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </transition>
    <transition name="el-fade-in-linear">
        <div v-show="tabIndex == 1">
            <div class="ecn-box">
                <!-- <div class="line"></div> -->
                <div class="ecn-title industry">
                <h1>数字化营销解决方案</h1>
                <!-- <p>Digital Marketing Solutions</p> -->
                </div>
                <div class="ecn-img industry">
                    <div :class="isMobile?'mobile-industry':'industry'" >
                        <ul>
                            <li>
                                <img src="../../assets/image/treat.png"/>
                                <div class="circls-lines">
                                    <span class="circle"></span>
                                    <span class="circl-line"></span>
                                </div>
                                <p>营销生态搭建</p>
                                <div class="intro-p">
                                    <p>代理资源匹配商业资源匹配连锁资源匹配专家资源匹配</p>
                                </div>
                            </li>
                            <li>
                                <img src="../../assets/image/treat2.png"/>
                                <div class="circls-lines">
                                    <span class="circle"></span>
                                    <span class="circl-line"></span>
                                </div>
                                <p>学科建设</p>
                                <div class="intro-p">
                                    <p>顶级专家省级专家处⽅医⽣团队医学研究</p>
                                </div>
                            </li>
                            <li>
                                <img src="../../assets/image/treat3.png"/>
                                <div class="circls-lines">
                                    <span class="circle"></span>
                                    <span class="circl-line"></span>
                                </div>
                                <p>价值服务</p>
                                <div class="intro-p">
                                    <p>数字化营销<br>场景搭建<br>服务端口<br>收益阳光化</p>
                                </div>
                            </li>
                            <li>
                                <img src="../../assets/image/treat4.png"/>
                                <div class="circls-lines">
                                    <span class="circle"></span>
                                    <span class="circl-line"></span>
                                </div>
                                <p>患者管理</p>
                                <div class="intro-p">
                                    <p>专家医师互动答疑专业患教内容主题直播</p>
                                </div>
                            </li>
                            <li>
                                <img src="../../assets/image/treat6.png"/>
                                <div class="circls-lines">
                                    <span class="circle"></span>
                                    <span class="circl-line"></span>
                                </div>
                                <p>线上推广</p>
                                <div class="intro-p">
                                    <p>专业健康<br>话题传播<br>分享讨论<br>学术咨询</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <transition name="el-fade-in-linear">
        <div v-show="tabIndex == 4">
            <div class="ecn-box">
                <div class="ecn-title operator">
                <h1>深挖客户潜力第一入口</h1>
                <!-- <p>Background management of Medical Operation Specialist</p> -->
                </div>
                <div class="ecn-img operator">
                    <div :class="isMobile? 'mobile-operator':'operator'">
                        <ul>
                            <li>
                                <img src="../../assets/image/operator1.png"/>
                                <p>处方流转锁定</p>
                            </li>
                            <li>
                                <img src="../../assets/image/operator2.png"/>
                                <p>合法身份<br>医学运营专员</p>
                            </li>
                            <li>
                                <img src="../../assets/image/operator3.png"/>
                                <p>收入来源多样化</p>
                            </li>
                            <li>
                                <img src="../../assets/image/operator4.png"/>
                                <p>销售转型<br>运营培训</p>
                            </li>
                            <li>
                                <img src="../../assets/image/operator5.png"/>
                                <p>全场景数字化管理工具</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="ecn-title k7_manange">
                    <h1>医学运营专员后台管理（K7领客）</h1>
                </div>
                <div class="ecn-img k7_manange">
                    <div :class="isMobile? 'mobile-k7_manange':'k7_manange'">
                        <ul>
                            <li>
                                <img src="../../assets/image/phone_k71.png"/>
                                <p>昨日交易</p>
                                <p>查看昨日交易金额及统计</p>
                            </li>
                            <li>
                                <img src="../../assets/image/phone_k72.png"/>
                                <p>订单管理</p>
                                <p>实时查看订单状态</p>
                            </li>
                            <li>
                                <img src="../../assets/image/phone_k73.png"/>
                                <p>我的患者</p>
                                <p>与我相关购买过药品的患者</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <transition name="el-fade-in-linear">
        <div v-show="tabIndex == 5">
            <div class="ecn-box">
                <div class="ecn-title store">
                    <h1>医智诺就医助手</h1>
                    <!-- <p>Digital Marketing Solutions</p> -->
                </div>
                <div class="ecn-img store">
                    <div :class="isMobile?'mobile-pharmacy': 'pharmacy'">
                        <ul>
                            <li>
                                <img src="../../assets/image/helper_security.png"/>
                                <span>轻松找到医生</span>
                                <p>无需下载APP</p>
                            </li>
                            <li>
                                <img src="../../assets/image/helper_fast.png"/>
                                <span>安全有保障</span>
                                <p>小病在线看，安全又方便</p>
                            </li>
                            <li>
                                <img src="../../assets/image/helper_online.png"/>
                                <span>线上复诊方便</span>
                                <p>轻松搞定麻烦的复诊 </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="ecn-title k7_manange">
                    <h1>医智诺就医助手产品展示</h1>
                    <!-- <p>Occupy the first entrance of medical services</p> -->
                </div>
                <div class="ecn-img k7_manange">
                    <div :class="isMobile?'mobile-k7_manange':'k7_manange'">
                        <ul>
                            <li>
                                <img src="../../assets/image/helper1.png"/>
                                <p>我的医生</p>
                                <p>我关注、签约的医生</p>
                            </li>
                            <li>
                                <img src="../../assets/image/helper2.png"/>
                                <p>线上图文咨询</p>
                                <p>与医生进行实时健康咨询</p>
                            </li>
                            <li>
                                <img src="../../assets/image/helper3.png"/>
                                <p>线上购药</p>
                                <p>下单购买，物流配送到家</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </transition>

    <!-- footer -->
    <!-- <My-footer v-if="!isMobile" ></My-footer>
    <My-mobilFooter v-if="isMobile"></My-mobilFooter> -->
  </div>
</template>

<script>
import "../../assets/styles/product.scss"
import "../../assets/styles/mobile.scss"
import 'element-ui/lib/theme-chalk/display.css';
import { isMobile } from '../../../common/util'

export default {
    name:'product',
    
    data(){
        return{
            isMobile: isMobile(),
            tabList: [
                {icon: require("../../assets/image/tab_icon1.png"),title:"平台生态圈",liveIcon: require("../../assets/image/tab_liveIcon1.png")},
                {icon: require("../../assets/image/tab_icon4.png"),title:"工业",liveIcon: require("../../assets/image/tab_liveIcon4.png")},
                {icon: require("../../assets/image/tab_icon3.png"),title:"药房",liveIcon: require("../../assets/image/tab_liveIcon3.png")},
                {icon: require("../../assets/image/tab_icon2.png"),title:"医生",liveIcon: require("../../assets/image/tab_liveIcon2.png")},
                {icon: require("../../assets/image/tab_icon5.png"),title:"医学运营人员",liveIcon: require("../../assets/image/tab_liveIcon5.png")},
                {icon: require("../../assets/image/tab_icon6.png"),title:"患者",liveIcon: require("../../assets/image/tab_liveIcon6.png")}
            ],
            bannerImg: '',
            bannerImgList: isMobile() ? [
                require("../../assets/image/banner_plate_2.png"),
                require("../../assets/image/banner_industry_2.png"),
                require("../../assets/image/banner_store_2.png"),
                require("../../assets/image/banner_doctor_2.png"),
                require("../../assets/image/banner_operator_2.png"),
                require("../../assets/image/banner_healthy_2.png"),
            ] : [
                require("../../assets/image/banner_plate.png"),
                require("../../assets/image/banner_industry.png"),
                require("../../assets/image/banner_store.png"),
                require("../../assets/image/banner_doctor.png"),
                require("../../assets/image/banner_operator.png"),
                require("../../assets/image/banner_healthy.png"),
            ],
            tabIndex: 0,
            slideString: false,
        }
    },
    watch:{
        '$route.params': function(val,oval){
           if (val.id == 1) {
               this.tabIndex = 0
               this.bannerImg = this.bannerImgList[0]

           } else if (val.id == 2) {
               this.tabIndex = 1
               this.bannerImg = this.bannerImgList[1]

           } else if (val.id == 3) {
               this.tabIndex = 2
               this.bannerImg = this.bannerImgList[2]

           } else if (val.id == 4) {
               this.tabIndex = 3
               this.bannerImg = this.bannerImgList[3]

           } else if (val.id == 5) {
               this.tabIndex = 4
               this.bannerImg = this.bannerImgList[4]

           } else if (val.id == 6) {
               this.tabIndex = 5
               this.bannerImg = this.bannerImgList[5]
           }
        },
        'tabIndex': function(val,oval){
            if (val == 0) {
               this.bannerImg = this.bannerImgList[0]

           } else if (val == 1) {
               this.bannerImg = this.bannerImgList[1]

           } else if (val == 2) {
               this.bannerImg = this.bannerImgList[2]

           } else if (val == 3) {
               this.bannerImg = this.bannerImgList[3]

           } else if (val == 4) {
               this.bannerImg = this.bannerImgList[4]

           } else if (val == 5) {
               this.bannerImg = this.bannerImgList[5]
           }
        }
    },
    mounted(){
       let m = this.$route.params
        if (m.id == 1){
            this.tabIndex = 0
            this.bannerImg = this.bannerImgList[0]
        } else if(m.id == 2){
            this.tabIndex = 1
            this.bannerImg = this.bannerImgList[1]
        } else if(m.id == 3){
            this.tabIndex = 2
            this.bannerImg = this.bannerImgList[2]
        } else if(m.id == 4){
            this.tabIndex = 3
            this.bannerImg = this.bannerImgList[3]
        } else if(m.id == 5){
            this.tabIndex = 4
            this.bannerImg = this.bannerImgList[4]
        } else if(m.id == 6){
            this.tabIndex = 5
            this.bannerImg = this.bannerImgList[5]
        }
    },
    
    methods: {
        _changeTab(m){
            this.tabIndex = m
        }
    }
        
}
</script>

<style scoped>
</style>