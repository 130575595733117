<template>
    <div class="abouts_solution solution-page main-container"  @scroll="scroll">
      <My-new-nav ref="header" :isHome='false'></My-new-nav>
      <div class="page-width solution-cont">
        <div class="banner">
          <div class="view-width">
            <div class="banner-title">数字化项目管理解决方案</div>
            <div class="banner-message">打通数字化转型供需与生态共建通道</div>
          </div>
        </div>
      </div>
      <div class="view-width view-pt-80 " >
        <ecn-title title="打通数字化转型供需与生态共建通道"></ecn-title>
        <div class="solution-path">
         <img :src="solutionImg" alt="" >
        </div>
      </div>
      <My-new-footer></My-new-footer>
    </div>
  </template>
  <script>
  import upGrading from '@/components/Upgrading/index'
  import newsHeader from '@/views/newsHeader/index'
  import scrollHeaderStyle from "../newJoinus/utils";
  export default {
    components:{
      upGrading,
      newsHeader
    },
    data(){
      return {
        pageType: "ecologicalSolutionPage",
        solutionImg: require("../../assets/image/solution/solution-path.png"),
      }
    },
    activated(){
      let context = this;
      scrollHeaderStyle(0, context, this.pageType )
    },
    methods:{
      scroll(e){
        //滚动条滚动的事件
        let context = this;
        scrollHeaderStyle(e.srcElement.scrollTop, context, this.pageType )
      },
    }
  }
  </script>
  <style scoped lang="scss">
  .solution-page {
    overflow-x: hidden;
    .solution-cont {
      background-image: url('../../assets/image/solution/solution-manage-zip.png');
      background-repeat: no-repeat;
      background-size: 100%;
      min-width: 1280px;
    }
    .banner {
      height: 520px;
    }
    .banner-title {
      font-size: 60px;
      font-family: YouSheBiaoTiHei;
      color: #FFFFFF;
      line-height: 78px;
      letter-spacing: 6px;
      text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
      padding-top: 204px;
    }
    .banner-message {
      font-size: 18px;
      font-family: SourceHanSansCN-Bold;
      color: #FFFFFF;
      line-height: 27px;
      letter-spacing: 9px;
      text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
      margin-top: 19px;
    }
    .ecn-title ::v-deep {
      .title{
        font-size: 28px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: normal;
        color: rgba(0,0,0,0.9);
        letter-spacing: 3px;
      }
    }
    .solution-path{
        width: 100%;
        margin-top: 80px;
        margin-bottom: 96px;
        img{
            display: block;
            margin:auto;
        }
    }
    .channel-bg-f2{
      background-color: #fff;
    }
}
  .has-border{
    border: 1px solid #D8D8D8;
  }
  .has-radius{
    border-radius: 12px;
  }
  .text-right{
    text-align: right;
  }
  </style>