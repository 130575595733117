<template>
<div>
    <My-banner :bannerImg ="bannerImg"></My-banner>
    
        <div class="contentDetail">
          <div class="content_top">
            <div class="content_top_title">
              {{data.jobName}}
            </div>
            <div class="content_top_tip">
              <span> &nbsp;&nbsp;</span>
            </div>            
          </div>
        </div>
        <div style="margin: 0 10px;">
        <div class="content_one">
          <div class="content_one_header">
            工作职责:
          </div>
          <ul>
            <li class="li__item" v-for="(item,index) in cutData(data.duty)" :key="index">
              {{item}}
            </li>
          </ul>
        </div>
        <div class="content_one">
          <div class="content_one_header">
            任职要求:
          </div>
            <ul>
            <li class="li__item" v-for="(item,index) in cutData(data.requireInfo)" :key="index">
              {{item}}
            </li>
          </ul>
        </div>
        <div class="content_one">
          <div class="content_one_header">
            工作地点
          </div>
            <ul>
            <li class="li__item">
              {{data.address}}
            </li>
          </ul>
        </div>
        <div class="content_one">
          <div class="content_one_header">
            公司信息
          </div>
          <ul>
            <li class="li__item" v-for="(item,index) in cutData(companyInfo)" :key="index">{{item}}</li>
          </ul>
        </div>
    </div>
  </div>  
</template>

<script>
import { queryPosition } from '../../../../../common/api'

export default {
  data(){
    return {
      bannerImg:  require("../../../../assets/image/Mobile/JoinUs.png"),
      id:0,
      data: {},
      companyInfo: '联系方式：029-81123622\r\n\
        简历邮箱：wangmei@echartnow.com\r\n\
        邮箱简历投递格式：附件名字+应聘岗位+工作地点，例：张三+ 陕西商务岗位+西安\r\n\
        集团办公地点：陕西省西安市高新区锦业一路6号永利金融中心14楼1401-02-03。'
    }
  },
  created(){
    // console.log(typeof(this.$route.query.id));
    this.id = Number(this.$route.query.id)
  },
  mounted() {
    let data = {
        id: this.id
    }
    queryPosition(data).then((res) => {
        if (res.responseCode == 0) {
          this.data = res.responseBody           
        }
    })
  },
  methods: {
    cutData(dt) {
      if (dt) {
        return dt.replace(new RegExp('\r\n','gm'),'<br />').replace(new RegExp('\n','gm'),'<br />').split('<br />')
      } else {
        return dt
      }      
    }
  }
}
</script>

<style lang="scss" scoped>
.contentDetail{
   box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
}
.content_top{
  width:100vw;
  margin-top:46px;
  .content_top_title{
    padding-left:33px;
    padding-top:21px;
    margin-bottom: 28px;
    height: 35px;
    font-size: 42px;
    font-family: SourceHanSansCN-Regular;
    font-weight:700;
    color: #333333;
    line-height: 28px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    }
    .content_top_tip{
    padding-left:33px;
    }
   .content_top_tip span{
    display: inline-block;
    margin-bottom: 0px;
    height: 2px;
    font-size: 28px;
    font-family: SourceHanSansCN-Regular;
    font-weight: 500;
    color: #666666;
    line-height: 6px;
   }
}
.content_one {
  padding:36px;
  font-size: 1.2em;  
  max-height: 100vh;
  .content_one_header{
    height: 35px;
    font-size: 34px;
    font-family: SourceHanSansCN-Regular;
    font-weight: 900;
    color: #333333;
    line-height: 35px;
    margin-bottom: 33px;
  }
  .li__item{
    font-size: 1.6em;
    font-family: SourceHanSansCN-Regular;
    font-weight: 400;
    color: #666666;
    margin-bottom:30px;
  }
}
</style>
