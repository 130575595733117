<template>
  <div>
        <My-banner :bannerImg ="bannerImg"></My-banner>
        <!-- tab-bar -->
        <div class="tab-bar" v-if="!isMobile">
            <div  class="tab-bar-li" v-for="(item,index) in tabList" :key="index" :class="{'tab_backgroundA': index == tabIndex}" @click="tabIndex = index">
                <img :src="index == tabIndex?item.liveIcon : item.icon"/>
                <span>{{item.title}}</span>
            </div>
        </div>
        <My-swiper-tab :tabList="tabList" v-else :tabIndex="tabIndex" @changeTab="_changeTab"></My-swiper-tab>
        <transition name="el-fade-in-linear">
            <div v-show="tabIndex == 0">
                <expriseIntro></expriseIntro>
            </div>
        </transition>
         <transition name="el-fade-in-linear">
            <div v-show="tabIndex == 1">
            <expriseCulture></expriseCulture>
            </div>
        </transition>
        <transition name="el-fade-in-linear">
            <div v-show="tabIndex == 2">
            <enterpriseNews></enterpriseNews>
            </div>
        </transition>
        <transition name="el-fade-in-linear">
            <div v-show="tabIndex == 3">
                <talentRecruitment></talentRecruitment>
            </div>
        </transition>
         <transition name="el-fade-in-linear">
            <div v-show="tabIndex == 4">
                <contact></contact>
            </div>
        </transition>
        <!-- footer -->
        <!-- <My-footer></My-footer> -->
  </div>
</template>

<script>
import "../../assets/styles/about_Us.scss"
import "../../assets/styles/public.scss"

import enterpriseNews from './components/enterprise_news.vue'
import expriseIntro from "./components/enterprise_intro.vue"
import expriseCulture from "./components/enterprise_culture.vue"
import talentRecruitment from "./components/talent_recruitment.vue"
import contact from "./components/contact.vue"
import { isMobile } from '../../../common/util'

export default {
    name:'aboutus',
    components: { expriseIntro ,expriseCulture,enterpriseNews,talentRecruitment,contact},
    data(){
        return{
            tabList: [
                {icon: require("../../assets/image/tab_icon4.png"),title:"企业简介",liveIcon: require("../../assets/image/tab_liveIcon4.png")},
                {icon: require("../../assets/image/tab_icon_us2.png"),title:"企业文化",liveIcon: require("../../assets/image/tab_liveIcon_us2.png")},
                {icon: require("../../assets/image/tab_icon_us3.png"),title:"新闻动态",liveIcon: require("../../assets/image/tab_liveIcon_us3.png")},
                {icon: require("../../assets/image/tab_icon_us4.png"),title:"人才招聘",liveIcon: require("../../assets/image/tab_liveIcon_us4.png")},
                {icon: require("../../assets/image/tab_icon_us5.png"),title:"联系我们",liveIcon: require("../../assets/image/tab_liveIcon_us5.png")},
            ],
            bannerImg: '',
            bannerImgList: isMobile() ? [
                require("../../assets/image/enterprise_banner1_2.png"),
                require("../../assets/image/enterprise_banner2_2.png"),
                require("../../assets/image/enterprise_banner3_2.png"),
                require("../../assets/image/enterprise_banner4_2.png"),
                require("../../assets/image/enterprise_banner5_2.png"),
            ] : [
                require("../../assets/image/enterprise_banner1.png"),
                require("../../assets/image/enterprise_banner2.png"),
                require("../../assets/image/enterprise_banner3.png"),
                require("../../assets/image/enterprise_banner4.png"),
                require("../../assets/image/enterprise_banner5.png"),
            ],
            tabIndex: 0,
            isMobile:isMobile()
        }
    },
    watch:{
        '$route.params': function(val,oval){
           if (val.id == 1) {
               this.tabIndex = 0
               this.bannerImg = this.bannerImgList[0]

           } else if (val.id == 2) {
               this.tabIndex = 1
               this.bannerImg = this.bannerImgList[1]

           } else if (val.id == 3) {
               this.tabIndex = 2
               this.bannerImg = this.bannerImgList[2]

           } else if (val.id == 4) {
               this.tabIndex = 3
               this.bannerImg = this.bannerImgList[3]

           } else if (val.id == 5) {
               this.tabIndex = 4
               this.bannerImg = this.bannerImgList[4]

           }
        },
        'tabIndex': function(val,oval){
            if (val == 0) {
               this.bannerImg = this.bannerImgList[0]
           } else if (val == 1) {
               this.bannerImg = this.bannerImgList[1]
           } else if (val == 2) {
               this.bannerImg = this.bannerImgList[2]
           } else if (val == 3) {
               this.bannerImg = this.bannerImgList[3]
           } else if (val == 4) {
               this.bannerImg = this.bannerImgList[4]
           } 
        }
    },
    mounted(){
        this.bannerImg= this.bannerImgList[0]
       let m = this.$route.params
        if (m.id == 1){
            this.tabIndex = 0
            this.bannerImg = this.bannerImgList[0]
        } else if(m.id == 2){
            this.tabIndex = 1
            this.bannerImg = this.bannerImgList[1]
        } else if (m.id == 3) {
            this.tabIndex = 2
            this.bannerImg = this.bannerImgList[3]
        } else if (m.id == 4) {
            this.tabIndex = 3
            this.bannerImg = this.bannerImgList[4]
        } else if(m.id == 5) {
            this.tabIndex = 4
            this.bannerImg = this.bannerImgList[5]
        }
    },
    methods:{
        _changeTab(m){
            this.tabIndex = m
        }
    }
}
</script>

<style>

</style>