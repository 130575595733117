<template>
  <div>
       <div class="main-newsdate">
            <div class="layui-container">
                <h1 style="text-align:center;margin:40px 0">关于“医智诺”品牌</h1>
                <p  class="brandP" >医智诺，陕西医智诺信息科技有限公司旗下品牌。研发了医学运营管理平台、专业化药房管理平台、学科建设运营品台、数字化营销平台、患者健康服务平台。致力于打造新医疗价值服务观。</p>
                <h2 class="brandH2" >品牌简介</h2>
                <p class="brandP">是香港上市公司华谊腾讯（HK00419）旗下的医疗集团，控股子公司。<span style="font-size: 14px;font-weight: bolder;">（https://baijiahao.baidu.com/s?id=1700002020104658722&wfr=spider&for=pc）</span>医智诺产品研发中心设立在古都西安，目前已在全国建立七个运营中心，业务覆盖16个省会。</p>
                <p class="brandP">公司将利用雄厚的资金、技术、资源实力，顺应国家医疗政策，打造新医药服务、新医疗服务、新健康服务的价值链，助力药企数字化转型。</p>
                <h2 class="brandH2">品牌使命</h2>
                <p class="brandP">搭建医疗新生态，让医疗价值回归，让医疗更加温暖。</p>
                <h2 class="brandH2">品牌愿景</h2>
                <p class="brandP">让每个人拥有属于自己的医疗专“家”，让医疗触手可及。</p>
                <h2 class="brandH2">品牌优势</h2>
                <p class="brandP">在上市板块的医疗＋互联网价值服务平台</p>
                <p class="brandP">孵化客户端价值服务升级</p>
                <p class="brandP">搭建医疗服务生态场景化</p>
                <h2 class="brandH2">品牌核心价值观</h2>
                <p class="brandP">Passion（激情）</p>
                <p class="brandP">Take Big Challenge（接受巨大的挑战）</p>
                <p class="brandP">Accountability（负责可靠）</p>
                <p class="brandP">Open & Respectful（开放和尊重）</p>
                <p class="brandP">Self-critical（自我严格要求）</p>
                <p class="brandP">Integrity & Honesty（正直和诚实）</p>
                <h2 class="brandH2">品牌人才观</h2>
                <p class="brandP">人品正（根红苗正）</p>
                <p class="brandP">不能只看眼前（仰望星空）</p>
                <p class="brandP">眼前的每一件事都做好（脚踏实地）</p>
                <h2 class="brandH2">商标信息</h2>
                <p class="brandP">申请/注册号：56703702</p>
                <p class="brandP">申请日期：2021年06月07日</p>
                <p class="brandP">国际分类：42</p>
                <p class="brandP">申请人名称：陕西医智诺信息科技有限公司</p>
                <p class="brandP" style="margin-bottom:20px" >专用权期限：</p>
            </div>
       </div>
        <!-- <My-footer></My-footer> -->
  </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
@media (min-width:768px){
    .main-newsdate{
    padding: 0 20%;
}
}

.main-newsdate .brandP{font-size: 18px; line-height: 30px; text-align: left;text-indent: 36px;}
  .main-newsdate .brandH2{
      text-align: left;
      margin-bottom:20px;
      margin-top:20px;
      font-weight: bold;
  }
</style>