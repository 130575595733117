<template>
    <div>
      <My-new-nav ref="header" menuTheme="dark"></My-new-nav>
      <div class="topBanner">
        <div class="pageTitle">
          <div class="title">企业文化</div>
          <div class="subTitle">在医智诺，每天都是全新的一天，不断突破边界遇见未知的自己</div>
        </div>
      </div>
      <div class="card">
        <div class="cardTop">
          <div class="cardTitle">企业愿景</div>
          <div class="cardDesc">让每个人拥有属于自己的医疗专「家」</div>
          <div class="cardDesc">让医疗触手可及</div>
        </div>
        <div class="card-bottom">
            <img src="../../assets/image/Mobile/culture/mission-image.png" alt="">
        </div>
      </div>
      <div class="card img_bg">
        <div class="cardTop">
          <div class="cardTitle">核心价值观</div>
          <!-- <div class="cardDesc">搭建医疗新生态</div> -->
        </div>
        <div class="fourSub">
          <div class="subCard" v-for="(item, index) in senseList" :key="index">
            <div class="listPic num1">
                <img :src="item.icon" alt="">
            </div>
            <div class="listTitle">{{ item.title }}</div>
            <div class="listDesc">{{ item.titleEn }}</div>
          </div>
        </div>
      </div>
      <div class="card" style="margin-bottom: -2px;">
        <div class="sense-container">
            <div class="cont-flex-item">
                <div class="sense-image-1">
                    <img src="../../assets/image/culture/image-1.png" alt="" srcset="">
                </div>
                <div class="cont-flex-item-right">
                    <div class="sense-image-3 ">
                        <img src="../../assets/image/culture/image-3.png" alt="" srcset="">
                    </div>
                    <div class="sense-image-3">
                        <img src="../../assets/image/culture/image-4.png" alt="" srcset="">
                    </div>
                </div>
            </div>
            <div class="sense-image-2">
                <img src="../../assets/image/culture/image-2.png" alt="" srcset="">
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
        return {
            senseList: [
                {
                    title: '激情',
                    titleEn: 'Passion',
                    icon: require('../../assets/image/culture/icon-1.png')
                },
                {
                    title: '接受巨大的挑战',
                    titleEn: 'Take big challenge',
                    icon: require('../../assets/image/culture/icon-2.png')
                },
                {
                    title: '负责可靠',
                    titleEn: 'Accountability',
                    icon: require('../../assets/image/culture/icon-3.png')
                },
                {
                    title: '开放和尊重',
                    titleEn: 'Open&Respectful',
                    icon: require('../../assets/image/culture/icon-4.png')
                },
                {
                    title: '自我严格要求',
                    titleEn: 'Self-critical',
                    icon: require('../../assets/image/culture/icon-5.png')
                },
                {
                    title: '正直和诚实',
                    titleEn: 'Integrity&Honesty',
                    icon: require('../../assets/image/culture/icon-6.png')
                }
            ],
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .topBanner{
    padding-bottom: 53.33%;
    position: relative;
    background-image: url("../../assets/image/Mobile/culture/top_bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    .pageTitle{
      padding-left: 36px;
      position: absolute;
      top: 40%;
      .title{
        font-size: 72px;
        font-family: YouSheBiaoTiHei;
        color: #FFFFFF;
        line-height: 93px;
        letter-spacing: 15px;
        text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
      }
      .subTitle{
        font-size: 28px;
        line-height: 45px;
        font-weight: bold;
        color: #FFFFFF;
        letter-spacing: 2px;
        text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
      }
    }
  }
  .card{
    padding: 80px 36px;
    background-color: #131313;
    color: #FFFFFF;
    .cardTop{
      position: relative;
      margin-bottom: 60px;
      &::after{
        content: '';
        display: block;
        position: absolute;
        width: 84px;
        height: 18px;
        background: #0D6EFD;
        opacity: 0.9;
      }
    }
    .cardTitle{
      font-size: 54px;
      font-weight: bold;
      line-height: 69px;
      letter-spacing: 6px;
      margin-bottom: 15px;
    }
    .cardDesc{
      font-size: 30px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 8px;
    }
    & > p{
      font-size: 42px;
      font-weight: 400;
      line-height: 72px;
      letter-spacing: 4px;
      margin-bottom: 60px;
    }
  
    &.img_bg{
      background-image: url("../../assets/image/Mobile/culture/list_bg.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  
    .fourSub{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 120px auto 60px;
      .subCard{
        width: calc((100% - 56px) / 3);
        padding: 45px 0;
        background: #062D67;
        box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.04);
        opacity: 0.7;
        margin-bottom: 28px;
        text-align: center;
        .listPic{
          width: 48.08%;
          margin: 0 auto;
          img{
            width: 100%;
          }
        }
        .listTitle{
          font-size: 32px;
          color: #FFFFFF;
          line-height: 50px;
        }
        .listDesc{
          font-size: 28px;
          color: #ACC7ED;
          line-height: 45px;
        }
      }
    }
    .card-bottom {
        img{
            width: 100%;
        }
    }
    .cont-flex-item {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .sense-container{
        img{
            width: 100%;
        }
        .sense-image-1{
            width: 66%;
            
        }
        .cont-flex-item-right{
            width: 32%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .sense-image-2{
            margin-top: 16px;
        }
    }
  }
  </style>