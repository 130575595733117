<template>
  <!-- <div class="abouts_solution">
    <newsHeader></newsHeader>
      <div class="page-width about-cont">
        <div class="banner">
          <div class="view-width">
            <div class="banner-title">加入我们</div>
            <div class="banner-message">寻找特别的您，这里有属于您的位置！</div>
          </div>
        </div>
      </div>
    <upGrading></upGrading>
  </div> -->
  <div class="abouts_solution solution-page main-container"  @scroll="scroll">
    <My-new-nav ref="header" :isHome='false'></My-new-nav>
    <div class="page-width solution-cont">
      <div class="banner">
        <div class="view-width">
          <div class="banner-title">数字化运营生态解决方案</div>
          <div class="banner-message">助力医疗企业经营实现“自动驾驶”</div>
        </div>
      </div>
    </div>
    <div class="view-width view-pt-80" >
      <ecn-title title="医智诺数字化资产管理平台"></ecn-title>
      <div class="solution-channel">
        <div class="solution-channel-lf">
          <span class="channel-lf-title">引进区块链技术，保证企业数据资产的安全、可信</span>
        </div>
        <div class="solution-channel-rg">
          <img src="../../assets/image/solution/solution-channer-rg.png" alt="">
          <h2>数字化项目运营管理系统</h2>
        </div>
      </div>
      
    </div>
    <div class="solution-modular channel-bg-f2">
      <div class="view-width has-border has-radius" >
        <div class="precipitate-modular">
            <img src="../../assets/image/solution/solution-modoler.png" alt="">
            <span class="title-intro" style="left:-150px;top:126px;">业务数据化</span>
            <span class="title-intro" style="right:-110px;top:126px;">数据资产化</span>
            <span class="title-intro" style="left:-150px;top:460px;">资产服务化</span>
            <span class="title-intro" style="right:-110px;top:460px;">服务业务化</span>
            <span class="title-intro-bottom" style="left: 269px;bottom: 6px;">数据资产沉淀</span>
        </div>
      </div>
    </div>
    <div class="solution-apply">
      <div class="solution-apply-img">
        <img src="../../assets/image/solution/solution-apply.png" alt="">
        <span class="solution-apply-circle one">
          <img src="../../assets/image/solution/span-icon1.png" alt="">
          <span>全域数据资产管理</span>
        </span>
        <span class="solution-apply-circle two">
          <span>基于数据标准建设</span>
          <img src="../../assets/image/solution/span-icon2.png" alt="">
        </span>
        <span class="solution-apply-circle three">
          <span>数据资产应用</span>
          <img src="../../assets/image/solution/span-icon3.png" alt="">
        </span>
        <p>全域数据资产应用</p>
      </div>
    </div>
    <img style="display: none" src="../../assets/image/solution/solution-banner.png" @load="onBgImgLoad" />
    <My-new-footer></My-new-footer>
  </div>
</template>
<script>
import upGrading from '@/components/Upgrading/index'
import newsHeader from '@/views/newsHeader/index'
import scrollHeaderStyle from "../newJoinus/utils";
export default {
  components:{
    upGrading,
    newsHeader
  },
  data(){
    return {
      pageType: "ecologicalSolutionPage",
      bgImg: require("../../assets/image/solution/solution-banner.png"),
    }
  },
  activated(){
    let context = this;
    scrollHeaderStyle(0, context, this.pageType )
  },
  methods:{
    scroll(e){
      //滚动条滚动的事件
      let context = this;
      scrollHeaderStyle(e.srcElement.scrollTop, context, this.pageType )
    },
    onBgImgLoad(){
      document.querySelector(
        ".solution-cont"
      ).style.backgroundImage = `url(${this.bgImg})`;
    }
  }
}
</script>
<style scoped lang="scss">
.solution-page {
  overflow-x: hidden;
  .solution-cont {
    background-image: url('../../assets/image/solution/solution-banner-zip.png');
    background-repeat: no-repeat;
    background-size: 100%;
    min-width: 1280px;
  }
  .banner {
    height: 520px;
  }
  .banner-title {
    font-size: 60px;
    font-family: YouSheBiaoTiHei;
    color: #FFFFFF;
    line-height: 78px;
    letter-spacing: 6px;
    text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
    padding-top: 204px;
  }
  .banner-message {
    font-size: 18px;
    font-family: SourceHanSansCN-Bold;
    color: #FFFFFF;
    line-height: 27px;
    letter-spacing: 9px;
    text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
    margin-top: 19px;
  }
  .ecn-title ::v-deep {
    .title{
      font-size: 28px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: normal;
      color: rgba(0,0,0,0.9);
      letter-spacing: 3px;
    }
  }
  .channel-bg-f1{
    background-color: #F9F9FD;
  }
  .channel-bg-f2{
    background-color: #fff;
  }
  .solution-channel{
    width: 100%;
    height: 654px;
    background-color: #fff;
    margin: 40px 0px;
    display: flex;
    border-radius: 0 12px 12px 0;
    .solution-channel-lf{
      width: 318px;
      height: 100%;
      background:url(../../assets/image/solution/solution-banner-bg.png) no-repeat center center;
      .channel-lf-title{
        display: block;
        width: 262px;
        height: 144px;
        font-size:24px;
        font-weight: 600;
        letter-spacing: 4px;
        color: #fff;
        padding-top: 44px;
        margin: 0 auto ;

      }
    }
    .solution-channel-rg{
      flex: 1;
      border: 1px solid #D8D8D8 ;
      border-radius: 0 12px 12px 0;
      img{
        display: block;
        margin: 14px auto 26px;
      }
      h2{
        width: 938px;
        text-align: center;
        line-height: 54px;
        background: linear-gradient(to left,#fff  0%,#E4EEFF 50%,#fff 100%);
        color: #5B89FF;
        margin: 0 auto;
        letter-spacing: 5px;
        height: 54px;
      }
    }
    
  }
  .solution-modular{
    padding: 52px 0;
    .precipitate-modular{
      width: 721px;
      height: 521px;
      margin: 48px auto 45px;
      position: relative;
      img{
        display: block;
       position: absolute;
       left: 0;
       top: 0;
      }
      .title-intro{
        position: absolute;
        display: block;
        color: #2C68FF;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 3.59px;
      }
      .title-intro-bottom{
        width: 158px;
        height: 36px;
        color: #fff;
        position: absolute;
        display: block;
        background-color: #5B89FF;
        line-height: 36px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 2.94px;
        text-align: center;
        border-radius: 4px;
      }
      
    }
  }
  .solution-apply{
    width: 100%;
    height: 710px;
    // background: url(../../assets/image/solution/solution-apply.png) no-repeat center center;
    padding-bottom: 80px;
    .solution-apply-img{
      margin: auto;
      width: 1440px;
      position: relative;
      display: block;
      }
      p{
        width: 200px;
        height: 36px;
        color: #fff;
        position: absolute;
        left: 50%;
        bottom: 61px;
        transform: translateX(-50%);
        display: block;
        background-color: #5B89FF;
        line-height: 36px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 2.94px;
        text-align: center;
        border-radius: 4px;
      }
      .solution-apply-circle{
        position: absolute;
        display: block;
        display: flex;
        align-items: center;
        span{
          display: block;
          color: #2C68FF;
          font-size: 22px;
          font-weight: bold;
          letter-spacing: 3.59px;
        }
      }
      .one{
        left: 80px;
        top:50%;
        transform: translateY(-50%);
        span{
          margin-left: 20px;
        }
      }
      .two{
        top:25%;
        right:80px;
        span{
          margin-right: 20px;
        }
      }
      .three{
        top:65%;
        right:80px;
        span{
          margin-right: 20px;
        }
      }
    }
  }
.has-border{
  border: 1px solid #D8D8D8;
}
.has-radius{
  border-radius: 12px;
}
</style>