<template>
  <div class="Detail_content">
    <newsHeader/>
    <div class="content_box">
      <div class="Detail_header">{{news.title}}</div>
      <div class="Detail_sub_header">
        <div class="Detail_sub_header_time">
        {{$moment(news.createTime).format('YYYY-MM-DD')}}
        </div>
        <!-- 副标题的images区域 -->
        <div class="Detail_sub_header_images">
          <!-- <img src="../../assets/image/weixin.png" @click="$router.push({ path: '/upGrading'})">
          <img src="../../assets/image/ellipsis.png" class="ellipsis_image" @click="$router.push({ path: '/upGrading'})"> -->
        </div>
      </div>
      <!-- 新闻图片 -->
      <div class="Detail_image">
        <img :src="news.imgUrl"/>
      </div>
      <!-- 内容区域 -->
      <div class="content">
        <div class="Deatil_content"  v-html="news.content"></div>
      </div>
    </div>
    <My-new-footer></My-new-footer>
  </div>
</template>
<script>
import { selectNewsById } from '../../../common/api.js'
//引入新闻详情的头部
import newsHeader from '@/views/newsHeader/index'
export default {
  components:{
    newsHeader
  },
  data(){
    return {
      id:0,
      news:{},
    }
  },
  activated(){
    console.log(this.id,'454545454')
    this.id = this.$route.params.id
    this.getNewsDetail()
  },
  methods:{
    getNewsDetail(){
      let data ={
        id:this.id
      }
      selectNewsById(data).then(res=>{
        if(res.responseCode == 0){
          this.news = res.responseBody
          console.log('新闻详情',this.news)
        }
      })
    }
  }
}
</script>
<style lang="scss">
.Detail_content{
  background: #F9F9FD;
  margin-top:72px;
  .content_box{
    width:1110px;
    margin: 0 auto;
    background: #F9F9FD;
    .Detail_header{
      width: 868px;
      height: 96px;
      font-size: 40px;
      // font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #212121;
      line-height: 48px;
      padding-top:80px;
      overflow: hidden;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .Detail_sub_header{
      margin-top:28px;
      margin-bottom:26px;
      display:flex;
      justify-content:space-between;
      align-items:center;
      .Detail_sub_header_time{
        height: 24px;
        font-size: 14px;
        // font-family: AppleSystemUIFont;
        color: #52647C;
        line-height: 24px;
      }
      .Detail_sub_header_images{
        cursor:pointer;
      }
      .Detail_sub_header_images .ellipsis_image{
        margin-left:24px;
      }
    }
    .Detail_image img{
      width:100%;
      margin-bottom:24px;
    }
    .content{
      background:#F9F9FD;
      display:flex;
      justify-content:center;
      align-items:center;
      .Deatil_content{
        width:1110px;
        margin: 0 auto;
        font-size: 16px;
        // font-family: AppleSystemUIFont;
        color: #212121;
        line-height: 28px;
        margin-bottom:80px;
        p img{
           width:100%;
           margin-top:20px;
        }
        .ql-align-center{
          text-align:center !important
        }
        h1{
          margin:20px 0 20px 0
        }
      }
    }
  }
}
</style>
