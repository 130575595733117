<template>
   <!-- footer -->
    <div class="footer-bigbox">
      <div class="footer-box">
        <div class="footer-left">
          <div class="footer-left-img">
            <img src="../../assets/image/code.png" alt="">
          </div>
          <p class="friend" style="display:none;"><a target="_Blank" href="http://www.nmpa.gov.cn/WS04/CL2042/">国家药品监督管理局</a> </p>
          <div class="footer-left-right">
            <div class="footer-left-address">
              <img src="../../assets/image/adderss.png" alt="">
              <span style="margin-left:12px">陕西省西安市高新区锦业一路6号<br />&emsp;&emsp;永利金融中心14楼1401-02-03</span>
            </div>
            <div class="footer-left-info">
              <img src="../../assets/image/phone.png" alt="">
              <span style="margin-left:12px">029-81774859</span>
            </div>
          </div>
        </div>
        <div class="footer-right">
            <div class="foo">
                <p>友情链接</p>
                <i><a target="_Blank" href="https://www.nmpa.gov.cn/">国家药品监督管理局</a></i>
                <i><a target="_Blank" href="http://www.sxfda.gov.cn/sxfda/z/index.html"> </a></i>
                <i><a target="_Blank" href="http://xafda.xa.gov.cn/ptl/index.html"></a></i>
              </div>
          <div class="foo">
            <p>App下载</p>
            <i @click="routerJump('/app/1')">医智诺就医助手</i>
            <i @click="routerJump('/app/2')">医智诺健康管家</i>
            <i @click="routerJump('/app/3')">领医未来APP</i>
          </div>
          <div class="foo">
            <p>供应商</p>
            <i><a  @click="routerJump('/coopSettlement/1')">药房入驻</a></i>
            <i><a  @click="routerJump('/coopSettlement/2')">医生入驻</a></i>
          </div>
          <div class="foo">
            <p>关于我们</p>
            <i><a @click="routerJump('/aboutUs/4')">加入我们</a></i>
            <i><a  @click="routerJump('/aboutUs/5')">联系我们</a></i>
            <!-- <i><a  @click="routerJump('/brand')">"医智诺"品牌</a></i> -->
          </div>
        </div>
        
        <div class="footer-bottom">
            <p>地址：{{ $COMPANY_INFO.address }}&nbsp;&nbsp;&nbsp;&nbsp;备案号：<a class="footer-icp" href="https://beian.miit.gov.cn" target="_blank">{{ $COMPANY_INFO.icp }}</a></p>
            <!-- <p>备案号：<a class="footer-icp" href="https://beian.miit.gov.cn" target="_blank">陕ICP备18017834号-1</a>&nbsp;&nbsp;|&nbsp;&nbsp;(陕)网械平台备字〔2019〕第00002号</p>
            <p >互联网药品信息服务资格证书编号：（陕)-经营性-2018-0003</p> -->
        </div>
        
      </div>
    </div>
</template>

<script>
import '../../assets/styles/footer.scss'
import "../../assets/styles/public.scss"
export default {
  methods:{
     routerJump(m){
        this.$router.push({path:m})
        this.$store.commit('changeActiveMenu',m)
        window.scrollTo(0,0)
      }
    },
  
}
</script>
  
<style lang="scss" scoped>
.footer-icp{
  color: inherit;
  &:hover{
    color: rgba(13, 110, 253, 0.9);
  }
}
</style>
