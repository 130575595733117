<template>
  <div>
    <div class="part1" style="display: none;">
      <div class="mobile-margin">
        <div class="home_text5 home_title">医智诺大数据</div>
        <div class="home_text_desc-top home_desc">海量数据 无限可能</div>
        <div class="rectangle"></div>
        <div class="home-part2-content2-list">
          <div class="home-part2-content2-list-left">
            <img class="home-part2-content2-list-img" src="../../assets/image/home/icon_left.png" alt="">
            <div class="home-part2-content2-list-count">
              <span class="home-part2-content2-list-left-text1">100</span>
              <span class="home-part2-content2-list-add">+</span>
            </div>
            <div class="home-part2-content2-list-left-text2">企业合作</div>
          </div>
          <div class="home-part2-content2-list-right">
            <div class="home-part2-content2-list-right-row">
              <div v-for="(item,index) in listRight1" :key="index" class="home-part2-content2-list-right_">
                <img :src="item.icon" alt="">
                <div class="home-part2-content2-list-count">
                  <span class="home-part2-content2-list-right-text1">{{ item.number }}</span>
                  <span class="home-part2-content2-list-add">+</span>
                </div>
                <div class="home-part2-content2-list-right-text2">{{ item.text }}</div>
              </div>
            </div>
            <div class="home-part2-content2-list-right-row">
              <div v-for="(item,index)  in listRight2" :key="index" class="home-part2-content2-list-right_">
                <img :src="item.icon" alt="">
                <div class="home-part2-content2-list-count">
                  <span class="home-part2-content2-list-right-text1">{{ item.number }}</span>
                  <span class="home-part2-content2-list-add">+</span>
                </div>
                <div class="home-part2-content2-list-right-text2">{{ item.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="part2">
      <div class="mobile-margin">
        <div class="home_text3 home_title">合法合规资质</div>
        <div class="home_text_desc-top home_desc">高新技术企业、互联网药品信息服务企业</div>
        <div class="rectangle"></div>
      </div>
      <EcnSlideMobile/>
    </div>
  </div>
</template>

<script>
import EcnSlideMobile from "@/views/mobile/EcnSlideMobile.vue";

export default {
  name: "Information",
  components: {EcnSlideMobile},
  data() {
    return {
      listRight1: [
        {
          icon: require('../../assets/image/home/icon1.png'),
          number: '3000',
          text: '医院覆盖',
        },
        {
          icon: require('../../assets/image/home/icon2.png'),
          number: '50000',
          text: '医生上线',
        }, {
          icon: require('../../assets/image/home/icon3.png'),
          number: '1.5亿',
          text: '患者覆盖',
        }
      ],
      listRight2: [
        {
          icon: require('../../assets/image/home/icon4.png'),
          number: 30000,
          text: '药房签约数',
        }, {
          icon: require('../../assets/image/home/icon5.png'),
          number: '15万',
          text: '上线药品',
        }, {
          icon: require('../../assets/image/home/icon6.png'),
          number: '4000',
          text: '销售团队',
        }
      ],
    };
  },
}
</script>

<style scoped lang="scss">

.rectangle{
  width: 86px;
  height: 18px;
  background: #0D6EFD;
  opacity: 0.9;
  margin-top: 20px;
}

.part1 {
  background-color: black;
  padding-bottom: 80px
}

.part2 {
  background-color: #000000;
  background-repeat: no-repeat;
  height: 1300px;
  width: 100%;
  background-size: contain;
  background-image: url('../../assets/image/home/moble_home5.png');
}

.mobile-margin {
  margin: 0 36px;
}

.home_text5 {
  padding-top: 160px;
}

.home_text_desc-top {
  padding-top: 18px;
}

.home_title {
  font-size: 54px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 54px;
  letter-spacing: 5px;
}

.home_desc {
  font-size: 30px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 24px;
  letter-spacing: 5px;
  margin-top: 12px;
}

.home_text3 {
  padding-top: 150px;
}

.home-part2-content2-list {
  margin-top: 92px;
  width: 100%;
  display: flex;
}

.home-part2-content2-list-left {
  background-image: url('../../assets/image/home/moble_home4.png');
  width: 30%;
  height: 438px;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-part2-content2-list-left img {
  margin-top: 135px;
  width: 60px;
  height: 48px;
}

.home-part2-content2-list-left-text1 {
  font-size: 42px;
  font-weight: bold;
  line-height: 42px;
  letter-spacing: 2px;
}

.home-part2-content2-list-count {
  position: relative;
  color: #FFFFFF;
  margin-top: 10px;
}

.home-part2-content2-list-add {
  position: absolute;
  top: 0;
}

.home-part2-content2-list-left-text2 {
  font-size: 30px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 42px;
  letter-spacing: 2px;
  margin-top: 10px;
}

.home-part2-content2-list-right {
  flex-grow: 1;
  background-image: url('../../assets/image/home/moble_home6.png');
  background-size: cover;
}

.home-part2-content2-list-right_ {
  width: 33.33%;
  height: 219px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.home-part2-content2-list-right_ img {
  margin-top: 50px;
  width: 48px;
  height: 48px;
}

.home-part2-content2-list-right-text1 {
  font-size: 27px;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 1px;
  margin-top: 10px;
}

.home-part2-content2-list-right-text2 {
  font-size: 24px;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 2px;
  margin-top: 10px;
}

.home-part2-content2-list-right-row {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

</style>
