<template>
  <div class="abouts_joinus about-page main-container" ref="joinUsRef" @scroll="scroll">
    <My-new-nav ref="header" :isHome='false'></My-new-nav>
    <div class="page-width about-cont">
      <div class="banner">
        <div class="view-width-banner">
          <div class="banner-title">加入我们</div>
          <div class="banner-message">寻找特别的您，这里有属于您的位置！</div>
        </div>
      </div>
    </div>
    <div class="view-width view-pt-80">
      <ecn-title title="招聘通道"></ecn-title>
      <div class="join-us-channel">
        <div class="channel-main-box">
          <div class="channel-item channel-item1">
            <div class="turn-to-btn" @click='turnToSocialRecruit'>
              <img class="channel-icon channel-icon1" src="../../assets/image/joinUs/channel-2-icon.png" alt="">
              <div class="channel-label">社会招聘</div>
            </div>
          </div>
          <div class="channel-item channel-item2">
            <div class="turn-to-btn" @click="openDialog">
              <img class="channel-icon channel-icon2" src="../../assets/image/joinUs/channel-1-icon.png" alt="">
              <div class="channel-label">校园招聘</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="view-width join-us-content-title">
      <ecn-title title="为什么选择医智诺"></ecn-title>
      <div class="join-us-content">
        <div class="content-item">
          <div class="content-item1">
            <img class="content1" src="../../assets/image/joinUs/content1.png" alt="">
            <div class="content-desc">
              <div class="title">因材施教的培训体系</div>
              <div class="msg">医智诺致力于打造学习型组织，为员工提供最契合的培训，帮助初入职场的你从容应对各种问题，轻松掌控工作节奏。</div>
            </div>
          </div>
          <div class="content-item2">
            <img class="content2" src="../../assets/image/joinUs/content2.png" alt="">
            <div class="content-desc">
              <div class="title">得天独厚的发展优势</div>
              <div class="msg">医智诺是香港上市公司华谊腾讯（HK00419）旗下的医疗集团，控股子公司。</div>
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="content-item2">
            <img class="content2" src="../../assets/image/joinUs/content3.png" alt="">
            <div class="content-desc">
              <div class="title">积极正向的发展目标</div>
              <div class="msg">医智诺致力于布局全产业医疗服务生态圈，搭建全场景化医疗服务平台，平台未来5年提供直接岗位10000+、间接岗位10000+，市值将达到1000亿，孵化出6家创新企业。</div>
            </div>
          </div>
          <div class="content-item1">
            <img class="content1" src="../../assets/image/joinUs/content4.png" alt="">
            <div class="content-desc">
              <div class="title">无微不至的福利关怀</div>
              <div class="msg">在医智诺你的努力不会被忘记，你的付出将会被真诚对待，舒适的办公环境，极具竞争力的薪酬、足额五险一金、试用期全额工资和股权激励体系，只为回报你的尽心拼搏。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="view-width swiper-main-box">
      <div class="join-us-content">
        <div class="content-item" style="margin-bottom: 32px">
          <div class="content-item1">
            <el-carousel  arrow="never"
                          :interval=intervalNum1
                          indicator-position="none" height="365px">
              <el-carousel-item
                  v-for="(item, idx) in swiperContent1" :key="idx">
                <img class="content1" :src="item.path" alt="">
                <p class="swiper-title">{{item.name}}</p>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="content-item2">
            <el-carousel  arrow="never"
                          :interval= intervalNum2
                          indicator-position="none" height="365px">
              <el-carousel-item
                  v-for="(item, idx) in swiperContent2" :key="idx">
                <img class="img-custom" :src="item.path" alt="">
                <p class="swiper-title">{{item.name}}</p>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="content-item">
          <div class="content-item2">
            <el-carousel  arrow="never"
                          :interval= intervalNum2
                          indicator-position="none" height="365px">
              <el-carousel-item
                  v-for="(item, idx) in swiperContent3" :key="idx">
                <img class="img-custom" :src="item.path" alt="">
                <p class="swiper-title">{{item.name}}</p>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="content-item1">
            <el-carousel  arrow="never"
                :interval=intervalNum1
                indicator-position="none" height="365px">
              <el-carousel-item
                  v-for="(item, idx) in swiperContent4" :key="idx">
                <img class="content1" :src="item.path" alt="">
                <p class="swiper-title">{{item.name}}</p>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    <img style="display: none" :src="bgImg" @load="onBgImgLoad" />
    <My-new-footer></My-new-footer>
    <el-dialog
        title=""
        :close-on-click-modal=false
        :modal-append-to-body=false
        :destroy-on-close=true
        :width="'960'"
        :visible.sync="dialogVisible">
      <div class="poster-content">
        <img class="school-poster" src="../../assets/image/joinUs/schoole-poster.png" alt="">
        <span class="close-icon" @click="handleClose"></span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import scrollHeaderStyle from "./utils";
import envimg1 from "../../assets/image/joinUs/env-company-pic1.png"
import envimg2 from "../../assets/image/joinUs/env-company-pic2.png"

import sportImg1 from "../../assets/image/joinUs/sport-pic1.png"
import staffImg1 from "../../assets/image/joinUs/staff-pic1.png"

import readBookImg1 from "../../assets/image/joinUs/readingBook-pic1.png"
import birthdayImg1 from "../../assets/image/joinUs/birthday-pic1.png"

import holidayImg1 from "../../assets/image/joinUs/holiday-pic1.png"
import familyImg1 from "../../assets/image/joinUs/family-benefits-pic1.png"
export default {
  components:{
  },
  data() {
    return {
      bgImg: require("../../assets/image/joinUs/join-us-banner.png"),
      dialogVisible: false,
      intervalNum1: 3500,
      intervalNum2: 4000,
      pageType: "joinUsPage",
      swiperContent1: [//图片展示多条不会出现倒置的现象
        {
          name: '5A办公环境',
          path: envimg1
        },
        {
          name: '5A办公环境',
          path: envimg2
        },
        {
          name: '5A办公环境',
          path: envimg1
        },
        {
          name: '5A办公环境',
          path: envimg2
        }
      ],
      swiperContent2: [
        {
          name: '五星健身年卡',
          path: sportImg1
        },
        {
          name: '员工团建',
          path: staffImg1
        },
        {
          name: '五星健身年卡',
          path: sportImg1
        },
        {
          name: '员工团建',
          path: staffImg1
        }
      ],
      swiperContent3: [
        {
          name: '公司读书角',
          path: readBookImg1
        },
        {
          name: '生日/贺仪',
          path: birthdayImg1
        },
        {
          name: '公司读书角',
          path: readBookImg1
        },
        {
          name: '生日/贺仪',
          path: birthdayImg1
        }
      ],
      swiperContent4: [
        {
          name: '带薪假期',
          path: holidayImg1
        },
        {
          name: '家属福利',
          path: familyImg1
        },
        {
          name: '带薪假期',
          path: holidayImg1
        },
        {
          name: '家属福利',
          path: familyImg1
        }
      ],
    }
  },
  activated(){
    let context = this;
    scrollHeaderStyle(0, context, this.pageType )
  },
  mounted() {
    if(this.$route.query.showPoster){
      this.dialogVisible = true
    }
    this.$store.commit("changeActiveMenu", '/joinUs');
  },
  watch:{
    $route(to){
      if(to.query && to.query.showPoster){
        this.dialogVisible = true;
        this.$refs.joinUsRef.scrollTo(0, 0);
      }
    },
  },
  methods: {
    openDialog() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false;
    },
    turnToSocialRecruit() {
      this.$router.push({ path: '/socialRecruit' });
      this.$store.commit("changeActiveMenu", '/joinUs');
      window.scrollTo(0, 0);
    },
    scroll(e){
      //滚动条滚动的事件
      let context = this;
      scrollHeaderStyle(e.srcElement.scrollTop, context, this.pageType )
    },
    onBgImgLoad(){
      document.querySelector(
        ".about-cont"
      ).style.backgroundImage = `url(${this.bgImg})`;
    }
    
  }
}
</script>
<style lang="scss" scoped>
@keyframes channel-animation {
  0%{
    transform: scale(1);
    opacity: 0.8;
  }
  50%{
    transform: scale(1.2);
    opacity: 1;   /*圆形放大的同时，透明度逐渐减小为0*/
  }
  100%{
    transform: scale(1);
    opacity: 0.8;
  }
}
.v-modal{
  opacity: 0.6 !important;
}
.about-page {
  background-color: #f9f9fd;
  overflow-x: hidden;
  transition: all 1s ease-out;
  width: 100vw;
  .about-cont {
    background-image: url('../../assets/image/joinUs/join-us-banner-zip.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 1280px;
    background-position: center;
    height: 520px;
  }
  .banner {
    height: 520px;
  }
  .view-width-banner{
    width: 1280px;
    margin: 0 auto;
  }
  .banner-title {
    font-size: 60px;
    font-family: YouSheBiaoTiHei;
    color: #FFFFFF;
    line-height: 78px;
    letter-spacing: 6px;
    text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
    padding-top: 202px;
  }
  .banner-message {
    font-size: 18px;
    font-family: SourceHanSansCN-Bold;
    color: #FFFFFF;
    line-height: 27px;
    letter-spacing: 9px;
    text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
    margin-top: 20px;
  }
  .ecn-title ::v-deep {
    .title{
      font-size: 28px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: normal;
      color: rgba(0,0,0,0.9);
      letter-spacing: 3px;
    }
  }
  .channel-main-box{
    display: flex;
    margin-top: 40px;
    margin-bottom: 120px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .channel-item{
      width: 624px;
      height: 378px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      .channel-icon{
        width: 88px;
        height: 78px;
      }
      .channel-icon1{

      }
      .channel-label{
        width: 156px;
        font-size: 36px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        letter-spacing: 2px;
      }
      .turn-to-btn{
        cursor: pointer;
      }
      .turn-to-btn:hover{
        -webkit-animation: channel-animation 2s linear infinite;
      }
    }
    .channel-item1{
      background-image: url('../../assets/image/joinUs/channel-2.png');
      background-repeat: no-repeat;
      background-size: 100%;
    }
    .channel-item2{
      background-image: url('../../assets/image/joinUs/channel-1.png');
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
  .join-us-content-title{
    .ecn-title ::v-deep{
      margin-bottom: 40px;
    }
  }
  .content-item{
    margin-bottom: 23px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .content-item1{
      width: 776px;
      position: relative;
      font-size: 0;
    }
    .content-item2{
      width: 471px;
      position: relative;
      font-size: 0;
    }
    .content1{
      width: 776px;
    }
    .content2{
      width: 471px;
    }
    .content-desc{
      height: 240px;
      padding: 32px 24px;
      background-color: #fff;
      box-sizing: border-box;

      .title{
        height: 29px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 30px;
        letter-spacing: 2px;
        margin-bottom: 24px;
      }
      .msg{
        font-size: 20px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        letter-spacing: 1px;
        word-break:break-all;
      }
    }
  }
  .swiper-main-box{
    margin-top: 120px;
    margin-bottom: 100px;
  }
  .school-poster{
    width:100%;
    height: auto;
    user-select: none;
  }
  .el-dialog__wrapper ::v-deep{
    .el-dialog__header{
      display: none;
    }
    .el-dialog{
      background-color: transparent;
      width: 960px;
      margin-top: 0 !important;
      top: 50%;
      transform: translateY(-50%);
    }
    .el-dialog__body{
      padding: 0;
      font-size: 0px;
      .poster-content{
        position: relative;

        .close-icon{
          position: absolute;
          right: -70px;
          width: 36px;
          height: 36px;
          display: inline-block;
          background-image: url('../../assets/image/joinUs/close-icon.png');
          background-size: contain;
          top: 0px;
          cursor: pointer;
        }
      }

    }
    .el-dialog__footer{
      display: none;
    }
  }
  .img-custom{
    width: 471px;
    height: 364px;
  }
  .swiper-title{
    position: absolute;
    left: 24px ;
    top: 28px;
    z-index: 2;
    font-size: 32px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #FFFFFF;
  }

}
</style>