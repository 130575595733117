<template>

  <div class="main-container" @scroll="scroll">
    <My-new-nav ref="header"></My-new-nav>
    <div class="newsCenter_header">
      <div class="header_subtitle">
        <div class="header_subtitle_one">新闻中心</div>
        <div class="header_subtitle_two">在这里 , 您可以了解到医智诺最新的新闻资讯</div>
      </div>
    </div>
    <!-- 新闻列表list区域 -->
    <div class="news_list">
      <div class="new_item" v-for="(item,index) in news" :key="index" @click="clickDetail(item)">
        <div class="imageLeft">
          <img :src="item.imgUrl"/>
        </div>
        <div class="contentRight">
          <p class="contentTitle">{{item.title}}</p>
          <p class="contentTime">{{$moment(item.createTime).format('YYYY-MM-DD')}}</p>
          <p class="news-content">{{item.content.replace(/<[^>]+>|&[^>]+;/g, "").trim()}}</p>
           <!-- <p class="news-content">{{item.content.replace(/<[^<>]+>/g, "")}}</p> -->
        </div>
        <p class="contentBtn" @click="clickDetail(item)">查看详情</p>
      </div>
    </div>
    <!-- 查看更多按钮 -->
    <div class="btns">
      <div v-if="isMore" class="seeMore" @click="getMore">查看更多</div>
    </div>
    <My-new-footer></My-new-footer>
  </div>
</template>
<script>
import {  selectNewList } from '../../../common/api.js'
import scrollHeaderStyle from "../newJoinus/utils";
export default {
  data(){
    return {
      pageIndex: 1,
      pageSize: 5,
      scrollTop: 0,
      isMore: false,
      news:[]
    }
  },
  mounted() {
    this.getNews()
  },
  deletHtmlTag(str){
    str = str.replace(/<[^>]+>|&[^>]+;/g,'').trim()
    return str
  },
  activated(){
    console.log('$route.params.detail', this.$route.params.isNewDetail)
    if(this.$route.params.isNewDetail){
      //利用自定义属性来判断
      document.querySelector('.main-container').scrollTop = this.scrollTop
    }else{
       document.querySelector('.main-container').scrollTop = 0
    }
    let context = this;
    scrollHeaderStyle(0, context, this.pageType )
  },
  methods:{
    getNews() {
        let data = {
          pageNum: this.pageIndex,
          pageSize: this.pageSize,
          isOfficial: 1
        }
        let count = this.pageSize * this.pageIndex
        selectNewList(data).then(res => {
          if (res.responseCode == 0) {
            this.news = this.news.concat(res.responseBody)
            this.isMore = res.count > count
          }
        })
      },
      getMore() {
        this.pageIndex++
        this.getNews()
      },
      clickDetail(item){
        this.$router.push({ path: '/newsCenterDetail/' + item.id })
        this.$store.commit('changeActiveMenu','/newsCenter')
      },
      scroll(e){
        //滚动条滚动的事件
        let context = this;
        scrollHeaderStyle(e.srcElement.scrollTop, context, this.pageType )
      }
  }
}

</script>
<style lang="scss">
.main-container{
  width:100%;
  height: 100vh;
  overflow-y:auto;
  background: #F9F9FD;
  transition: all 1s ease-out;
  width: 100vw;
}
.newsCenter_header{
  width:100%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  background-image:url("../../assets/image/newsCenter.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 1280px;
  background-position: center;
  height: 520px;
}
.header_subtitle{
  width: 1280px;
  margin: 0 auto;
}
.header_subtitle_one{
  height: 78px;
  font-size: 60px;
  // font-family: YouSheBiaoTiHei;
  color: #FFFFFF;
  line-height: 78px;
  letter-spacing: 6px;
  font-weight: 600;
  text-shadow: 0px 0px 6px rgba(6, 58, 148, 0.5);
}
.header_subtitle_two{
  margin-top:19px;
  height: 18px;
  font-size: 18px;
  // font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 27px;
  letter-spacing: 9px;
  text-shadow: 0px 0px 6px rgba(6, 58, 148, 0.5);
  -webkit-background-clip: text;
}
.news_list{
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-flow: wrap;
  padding:80px 0 0  0 ;
}
.new_item{
  width:1280px;
  margin:0 auto;
  height:314px;
  display:flex;
  justify-content:center;
  align-items:center;
  // padding:0 86px;
  cursor:pointer;
  position:relative;
  .contentBtn{
    // width: 61px;
    height: 14px;
    font-size: 14px;
    // font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: #0D6EFD;
    line-height: 21px;
    letter-spacing: 1px;
    text-align:right;
    margin-top:6px;
    visibility:hidden;
    position:absolute;
    right:80px;
    bottom:40px
  }
  &:hover .contentBtn{
    visibility:visible
  }
}
.new_item:hover{
  width: 1280px;
  height: 314px;
  background: #FFFFFF;
  box-shadow: 0px 0px 13px 0px rgba(20, 79, 180, 0.13);
  border-radius: 8px;
}
.new_item:hover{
  width: 1280px;
  height: 314px;
  background: #FFFFFF;
  box-shadow: 0px 0px 13px 0px rgba(20, 79, 180, 0.13);
  border-radius: 8px;
}
.imageLeft{
  margin-right:30px;
  img{
  width: 445px;
  height: 250px
  }
}
.contentRight{
  display:flex;
  flex-flow: wrap;
  justify-content:flex-start;
  flex-direction:column;
  align-items:flex-start;
  width:663px;
  .contentTitle{
    height: 74px;
    width:663px;
    font-size: 30px;
    // font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #212121;
    line-height: 37px;
    margin-top:-20px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .contentTime{
    height: 24px;
    font-size: 14px;
    // font-family: AppleSystemUIFont;
    color: #52647C;
    line-height: 24px;
    margin-top:8px;
    margin-bottom:20px;
  }
  .news-content{
    width:100%;
    word-break:break-all;
    font-size: 14px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    word-break: all;
    -webkit-box-orient: vertical;
    height: 96px;
    // font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #52647C;
    line-height: 24px;
  }
}
.btns{
  background:#F9F9FD;
  display:flex;
  align-items:center;
  justify-content:center;
  padding-bottom: 60px;
  .seeMore{
    margin: 0 auto;
    width: 256px;
    height: 60px;
    background: #247CFD;
    border-radius: 8px;
    text-align:center;
    font-size: 23px;
    // font-family: PingFangSC-Semibold, PingFang SC;
    color: #FFFFFF;
    line-height: 60px;
    margin-top:40px;
    margin-bottom:20px;
    cursor:pointer;
  }
}
</style>