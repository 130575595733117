<template>
   <div class="mobile-banner" >
        <img class="mobile-banner-img" :src="bannerImg" v-show="bannerImg" />
    </div>
</template>

<script>
export default {
    props:{
        bannerImg: {
            type: String,
            default: ''
        },
        slideString:{
            type:Boolean,
            default: false
        }
    },
    data(){
        return{
            bannerHeight: "",
            logo:require('../../assets/image/Whiteninglogo.svg'),
            logo1:require('../../assets/image/colorlogo.svg')

        }
    },
    mounted(){
       
      
    },
    methods:{
        
    }
}
</script>

<style lang="scss" scoped>
.mobile-banner{
    width:100%;
    position: relative;
}
.mobile-banner-img{
    width: 100%;
    
}
</style>
