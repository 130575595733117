<template>
  <div>
    <div v-if="isMobile" class="mobile-header-box" id="nav">
      <div class="mobile-header-box-log">
        <img v-if="mobileShowLogo" :src="menuTheme == 'light' ? logo1 : logo" />
      </div>
      <div class="mobile-header-box-menu">
        <img :src="menuTheme == 'light' ? menubtn2 : menubtn1" @click="showMobilMenu = true"/>
      </div>
    </div>
    <div
      v-else
      class="header-box"
      :class="{ 'home-nav': isHome, 'no-transparent-nav': !isTransparent }"
      id="nav"
      @mouseenter.stop="changeColor(1)"
      @mouseleave="changeColor(2)"
    > <div class="nav-center">
        <div :class="isMobile ? 'logo mobile' : 'logo'">
          <img :src="navShow ? logo1 : logo" />
        </div>
        <div
          style="display: flex; align-items: center; position: relative"
          class="menu"
        >
          <el-menu
            class="el-menu-demo hidden-md-and-down"
            style="background-color: transparent; border-bottom: none"
            :text-color="textColor"
            :active-text-color="activeColor"
            :default-active="activeMenu"
            router
            @open="change"
            @select="selectMenu"
            mode="horizontal"
            v-if="!isMobile"
          >
            <el-menu-item
              index="/"
              style="background-color: transparent; border: 0 !important"
              >首页</el-menu-item
            >
            <!-- <el-menu-item
              index="/product"
              style="background-color: transparent; border: 0 !important"
              >产品</el-menu-item
            > -->
            <el-submenu index="/product" popper-class="ecn-submenu-popover" >
              <template slot="title">产品</template>
              <el-menu-item  index="/product">苁蓉-数字化运营平台</el-menu-item>
              <el-menu-item  index="/projectApp">领医未来</el-menu-item>
              <el-menu-item  index="/productPatient">医智诺就医助手</el-menu-item>
              <el-menu-item  index="/productPharmacy">专业化药房</el-menu-item>
            </el-submenu>
          <!-- <el-menu-item
            index="/Solution"
            style="background-color: transparent; border: 0 !important"
            >解决方案</el-menu-item
          > -->
            <el-submenu index="/ecologicalSolution" popper-class="ecn-submenu-popover" >
              <template slot="title">解决方案</template>
              <el-menu-item  index="/operationSolution">数字化运营解决方案</el-menu-item>
              <el-menu-item  index="/projectSolution">数字化项目管理解决方案</el-menu-item>
              <el-menu-item  index="/ecologicalSolution">数字化运营生态解决方案</el-menu-item>
            </el-submenu>
            <!-- <el-menu-item
              index="/newsCenter"
              style="background-color: transparent; border: 0 !important"
              >新闻中心</el-menu-item
            > -->
            <el-submenu index="/aboutUs" popper-class="ecn-submenu-popover" >
              <template slot="title">关于我们</template>
              <el-menu-item  index="/aboutUs">集团介绍</el-menu-item>
              <el-menu-item  index="/culture">企业文化</el-menu-item>
              <el-menu-item  index="/contact">联系我们</el-menu-item>
            </el-submenu>
            <el-menu-item index="/joinUs" style="background-color: transparent; border: 0 !important">加入我们</el-menu-item
            >
          </el-menu>
        <!-- <div  v-if="!showMobilMenu || isMobile"><img :src="navShow ? menubtn2 : menubtn1" style="width:28px;height: 16px;position: fixed;right: 32px;color: #fff;top: 25px;" @click="showMobilMenu = true"/></div> -->
        </div>
      </div>
    </div>
   
    <My-mobilMenu v-if="showMobilMenu" :menuTheme="mobileShowLogo ? 'light' : 'dark'" @closemenu="closeMenu()"></My-mobilMenu>
    <!-- <template>
        <el-carousel trigger="click"  class="banner-box" loop :height="bannerHeight + 'px'">
            <el-carousel-item v-for="item in homeSlide" :key="item.imgUrl">
            <img ref="bannerHeight" :src="item.imgUrl" class="image" @load="imgLoad">
            </el-carousel-item>
        </el-carousel>
        </template> -->
  </div>
</template>

<script>
import "../../assets/styles/index.scss";
import "../../assets/styles/public.scss";
import "../../assets/styles/mobile.scss";
import "element-ui/lib/theme-chalk/display.css";
import logo from "../../assets/image/Whiteninglogo.svg";
// import logo1 from "../../assets/image/colorlogo.svg";
import logo1 from "../../assets/image/colorlogo.svg";
import menubtn1 from "../../assets/image/Mobile/mobileMenu.svg";
import menubtn2 from "../../assets/image/Mobile/mobileMenu2.svg";
import { isMobile } from "../../../common/util";

export default {
  name: "myheader",
  data() {
    return {
      isMobile: isMobile(),
      showMobilMenu: false,
      logo,
      logo1,
      menubtn1,
      menubtn2,
      bannerHeight: "",
      navShow: false,
      isTransparent: false,
      textColor: "#91969c",
      activeColor: "#fff",
      homeSlide: [
        { imgUrl: require("../../assets/image/banner.png") },
        { imgUrl: require("../../assets/image/banner2.png") },
        { imgUrl: require("../../assets/image/banner3.png") },
        { imgUrl: require("../../assets/image/banner4.png") },
      ],
    };
  },
  computed: {
    activeMenu() {
      return this.$store.state.activeMenu;
    },
  },
  props:{
    isHome:{
      default:false,
      type:Boolean,
    },
    menuTheme: {
      type: String,
      default: 'light'
    },
    mobileShowLogo: {
      default: true,
      type: Boolean,
    }
  },
  // mounted() {
  //   document.querySelector('#app').addEventListener('scroll', (e) => {
  //     this.scollList(e)
  //   })
  // },
  methods: {
    checkTransparent() {
      if (this.$route.path.indexOf("newsdetail") > 0) {
        return false;
      } else {
        return true;
      }
    },
    // scollList(e) {
    //   if (this.$route.path!== '/newsCenter') {
    //     return false;
    //   }
    //   if (e.srcElement.scrollTop > 521) {
    //     document.getElementById("nav").style.backgroundColor = "#ffffff";
    //     this.navShow = true;
    //     this.textColor = "#91969c";
    //     this.activeColor = "#000";
    //     this.isTransparent = false;
    //   } else {
    //     if (this.checkTransparent()) {
    //       document.getElementById("nav").style.backgroundColor = "transparent";
    //       this.navShow = false;
    //       this.textColor = "#91969c";
    //       this.activeColor = "#fff";
    //       this.isTransparent = true;
    //     }
    //   }
    // },
    set(option) {
      console.log('option',option.navShow)
        option.backgroundColor && (document.getElementById("nav").style.backgroundColor = option.backgroundColor)
        option.navShow !== undefined && (this.navShow = option.navShow);
        option.textColor && (this.textColor = option.textColor)
        option.activeColor && (this.activeColor = option.activeColor)
        option.isTransparent !== undefined && (this.isTransparent = option.isTransparent)
    },
    changeColor(m) {
      // console.log(m,'m====')
      if (isMobile) {
        return;
      }
      // if (this.isTransparent) {
      //   if (m == 1) {
      //     document.getElementById("nav").style.backgroundColor = "#ffffff";
      //     this.navShow = true;
      //     this.textColor = "#000";
      //     this.activeColor = "#343478";
      //   } else if (m == 2) {
      //     document.getElementById("nav").style.backgroundColor = "transparent";
      //     this.navShow = false;
      //     this.textColor = "#fff";
      //     this.activeColor = "#fff";
      //   }
      // }
    },
    change(index) {
      console.log(index);
    },
    closeMenu() {
      this.showMobilMenu = false;
    },
    selectMenu(index, path) {
      console.log(index, path);
      this.$store.commit("changeActiveMenu", index);
    },
  },
};
</script>
<style lang="scss" scoped>
  .home-nav{
    background-image: url('../../assets/image/homeNavebackground.png');
    width:100%;

    ::v-deep .el-menu-item.is-active {
      color: #fff !important;
    }
  }
  .header-box {
    ::v-deep .el-submenu__title {
      border: 0px !important;
    }
  }
  .ecn-submenu-popover {
    .el-menu-item.is-active{
      color: black !important;
    }
    .el-menu-item:hover {
     outline: 0;
     color: #333 !important;
     background-color: #e9e9e9 !important;
   }
  }
</style>