<template>
  <div>
      <!-- nav -->
      <!-- <My-nav></My-nav> -->
      <template>
          <!-- banner -->
          <My-banner :bannerImg="bannerImg"></My-banner>
      </template>
      <!-- tab-bar -->
    <div class="tab-bar">
        <div  class="tab-bar-li app" v-for="(item,index) in tabList" :key="index" :class="{'tab_backgroundA': index == tabIndex}" @click="tabIndex = index">
            <img :src="item.icon"/>
            <span>{{item.title}}</span>
        </div>
    </div>
    <div class="app-box">
        <div class="app-box-main" :style="isMobile ? 'height:950px;':''">
           
            <!-- <transition name="el-fade-in-linear"> -->
                <div v-show="tabIndex == 0" class="apps">
                    <div :class="isMobile ? 'app-box-left mobile':'app-box-left'">
                        <img src="../../assets/image/app_phone1.png" />
                    </div>
                    <div class="app-box-right">
                        <h1 :class="isMobile ? 'mobile':''">医智诺就医助手</h1>
                        <p>专业医疗问诊购药平台，全家的身体健康助手。</p>
                        <div class="app-download helper">
                            <ul>
                                <li>
                                   线上问诊
                                </li>
                                <li>
                                    复诊复方
                                </li>
                                 <li>
                                   科普资讯
                                </li>
                                <li>
                                    线上购药
                                </li>
                                <li style="margin-bottom:0;">
                                    科普直播
                                </li>
                                <li style="margin-bottom:0;">
                                    健康档案
                                </li>
                            </ul>
                            <div class="app-code" style="margin-left:54px;display: block;" v-if="!isMobile">
                                <img src="../../assets/image/app_code2.png">
                                <p>微信扫码进入</p>
                            </div>
                        </div>
                        <div class="mobile-app-download" v-if="isMobile">
                            <div class="app-code">
                                <img src="../../assets/image/app_code2.png" style="width:90%;">
                                <p>微信扫码进入</p>
                            </div>
                            <div class="open-weapp" @click="openWeapp()">点击进入</div>
                        </div>
                    </div>                    
                </div>
            <!-- </transition> -->
            <!-- <transition name="el-fade-in-linear"> -->
                <div v-show="tabIndex == 1" class="apps">
                    <div :class="isMobile ? 'app-box-left mobile':'app-box-left'">
                        <img src="../../assets/image/app_phone2.png" />
                    </div>
                    <div class="app-box-right">
                        <h1>医智诺健康管家</h1>
                        <p>您家门口的医生，为您提供便捷的医疗服务</p>
                        <div class="app-download helper">
                            <div class="app-code" >
                                <img src="../../assets/image/app_code3.png">
                                <p>微信扫码关注</p>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </transition> -->
             <!-- <transition name="el-fade-in-linear"> -->
                <div v-show="tabIndex == 2" class="apps">
                    <div :class="isMobile ? 'app-box-left mobile':'app-box-left'">
                        <img src="../../assets/image/phone1.png" />
                    </div>
                    <div class="app-box-right">
                        <h1 :class="isMobile ? 'mobile':''">领医APP</h1>
                        <p>为全国医生提供医疗 健康在线服务的工作平台</p>
                        <div class="app-download">
                            <ul>
                                <li style="margin-bottom:39px;cursor:pointer;" @click="agreeBut(1)" v-if="!isMobile || isMobile && isAndroid">
                                    <img src="../../assets/image/app_android.png"/> 
                                    &nbsp;
                                    Android下载
                                </li>
                                <li style="cursor:pointer;" @click="agreeBut(2)" v-if="!isMobile || isMobile && isIOS">
                                    <img src="../../assets/image/app_ios.png"/>
                                    &nbsp;
                                    iphone下载
                                </li>                                
                            </ul>
                            <div class="app-code" :style="isMobile ? 'display:none;':''">
                                <img src="../../assets/image/app_code1.png">
                                <p>微信扫码下载安装</p>
                            </div>
                        </div>                       
                        <div class="mobile-app-download" v-if="isMobile">
                            <div class="app-code" >
                                <img src="../../assets/image/app_code1.png" style="margin-left: 40px;margin-top: 10px;">
                                <p>微信扫码下载安装</p>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </transition> -->
        </div>
    </div>
    <div id="download-app" v-cloak>        
        <div class="mask" v-if="showMask" @click="showMask=false">
            <img src="img/browser.png"/>
            <div class="tipDiv">
            <div>1.点击箭头指向的···</div>
            <div>2.选择用浏览器打开</div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import "../../assets/styles/app.scss"
import { isMobile } from "../../../common/util"
import { openWelink } from "../../../common/api"
export default {
    data(){
        return{
            tabIndex: 0,
            bannerImg: '',
            isMobile: isMobile(),
            showMask: false,
            isAndroid: false,
            isIOS: false,
            tabList: [
                {icon: require("../../assets/image/app_icon1.png"),title:"医智诺就医助手"},
                {icon: require("../../assets/image/app_icon1.png"),title:"医智诺健康管家"},
                {icon: require("../../assets/image/app_icon2.png"),title:"领医未来"}
            ],
            bannerImgList: isMobile() ? [
                require("../../assets/image/banner_helper_2.png"),
                require("../../assets/image/banner_healthy_2.png"),
                require("../../assets/image/banner_doctor_2.png"),
            ] : [
                require("../../assets/image/banner_helper.png"),
                require("../../assets/image/banner_healthy.png"),
                require("../../assets/image/banner_doctor.png"),
            ],
        }
    },
    watch:{
        '$route.params': function(val,oval){
           if (val.id == 1) {
               this.tabIndex = 0
               this.bannerImg = this.bannerImgList[0]

           } else if (val.id == 2) {
               this.tabIndex = 1
               this.bannerImg = this.bannerImgList[1]

           } else if (val.id == 3) {
               this.tabIndex = 2
               this.bannerImg = this.bannerImgList[2]
           }
        },
        'tabIndex': function(val,oval){
            if (val == 0) {
               this.bannerImg = this.bannerImgList[0]

           } else if (val == 1) {
               this.bannerImg = this.bannerImgList[1]

           } else if (val == 2) {
               this.bannerImg = this.bannerImgList[2]

           }
        }
    },
    mounted(){
       let m = this.$route.params
        if (m.id == 1){
            this.tabIndex = 0
            this.bannerImg = this.bannerImgList[0]
        } else if(m.id == 2){
            this.tabIndex = 1
            this.bannerImg = this.bannerImgList[1]
        } else if(m.id == 3){
            this.tabIndex = 2
            this.bannerImg = this.bannerImgList[2]
        }

        let {isAndroid,isIOS,isIOS9} = this.detectVersion()
        this.isAndroid = isAndroid
        this.isIOS = isIOS || isIOS9
    },
    methods: {        
        // 打开微信
        openWeapp() {
            let data = {}
            openWelink(data).then(function(res) {
                let data = res
                if (data.responseCode == 0) {
                  console.log(data.responseBody)
                  if (data.responseBody) window.location.href=data.responseBody
                } 
            }).catch(function(error) {
                return
            });
        },        
        detectVersion: function() {
            let isAndroid,isIOS,isIOS9,version,
                u = navigator.userAgent,
                ua = u.toLowerCase();

            if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {   //android终端或者uc浏览器
                //Android系统
                isAndroid = true
            }

            if(ua.indexOf("like mac os x") > 0){
                //ios
                var regStr_saf = /os [\d._]*/gi ;
                var verinfo = ua.match(regStr_saf) ;
                version = (verinfo+"").replace(/[^0-9|_.]/ig,"").replace(/_/ig,".");
            }
            var version_str = version+"";
            if(version_str != "undefined" && version_str.length >0){
                version = parseInt(version)
                if(version>=8){
                    // ios9以上
                    isIOS9 = true
                }
                else{
                    isIOS = true
                }
            }
            return {isAndroid,isIOS,isIOS9}
        },
        agreeBut: function(e) {
            if (!this.isAndroid && !this.isIOS) {
                if (e == 1) {
                    window.location.href = 'https://download.echartnow.com/leaderApp/leaderApp.apk';
                } else if (e == 2) {
                    window.location.href = "https://apps.apple.com/cn/app/%E9%A2%86%E5%8C%BB%E6%9C%AA%E6%9D%A5/id1502889143";
                }
            } else {
                window.location.href='/download.html'
            }            
            return            
        },
    }
}
</script>

<style>
#download-app, .lingyi_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>