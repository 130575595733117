<template>
  <div
    id="app"
    :class="isMobile ? 'container mobile ' : 'container'"
  >
    <!-- <My-nav ref="header"></My-nav> -->
    <!-- {{$route.path.indexOf('newsCenterDetail')}} -->
    <!-- <My-new-nav ref="header" v-if="$route.path.indexOf('newsCenterDetail') < 0 && routerList.indexOf(_router) < 0"></My-new-nav> -->
    <keep-alive>
       <router-view></router-view>
    </keep-alive>
    <!-- <My-new-footer v-if="!isMobile && routerList.indexOf(_router) < 0"></My-new-footer> -->
    <!-- <My-footer v-if="!isMobile"></My-footer> -->
    <My-mobilFooter v-if="isMobile"></My-mobilFooter>
  </div>
</template>

<script>
import "./assets/styles/index.scss";
import "./assets/styles/public.scss";
import "./assets/styles/mobile.scss";
import "element-ui/lib/theme-chalk/display.css";
import { isMobile } from "../common/util";
export default {
  name: "App",
  beforeMount() {
    window.addEventListener("orientationchange", () => {
      location.reload();
    });
  },
  data() {
    return {
      isMobile: isMobile(),
      routerList:['/product','/Solution','/aboutUs','/joinUs']
    };
  },
  computed:{
    _router(){
      return this.$store.state.activeMenu
    }
  },
  mounted(){
    console.log(this.$route,11)
  },
  methods: {
    scollList(e) {
      this.$refs.header.scollList(e);
    },
  },
};
</script>

<style>
  *::-webkit-scrollbar-track {
    background-color: #f1f2f3;
  }
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #8f8f8f;
    border-radius: 4px;
  }
  .page-width {
    width: 100%;
  }
  .view-bg-black {
    background: #131313;
  }
  .view-width {
    width: 1280px;
    margin: auto;
  }
  .view-pt-68 {
    padding-top: 68px;
  }
  .view-pt-80 {
    padding-top: 80px;
  }
</style>
