<template>
    <div class="abouts_solution solution-page main-container"  @scroll="scroll">
      <My-new-nav ref="header" :isHome='false'></My-new-nav>
      <div class="page-width solution-cont">
        <div class="banner">
          <div class="view-width">
            <div class="banner-title">数字化运营解决方案</div>
            <div class="banner-message">数字化时代，与医智诺一起重新定义业务关系</div>
          </div>
        </div>
      </div>
      <div class="view-width view-pt-80 " >
        <ecn-title title="打造医疗企业数字化运营业务场景"></ecn-title>
        <div class="solution-scene">
         <img src="../../assets/image/solution/solution-scene.png" alt="" >
        </div>
      </div>
      <div class="page-width channel-bg-f2">
        <div class="view-width view-pt-80"  >
            <ecn-title title="O2O数字化运营服务"></ecn-title>
            <div class="solution-channel">
                <div class="solution-channel-lf">
                    <span class="channel-lf-title">主动出击 <br>
                        构建患者流量池</span>
                    <ul>
                        <li> <span></span> 线上线下引流渠道</li>
                        <li><span></span>占据终端场景</li>
                        <li><span></span>专属平台工具</li>
                        <li><span></span>专属运营团队</li>
                        <li><span></span>定制医生品牌推广方案</li>
                        <li><span></span>合法合规 阳光收益</li>
                    </ul>
                </div>
                <div class="solution-channel-rg">
                    <img src="../../assets/image/solution/solution-scene-rh.png" alt="">
                </div>
            </div>
        </div>
        
      </div>
       <div class="view-width view-pt-80"  >
            <ecn-title title="市场推广服务"></ecn-title>
            <div class="solution-market view-pt-80">
                <div class="solution-market-par">
                    <img src="../../assets/image/solution/solution-market1.png" alt="">
                    <div class="mark"></div>
                    <div class="solution-market-cont">
                      <span class="ul-title">医智诺平台广告</span>
                      <ul class="advertising-sys">
                          <li>核心广告合作</li> 
                          <li>品牌商业合作</li>
                      </ul>
                    </div>
                </div>
                <div class="solution-market-par">
                    <img src="../../assets/image/solution/solution-market2.png" alt="">
                    <div class="mark"></div>
                    <div class="solution-market-cont">
                      <span class="ul-title">医疗会议系统</span>
                      <ul class="meeting-sys">
                          <li>大型学术会议</li>
                          <li>线上科室会</li>
                          <li>新药发布会</li>
                          <li>MDT会议</li>
                          <li>AB专家会</li>
                          <li>专家讲座</li>
                          <li>学术年会</li>
                      </ul>
                    </div>
                </div>
                <div class="solution-market-par">
                    <img src="../../assets/image/solution/solution-market3.png" alt="">
                    <div class="mark"></div>
                    <div class="solution-market-cont">
                      <span class="ul-title">医生大IP打造</span>
                      <ul class="ip-build">
                          <li>多平台IP账号建设</li>
                          <li>医疗科普短视频、科普直播</li>
                          <li>内容、文章、视频制作及发布</li>
                      </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="view-width view-pt-80"  >
            <ecn-title title="临床科研服务"></ecn-title>
            <div class="solution-service">
                <div class="desc firstline odd">
                    <span class="lf-circle top"></span>
                    <div >
                        <div>01</div>
                        <b>真实世界研究(RCT/RWR…)</b>
                        <p>研究中心筛选、机构立项、伦理审查协助、研究系统开发与维护、真实世界证据</p>
                   </div>
                </div>
                <div class="desc firstline">
                    <span class="rh-circle top"></span>
                    <div>
                        <div class="text-right">02</div>
                        <b class="text-right">药物临床试验</b>
                        <p>招募进程可视化管理、招募信息发布、科研项目管理、科研数据管理、分析</p>
                    </div>
                </div>
                <div class="desc  odd">
                    <span class="lf-circle top"></span>
                    <div >
                        <div>03</div>
                        <b>药物经济学评价</b>
                        <p>药物研发决策、降低研发成本、控制药物费用、协助药品品规、剂型迭代、协助医疗保险药品目录、协助开展药物指南制订</p>
                   </div>
                </div>
                <div class="desc">
                    <span class="rh-circle bottom"></span>
                    <div>
                        <div class="text-right">04</div>
                        <b class="text-right">药物临床路径</b>
                        <p>产品临床后研究、产品学术信息推广、产品学术地位、调整产品结构化、助力医保、商报目录建立、助力医疗指南、专家共识
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <img style="display: none" :src="bgImg" @load="onBgImgLoad" />
      <My-new-footer></My-new-footer>
    </div>
  </template>
  <script>
  import upGrading from '@/components/Upgrading/index'
  import newsHeader from '@/views/newsHeader/index'
  import scrollHeaderStyle from "../newJoinus/utils";
  export default {
    components:{
      upGrading,
      newsHeader
    },
    data(){
      return {
        pageType: "ecologicalSolutionPage",
        bgImg: require("../../assets/image/solution/solution-opration-banner.png"),
      }
    },
    activated(){
      let context = this;
      scrollHeaderStyle(0, context, this.pageType )
    },
    methods:{
      scroll(e){
        //滚动条滚动的事件
        let context = this;
        scrollHeaderStyle(e.srcElement.scrollTop, context, this.pageType )
      },
      onBgImgLoad(){
        document.querySelector(
          ".solution-cont"
        ).style.backgroundImage = `url(${this.bgImg})`;
      }
    }
  }
  </script>
  <style scoped lang="scss">
  .solution-page {
    overflow-x: hidden;
    .solution-cont {
      background-image: url('../../assets/image/solution/solution-opration-banner-zip.png');
      background-repeat: no-repeat;
      background-size: 100%;
      min-width: 1280px;
    }
    .banner {
      height: 520px;
    }
    .banner-title {
      font-size: 60px;
      font-family: YouSheBiaoTiHei;
      color: #FFFFFF;
      line-height: 78px;
      letter-spacing: 6px;
      text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
      padding-top: 204px;
    }
    .banner-message {
      font-size: 18px;
      font-family: SourceHanSansCN-Bold;
      color: #FFFFFF;
      line-height: 27px;
      letter-spacing: 9px;
      text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
      margin-top: 19px;
    }
    .ecn-title ::v-deep {
      .title{
        font-size: 28px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: normal;
        color: rgba(0,0,0,0.9);
        letter-spacing: 3px;
      }
    }
    .solution-scene{
        width: 100%;
        margin-top: 112px;
        margin-bottom: 53px;
        img{
            display: block;
            margin:auto;
        }
    }
    .channel-bg-f2{
      background-color: #fff;
    }
    .solution-channel{
        width: 100%;
        height: 500px;
        padding-top: 80px;
        padding-bottom: 80px;
         display: flex;
      .solution-channel-lf{
        width: 318px;
        height: 100%;
        background:url(../../assets/image/solution/solution-scen-lf.png) no-repeat center center;
        .channel-lf-title{
          display: block;
          font-size:30px;
          font-weight: Bold;
          letter-spacing: 4.66px;
          color: #fff;
          padding-top: 40px;
          margin-left: 28px;
        }
        ul{
           color: #fff;
           margin-left: 28px;
           margin-top: 24px;
           li{
            font-size: 18px;
            font-weight: Bold;
            letter-spacing: 2.4px;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            span{
                display: block;
                width: 10px;
                height: 10px;
                margin-right: 18px;
                background-color:#fff;
                border-radius: 50%;
            }
           }
        }
        
      }
      .solution-channel-rg{
        flex: 1;
        border: 1px solid #D8D8D8 ;
        border-radius: 0 12px 12px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          display: block;
        }
      }
      
    }
    .solution-market{
        display: flex;
        padding-bottom: 80px;
        div{
            margin-right: 28px;
            position: relative;
            img{
                display: block;
            }
            
            .mark{
              display: none;
              width:100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0.5;
              background: linear-gradient(to bottom,#4f8be6 0%,#257DFE 100%);
            }
            &:hover .mark{
              display: block;
            }
            .solution-market-cont{
              position: absolute;
              bottom: 32px;
              left: 32px;
              span{
                display: block;
                color: #fff;
                font:normal normal bold 24px CN Bold Bold;
              }
              ul{
                font-size: 18px;
                line-height: 30px;
                color: #fff;
                height: 0;
                overflow: hidden;
              }
              .advertising-sys{
                transition: height 0.4s;
              }
              .meeting-sys{
                transition: height 1s;
              }
              .ip-build{
                transition: height 0.5s;
              }
            }
            &:hover .advertising-sys {
              height: 60px;
              margin-top: 14px;
            }
            &:hover .meeting-sys {
              height: 210px;
              margin-top: 14px;
            }
            &:hover .ip-build {
              height: 90px;
              margin-top: 14px;
            }
            
        }
    }
    .solution-service{
        height: 650px;
        width: 100%;
        margin-bottom: 80px;
        background: url(../../assets/image/solution/solution-service.png);
        display: flex;
        align-content:flex-start;
        flex-wrap: wrap;
        .desc{
            width: 332px;
            height: 210px;
            position: relative;
            span{
                display: block;
                position: absolute;
                width: 80px;
                height: 80px;
                background-color: #fff;
                border-radius: 50%;
                position: absolute;
                box-shadow: 0 0 11px rgba(10, 79, 226, 0.1300);
            }
            div{
                position: relative;
                color: #0D6EFD;
                div{
                    font-size: 47px;
                    font-weight: bold;
                    letter-spacing: 3.68px;
                }
                b{
                    font-size: 23px;
                    display: block;
                    width: 100%;
                    letter-spacing: 1.8px;
                }
                p{
                    margin-top: 12px;
                    font-size: 19px; 
                    color: #333333;
                    font-weight: normal;
                    letter-spacing: 3.1px;
                    line-height: 31px;
                }
            }
            .lf-circle{
                left: -25px;
            }
            .rh-circle{
                right: -29px;
            }
            .top{
                top: 25px;
            }
            .bottom{
                top: 29px;
            }
        }
        .odd{
            margin-right: 513px;
            margin-left: 56px;
        }
        .firstline{
            margin-top: 118px;
        }
    }
}
  .has-border{
    border: 1px solid #D8D8D8;
  }
  .has-radius{
    border-radius: 12px;
  }
  .text-right{
    text-align: right;
  }
  </style>