<template>
  <div class="home">
    <My-new-nav ref="header" menuTheme="dark" :mobileShowLogo="false"></My-new-nav>
    <div class="home-part1">
      <div class="mobile-margin">
        <img class="home_img1" src="../../assets/image/home/moble_home1.png" alt="">
        <img class="home_img2" src="../../assets/image/home/moble_home2.png" alt="">
        <div class="home_text1">让每个人拥有属于自己的医疗专【家】</div>
        <div class="home_text1">让医疗触手可及</div>
      </div>
    </div>
    <div class="home-part2">
      <div class="mobile-margin">
        <div class="home_text3 home_title">医智诺平台生态圈</div>
        <div class="home_text_desc-top home_desc">以人为本提升健康服务，跨界融合助力医疗创新</div>
        <div class="rectangle"></div>
        <div style="text-align: center">
          <img class="home_img3" src="../../assets/image/home/moble_home3.png" alt="">
        </div>
      </div>
    </div>
    <Information class="home-part3"/>
    <div class="home-part4" style="display: none;">
      <div class="mobile-margin">
        <div class="home_text3 home_title">部分合作伙伴</div>
        <div class="home_text_desc-top home_desc">携手合作伙伴，共建医疗新生态，共赢未来</div>
        <div class="rectangle"></div>
        <ul>
          <li class="drug">
            <img src="../../assets/image/home/drug1.png" alt="">
            <img src="../../assets/image/home/drug2.png" alt="">
            <img src="../../assets/image/home/drug3.png" alt="">
          </li>
          <li class="drug">
            <img src="../../assets/image/home/drug4.png" alt="">
            <img src="../../assets/image/home/drug5.png" alt="">
            <img src="../../assets/image/home/drug6.png" alt="">
          </li>
          <li class="drug">
            <img src="../../assets/image/home/drug7.png" alt="">
            <img src="../../assets/image/home/drug8.png" alt="">
            <img src="../../assets/image/home/drug9.png" alt="">
          </li>
        </ul>
      </div>
      <img class="drug_bottom" src="../../assets/image/home/drug_bottom.png" alt="">
    </div>
  </div>
</template>

<script>

import Information from "@/views/mobile/Information.vue";

export default {
  components: {Information},
}
</script>

<style lang="scss" scoped>

.home {
  background-color: #000000;
  width: 100%;
}

.rectangle {
  width: 86px;
  height: 18px;
  background: #0D6EFD;
  opacity: 0.9;
  margin-top: 20px;
}

.home-part1 {
  background-image: url("../../assets/image/home/moble_home_bg.png");
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover;
}

.home-part2 {
  color: #FFFFFF;
  background-color: #000000;
  opacity: 1;
  background-size: cover;
  width: 100%;
}

.mobile-margin {
  margin: 0 36px;
}

.home-part1 .mobile-margin {
  padding-top: 30%;
}

.home_img1 {
  padding-top: 400px;
  width: 280.5px;
  height: 84px;
  display: block;
}

.home_img2 {
  margin-top: 29px;
  height: 200px;
  margin-left: -18px;
}

.home_img3 {
  margin-top: 130px;
  width: 100%;
}

.home_text1 {
  font-size: 39px;
  color: #FFFFFF;
  line-height: 66px;
  letter-spacing: 12px;
  margin-top: 10px;
}

.home_title {
  font-size: 54px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 54px;
  letter-spacing: 5px;
}

.home_desc {
  font-size: 30px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 24px;
  letter-spacing: 5px;
  margin-top: 12px;
}

.home_text3 {
  padding-top: 80px;
}

.home_text_desc-top {
  padding-top: 18px;
}

.home_text5 {
  padding-top: 160px;
}

.home-part4 {
  background: #000000;
}

.home-part4 ul {
  margin-top: 69px;
}

.home-part4 ul li img {
  width: 32%;
}

.home-part4 .drug {
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
}
.drug_bottom{
  width: 100%;
  height: 198px;
  margin-top: -70px;
}
</style>
