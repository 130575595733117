<template>
  <div class="abouts_joinus about-page main-container" @scroll="scroll">
    <My-new-nav ref="header" :isHome='false'></My-new-nav>
    <div class="page-width about-cont">
      <div class="banner">
        <div class="view-width-banner">
          <div class="banner-title">社会招聘</div>
          <div class="banner-message">寻找特别的您，这里有属于您的位置！</div>
        </div>
      </div>
    </div>
    <div class="view-width view-pt-80 social-content">
      <div class="social-channel">
        <div class="channel-item">
          <img class="phone-icon channel-icon" src="../../../assets/image/joinUs/channel-phone-icon.png" alt="">
          <div class="phone-label">联系电话</div>
          <div class="phone-number">029-81123622</div>
        </div>
        <div class="channel-item">
          <img class="email-icon channel-icon" src="../../../assets/image/joinUs/channel-email-icon.png" alt="">
          <div class="phone-label">联系邮箱</div>
          <div class="phone-number">hr@echartnow.com</div>
        </div>
        <div class="channel-item">
          <img class="address-icon channel-icon" src="../../../assets/image/joinUs/channel-address-icon.png" alt="">
          <div class="phone-label">公司地址</div>
          <!-- <div class="phone-number address-text">陕西省西安市高新区锦业一路6号永利金融中心14楼1401-02-03</div> -->
          <div class="phone-number">{{ $COMPANY_INFO.address }}</div>
        </div>
        <div class="channel-item">
          <img class="resume-icon channel-icon" src="../../../assets/image/joinUs/channel-resume-icon.png" alt="">
          <div class="phone-label">邮箱简历投递格式</div>
          <div class="phone-number">附件姓名+应聘岗位+工作地点例：张三+陕西商务岗位+西安</div>
        </div>
      </div>
      <div class="social-position">
        <div v-if="totalNumber" class="position-title">共{{ totalNumber }}个职位</div>
        <div v-if="positionList.length" class="position-content">
          <div v-for="(item, idx) in positionList" class="position-item" @click="turnToPositionInfo(item.id)" :key="idx">
            <div class="item-title">{{ item.jobName }}</div>
            <div class="item-address">
              <span class="item-title-address">{{ item.address ? item.address : '地点不限'}}</span>
              <span class="vertival-line"></span>
              <span class="item-title-number">{{ item.recruitNumber ? item.recruitNumber : '人数不限'}}</span>
              <span class="vertival-line"></span>
              <span class="item-title-culture">{{ item.degree ? item.degree : '学历不限'}}</span>
            </div>
            <div class="item-content" >{{item.duty}}</div>
          </div>
        </div>
        <div v-else>暂无数据</div>
        <div class="footer-more-content">
          <div v-if="hasMore" class="load-more-btn" @click="loadMore">查看更多</div>
        </div>
      </div>
    </div>


    <img style="display: none" :src="bgImg" @load="onBgImgLoad" />
    <My-new-footer></My-new-footer>
  </div>
</template>
<script>
import scrollHeaderStyle from "@/views/newJoinus/utils";

import { selectAllPosition } from "../../../../common/api.js"
export default {
  components:{
  },
  data() {
    return {
      positionList: [],
      totalNumber: 0,
      currentPage: 1,
      pageSize: 10,
      dialogVisible: false,
      hasMore: true,
      pageType: "socialRecruitPage",
      bgImg: require("../../../assets/image/joinUs/social-banner.png"),
    }
  },
  activated(){
    let context = this;
    scrollHeaderStyle(0, context, this.pageType )
  },
  mounted() {
    this.getPositionList()
    this.$store.commit("changeActiveMenu", '/joinUs');
  },
  methods: {
    getPositionList(){
      let params = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        isOfficial: 1,
      }
      selectAllPosition(params).then((res) => {
        if(res?.responseCode == 0 ){
          this.positionList = this.positionList.concat(res.responseBody)
          this.totalNumber = res.count;
          console.log(this.totalNumber,this.positionList.length)
          this.hasMore = this.positionList.length < this.totalNumber ? true : false;
        }
      })
    },
    loadMore(){
      this.currentPage = this.currentPage + 1;
      this.getPositionList()
    },
    scroll(e){
      //滚动条滚动的事件
      let context = this;
      scrollHeaderStyle(e.srcElement.scrollTop, context, this.pageType )
    },
    turnToPositionInfo(id) {
      this.$router.push({ path: `/positionInfo?positionid=${id}` });
      this.$store.commit("changeActiveMenu", '/joinUs');
      window.scrollTo(0, 0);
    },
    onBgImgLoad(){
      document.querySelector(
        ".about-cont"
      ).style.backgroundImage = `url(${this.bgImg})`;
    }
  }
}
</script>
<style lang="scss" scoped>
.about-page {
  //font-size: 0;
  background-color: #f9f9fd;
  overflow-x: hidden;
  transition: all 1s ease-out;
  width: 100vw;
  .about-cont {
    background-image: url('../../../assets/image/joinUs/social-banner-zip.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 1280px;
    background-position: center;
    height: 520px;
  }
  .view-width-banner{
    width: 1280px;
    margin: 0 auto;
  }
  .banner {
    height: 520px;
  }
  .banner-title {
    font-size: 60px;
    font-family: YouSheBiaoTiHei;
    color: #FFFFFF;
    line-height: 78px;
    letter-spacing: 6px;
    text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
    padding-top: 202px;
  }
  .banner-message {
    font-size: 18px;
    font-family: SourceHanSansCN-Bold;
    color: #FFFFFF;
    line-height: 27px;
    letter-spacing: 9px;
    text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
    margin-top: 20px;
  }

  .social-content{
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .social-channel{
      display: flex;
      flex-direction: column;
      padding-right: 32px;

      .channel-item{
        width: 280px;
        //height: 182px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 22px 30px;
        margin-bottom: 32px;
        background: #fff;
        box-shadow: 0px 0px 13px 0px rgba(20,79,180,0.13);
        border-radius: 8px;
        box-sizing: border-box;

        .channel-icon{
          width: 48px;
          height: 48px;
          margin-bottom: 20px;
        }
        .phone-label{
          margin-bottom: 12px;
          height: 24px;
          font-size: 20px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #333333;
          line-height: 24px;

        }
        .phone-number{
          font-size: 16px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #333333;
          line-height: 24px;
        }
        .address-text{
          font-size: 15px;
        }
      }
    }
    .social-position{
      flex: 1;

      .position-title{
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #212121;
        padding-left: 82px;
        padding-bottom: 28px;
        border-left: 1px solid #EAEAEA;
        letter-spacing: 1px;
      }
      .position-content{
        padding-left: 32px;
        border-left: 1px solid #EAEAEA;

        .position-item{
          flex: 1;
          padding: 36px 48px;
          width: 756px;
          box-sizing: border-box;

          .item-title{
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #212121;
            margin-bottom: 8px;
            letter-spacing: 1px;

          }
          .item-address{
            font-size: 14px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #52647C;
            margin-bottom: 20px;
            letter-spacing: 1px;

            .item-title-address{
              display: inline-block;
              max-width: 170px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              vertical-align: bottom;
            }
            .vertival-line{
              display: inline-block;
              width: 1px;
              height: 11px;
              border-right: 1px solid #52647C;
              margin: 0 10px;
            }
          }
          .item-content{
            font-size: 14px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #52647C;
            white-space: pre-line;
            line-height: 24px;
            letter-spacing: 1px;
            max-height: 98px;
            overflow: hidden;
          }


        }
        .position-item:hover{
          background: #FFFFFF;
          box-shadow: 0px 0px 13px 0px rgba(20,79,180,0.13);
          border-radius: 8px;
          cursor:pointer;
        }
      }
    }

  }

  .footer-more-content{
    padding: 116px 0 80px;
    margin: 0 auto;
    margin-left: 110px;

    .load-more-btn {
      display: inline-block;
      width: 256px;
      height: 64px;
      background: #247CFD;
      border-radius: 8px;
      font-size: 23px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 64px;
      cursor: pointer;
      text-align: center;
    }
  }


}
</style>