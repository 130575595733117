<template>
    <div class="contact">
        <newsHeader></newsHeader>
        <div>
            <baidu-map :center="center" zoomToAccuracy='true' :zoom="zoom" @ready="handler" class="map-container"
                @click="getClickInfo"></baidu-map>
        </div>
        <div class="view-width">
            <div class="contact-container">
                <div class="contact-from">
                    <div class="title">留下您的联系方式</div>
                    <div class="from-row">
                        <input type="text" v-model="form.name" maxlength="50" placeholder="姓名">
                    </div>
                    <div class="from-row">
                        <input type="text" v-model="form.email" maxlength="50" placeholder="邮箱">
                    </div>
                    <div class="from-row">
                        <input type="text" v-model="form.phone" maxlength="11" placeholder="电话">
                    </div>
                    <div class="from-row">
                        <input type="text" v-model="form.weChat" maxlength="50" placeholder="微信">
                    </div>
                    <div class="from-row">
                        <input type="text" v-model="form.remarks" maxlength="50" placeholder="备注">
                    </div>
                    <div class="from-row btn-cont">
                        <div class="btn" @click="submit">确定</div>
                    </div>
                </div>
                <div class="contact-info">
                    <div class="contact-info-title">医智诺竭诚为您服务</div>
                    <div class="contact-info-item">
                        <div>访问我们</div>
                        <div>{{ $COMPANY_INFO.address }}</div>
                    </div>
                    <div class="contact-info-item">
                        <div>联系我们</div>
                        <div>029-81774859</div>
                        <div>echartnow@echartnow.com</div>
                    </div>
                    <div class="contact-info-item">
                        <div>工作时间</div>
                        <div>每周一至周五（法定节假日除外）</div>
                        <div>8:30 AM ~ 18:00 PM</div>
                    </div>
                </div>
            </div>
        </div>
        <My-new-footer></My-new-footer>
    </div>
</template>
<script>
import newsHeader from '@/views/newsHeader/index'
export default {
    components: {
        newsHeader
    },
    data() {
        return {
            center: this.$COMPANY_INFO.location,
            zoom: 18,
            form: {
                name: '',
                email: '',
                phone: '',
                weChat: '',
                remarks: '',
            },
        }
    },
    methods: {
        submit() {
            const form = this.form;
            if (form.name || form.email || form.phone || form.weChat || form.remarks) {
                this.$message({
                    message: '提交成功!',
                    type: 'success',
                    offset: 100
                });

                this.form = {
                    name: '',
                    email: '',
                    phone: '',
                    weChat: '',
                    remarks: '',
                }
            }
        },

        handler({ BMap, map }) {
            var point = new BMap.Point(this.center.lng, this.center.lat)
            map.centerAndZoom(point, 16)
            var marker = new BMap.Marker(point) // 创建标注
            map.addOverlay(marker) // 将标注添加到地图中
            var circle = new BMap.Circle(point, 6, { strokeColor: 'Red', strokeWeight: 6, strokeOpacity: 1, Color: 'Red', fillColor: '#f03' })
            var content = "<table>";
            content = content + "<tr><td> 地点：江苏省苏州市张家港市塘桥镇南京路525号</td></tr>";
            content += "</table>";
            var label = new BMap.Label(content, {       // 创建文本标注
                position: point,
                offset: new BMap.Size(-150, -80)
            })
            map.addOverlay(label)
            label.setStyle({                              // 设置label的样式
                color: '#343478',
                fontSize: '16px',
                padding: '10px',
                border: '1px solid #ccc',
                cursor: "pointer"
            })
            map.panBy(328, 250)
        },
        getClickInfo(e) {
            this.center.lng = e.point.lng
            this.center.lat = e.point.lat
        }
    }
}
</script>
<style lang="scss" scoped>
.contact {
    background: #F9F9FD;
    font-family: SourceHanSansCN-Regular;
    padding-top: 74px;

    input {
        background: none;
        outline: none;
        border: none;
    }

    .map-container {
        width: 100%;
        height: 760px;
    }

    .contact-container {
        padding: 0 70px;
        padding-bottom: 50px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        .contact-from {
            padding: 100px 97px;
            background: #FFFFFF;
            box-shadow: 0px 40px 100px 0px rgba(0, 0, 0, 0.15);
            position: relative;
            top: -150px;

            .title {
                font-size: 30px;
                color: #0A0D10;
                font-weight: 400;
                line-height: 45px;
                margin-bottom: 20px;
            }

            .from-row {
                width: 458px;

                input {
                    width: 100%;
                    border-bottom: 1px solid #C7CCDA;
                    font-size: 18px;
                    color: #0A0D10;
                    line-height: 24px;
                    padding-top: 32px;
                    padding-bottom: 16px;
                }

                input:focus {
                    border-bottom: 1px solid #6A7581;
                }

                input::placeholder {
                    color: #A7AEC2;
                }
            }

            .btn-cont {
                margin-top: 47px;
            }

            .btn {
                display: inline-block;
                font-size: 20px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 30px;
                background: #0D6EFD;
                border-radius: 8px;
                padding: 15px 40px;
                cursor: pointer;
            }

        }

        .contact-info {
            flex: 1;
            font-size: 16px;
            font-weight: 400;
            color: #0A0D10;
            margin-left: 128px;

            .contact-info-title {
                padding-top: 170px;
                font-size: 30px;
            }

            .contact-info-item {
                padding-top: 24px;
                line-height: 24px;

                >div:nth-child(1) {
                    font-size: 16px;
                    color: #A7AEC2;
                    margin-bottom: 5px;
                }
            }
        }
    }
}
</style>