<template>
  <div class="home-contarnier" @wheel="handleWheel">
    <!-- <keep-alive> -->
    
      <My-new-nav ref="header" :isHome='false'></My-new-nav>
    <div class="point-list">
      <div v-for="item in indexArr" :key="item" class="point-item" @click="handlePoint(item)">
        <div :class="{'point-item-active': oldIndex == item}"></div>
      </div>
    </div>
    <div id="homeContarnier">
      <!-- 生态圈 -->
      <!-- 中间介绍 -->
      <div class="ecn-box-home">
        <div class="ecn-zoology-binner">
          <div class="ecn-zoology-box">
            <div class="home-logo">
              <img src="../../assets/image/home-index-logo.png" alt="" />
            </div>
            <div class="home-title">
              <img src="../../assets/image/new_title_img.png" alt="" />
            </div>
            <div class="content-txt">
              让每个人拥有属于自己的医疗专【家】 , 让医疗触手可及
            </div>
          </div>
          <div class="home-ecology">
            <div class="view-width view-pt-68">
              <ecn-title title="医智诺平台生态圈" message="以人为本提升健康服务 , 跨界融合助力医疗创新"></ecn-title>
              <div class="home-ecology-img">
                <img src="../../assets/image/link-home-img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="view-bg-black" style="display: none;">
          <div class="view-width view-pt-68">
            <ecn-title title="医智诺大数据" message="海量数据 无限可能"></ecn-title>
            <ecn-view-data></ecn-view-data>
          </div>
        </div>
        <div class="view-qualifications">
          <div class="view-width view-qualifications-bg">
            <ecn-title class="view-pt-68" title="合法合规资质" message="高新技术企业、互联网药品信息服务企业"></ecn-title>
            <div>
              <ecn-slide></ecn-slide>
            </div>
          </div>
        </div>
        <div class="view-bg-black" id="homeCooperation" style="display: none;">
          <div class="view-width view-pt-68">
            <ecn-title title="部分合作伙伴" message="携手合作伙伴, 共建医疗新生态, 共赢未来"></ecn-title>
            <div class="home-cooperation">
              <div class="home-cooperation-item" v-for="(item, i) in enterpriseList" :key="i">
                <img :src="item.imgUrl" alt="" />
              </div>
              <div class="home-cooperation-item">
                <div class="more-item">
                  <div>了解更多</div>
                  <img src="../../assets/image/more-icon.png" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img style="display: none" :src="bgImg" @load="onBgImgLoad" />
      <My-new-footer ref="footer"></My-new-footer>
    </div>
    <!-- </keep-alive> -->
  </div>
</template>
<script>
import "../../assets/styles/index.scss";
import "../../assets/styles/public.scss";
import "../../assets/styles/mobile.scss";
import "element-ui/lib/theme-chalk/display.css";
import logo from "../../assets/image/Whiteninglogo.svg";
import logo1 from "../../assets/image/colorlogo.svg";
import { isMobile } from "../../../common/util";
import TextScroll from "./new_TextScroll.vue";
import { Carousel3d, Slide } from "vue-carousel-3d";
import {
  selectTextList,
  selectEnterpriseList,
  selectCarouselList,
  selectNewList,
} from "../../../common/api";
export default {
  name: "Home",
  computed: {
    activeMenu() {
      return this.$store.state.activeMenu;
    },
  },

  components: {
    TextScroll,
    Carousel3d,
    Slide,
  },
  data() {
    return {
      bannerHeight: "",
      logo,
      logo1,
      navShow: false,
      isTransparent: true,
      textColor: "#fff",
      activeColor: "#fff",
      isMobile: isMobile(),
      showMobilMenu: false,
      txt: document.documentElement.clientWidth / window.devicePixelRatio,
      bgImg: require("../../assets/image/new_home-banner.png"),
      homeSlide: isMobile()
        ? [{ imgUrl: require("../../assets/image/banner_mobile.png") }]
        : [{ imgUrl: require("../../assets/image/new_home-banner.png") }],
      news: [],
      textNews: [],
      enterpriseList: [],
      hasMoreCoop: false,
      contentList: [
        {
          num: 3000,
          txt: "医院覆盖",
          url: require("../../assets/image/hospital-icon.png"),
        },
        {
          num: 50000,
          txt: "医生上线",
          url: require("../../assets/image/auscultation-icon.png"),
        },
        {
          num: "1.5亿",
          txt: "患者覆盖",
          url: require("../../assets/image/facility-icon.png"),
        },
        {
          num: "30000",
          txt: "药房签约数",
          url: require("../../assets/image/pharmacy-icon.png"),
        },
        {
          num: "15万",
          txt: "上线药品",
          url: require("../../assets/image/drug-icon.png"),
        },
        {
          num: "4000",
          txt: "销售团队",
          url: require("../../assets/image/team-icon.png"),
        },
      ],
      pageType: "ecologicalSolutionPage",
      oldIndex: 0,
      isCanMove: true,
      indexArr: [0, 1, 2]
    };
  },
  activated() {
    this.handlePoint(0, 'act')
    document.getElementById("nav").style.backgroundColor = "transparent";
    this.$parent.$children[0].navShow = false
    this.$parent.$children[0].activeColor = '#fff'
    // this.navShow = false;
  },
  mounted() {
    // console.log(232323232332)
    document.getElementById("nav").style.backgroundColor = "transparent";
    console.log(this.$parent.$children[0].navShow,'this.$parent.$children[0]')
    this.$parent.$children[0].activeColor = '#fff'
    this.$parent.$children[0].navShow = false
    this.imgLoad();
    window.addEventListener("resize", () => {
      // this.bannerHeight = this.$refs.bannerHeight[0]?.height;
      this.imgLoad();
    });
    this.getSlides();
    this.getTextNews();
    this.getCoopEnterpriseList();
    this.getNews();
  },
  methods: {
    imgLoad() {
      this.$nextTick(() => {
        //if (this.$refs.bannerHeight[0]) this.bannerHeight = this.$refs.bannerHeight[0].height;
      });
    },
    getTextNews() {
      let data = {
        pageNum: 1,
        pageSize: 3,
        isOfficial: 1,
      };
      selectTextList(data).then((res) => {
        if (res.responseCode == 0) {
          this.textNews = res.responseBody;
        }
      });
    },
    getCoopEnterpriseList() {
      let data = {
        isOfficial: 1,
        pageIndex: 1,
        pageSize: 20,
      };
      selectEnterpriseList(data).then((res) => {
        if (res.responseCode == 0) {
          console.log(res, "res=====");
          this.enterpriseList = res.responseBody;
          if (this.enterpriseList.length >= 20) {
            this.hasMoreCoop = true;
          } else {
            let ap = 4 - (this.enterpriseList.length % 4);
            let i = 0;
            /*while (i < ap) {
              this.enterpriseList.push({
                imgUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAyAAAAJYCAYAAACadoJwAAAgAElEQVR4XsS9WZClaXIldO6",
              });
              i++;
            }*/
          }
        }
      });
    },
    getSlides() {
      let data = {
        pageNum: 1,
        pageSize: 4,
        isOfficial: 1,
      };
      selectCarouselList(data).then((res) => {
        console.log('res==================', res)
        console.log('res==================')
        if (res.responseCode == 0) {
          if (res.responseBody && res.responseBody.length > 0) {
            this.homeSlide = res.responseBody;
          }
        }
      });
    },
    getNews() {
      let data = {
        pageNum: 1,
        pageSize: 3,
        isOfficial: 1,
      };
      selectNewList(data).then((res) => {
        if (res.responseCode == 0) {
          this.news = res.responseBody;
        }
      });
    },
    onBgImgLoad() {
      document.querySelector(
        ".ecn-zoology-binner"
      ).style.backgroundImage = `url(${this.bgImg})`;
    },
    getFooterOffsetHeight() {
      // return document.getElementById('homeCooperation').offsetHeight
      // console.log(this.$refs.footer.style.height);
      return 300
    },
    handleWheel(e) {
      console.log(e);
      if(!this.isCanMove) return
      this.isCanMove = false
      setTimeout(() => {
        this.isCanMove = true
      }, 1000)

      // 获取最大屏数index
      let footerHeight = this.getFooterOffsetHeight()
      let maxIndex = document.documentElement.clientHeight > footerHeight ? this.indexArr.length : this.indexArr.length + 1
      if(e.wheelDelta < 0) {
        this.oldIndex ++
        this.oldIndex = this.oldIndex > maxIndex ? maxIndex : this.oldIndex
      } else {
        this.oldIndex --
        this.oldIndex = this.oldIndex < 0 ? 0 : this.oldIndex
      }
      this.movePage(maxIndex)
    },
    handlePoint(item, isAct) {
      let footerHeight = this.getFooterOffsetHeight()
      let maxIndex = document.documentElement.clientHeight > footerHeight ? this.indexArr.length : this.indexArr.length + 1
      this.oldIndex = item
      this.movePage(maxIndex, isAct)
    },
    movePage(max, isAct) {
      let bodyHeight = document.documentElement.clientHeight
      let move = this.oldIndex < max ? this.oldIndex * bodyHeight : bodyHeight * (max - 1) + this.getFooterOffsetHeight()
      if(this.oldIndex < max && this.oldIndex > 0) move -= 60
      this.setContentAnmtion(move, isAct)

      let option = {}
      if(this.oldIndex == 0) {
        option.backgroundColor ='transparent'
        option.navShow = false
        option.textColor ='#91969c'
        option.activeColor ='#fff'
        option.isTransparent = true
      } else {
        option.backgroundColor ='#ffffff'
        option.navShow = true
        option.textColor ='#91969c'
        option.activeColor ='#000'
        option.isTransparent = false
      }
      this.$refs.header.set(option)
    },
    //设置轮播动画
    setContentAnmtion(move, isAct) {
      let dom = document.getElementById('homeContarnier')
      dom.style.transform = `translate3d(0px, -${move}px, 0px)`
      if(isAct) {
        dom.style.transition = 'none'
        document.getElementsByClassName('header-box')[0].style.transition = 'none'
      } else {
        dom.style.transition = 'transform 1s'
        document.getElementsByClassName('header-box')[0].style.transition = 'background-color 1s'
      }
    }
  },
};
</script>

<style lang="scss">
.home-contarnier{
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: #131313;
  .header-box{
    width: 100%;
    transition: background-color 1s;
  }
  .point-list{
    height: 90px;
    position: absolute;
    right: 20px;
    top: calc(50% - 45px);
    z-index: 9999;
    .point-item{
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover>div{
        width: 8px;
        height: 8px;
        box-shadow: 0px 0px 7px #06429A;
      }
      div{
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #fff;
      }
      .point-item-active{
        width: 10px !important;
        height: 10px !important;
        box-shadow: 0px 0px 7px #06429A;
      }
    }
    
  }
}
.el-menu-demo .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled),
.el-menu--horizontal > .el-submenu .el-submenu__title {
  font-size: 15px !important;
  font-weight: 600 !important;
}
.el-menu-demo .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: transparent !important;
  color: #ccc !important;
}
.no-transparent-nav{
  .el-menu-demo .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: transparent !important;
    color: #333 !important;
  }
}
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  background-color: transparent !important;
  font-size: 16px !important;
}
.home-ecology {
  height: 900px;
  background: linear-gradient(180deg, rgba(19,19,19,0) 0%, rgba(19,19,19,0.5) 16%, rgba(19,19,19,0.71) 43%, rgba(19,19,19,0.7) 100%);
  .home-ecology-img {
    padding-top: 16px;
    padding-bottom: 56px;
    text-align: center;
    img {
      width: 1084px;
      height: 674px;
    }
  }
}
.view-bg-black{
  height: 900px;
}

.view-qualifications {
  height: 900px;
  background-image: url('../../assets/image/aboutUs/certification-img.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.view-qualifications-bg {
  height: 900px;
  background-image: url('../../assets/image/aboutUs/certification-bg-img.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.home-cooperation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 0;
  margin-top: 50px;
  background: url('../../assets/image/footer-binner.png') no-repeat bottom;
  background-size: 100%;
  padding-bottom: 108px;
}
.home-cooperation-item {
  width: 240px;
  height: 128px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-radius: 8px;
  img {
    width: 100%;
    height: 1005;
  }
  .more-item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 18px;
    color: #888888;
    letter-spacing: 2px;
    img {
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }
  }
}

</style>

