<template>
  <div class="product-patient"> 
     <newsHeader></newsHeader>
     <div class="">
       <div class="view-width flexRowBetFir padding-item">
         <div class='flexColFirCen'>
           <div class="project-name margin-top-86 flexRowBetBase"><div>医智诺就医助手</div><div class="small-title">小程序</div></div>
           <div class="product-description">「医智诺就医助手」小程序解决患者就医难题，问诊购药一站式服务，上万医生全天候服务，全方位健康管理和专属家庭医生服务，让生活更健康。</div>
         </div>
         <div class="product-img"><img class="img1" src="../../assets/image/product/patient/project.png" alt="医智诺就医助手"></div>
       </div>
       <div class="bg-gray">
          <div class="view-width padding-item11">
            <ecn-title title="搭建健康服务生态场景化平台"></ecn-title>
            <div class="model-item flexRowBetCen padding-item1">
              <div class='flexColCen2'>
                <div class="project-name">无忧问诊购药服务</div>
                <div class="product-description">随时随地线上问诊，一键购药快速送达，满足全天问诊购药需求，上万医生在平台等您来。</div>
              </div>
              <div class="product-img"><img class="img234" src="../../assets/image/product/patient/model1.png" alt="无忧问诊购药服务"></div>
            </div>
          </div>
       </div>
       <div class="view-width model-item flexRowBetCen padding-item2">
         <div class="product-img"><img class="img234" src="../../assets/image/product/patient/model2.png" alt="专属家庭医生服务"></div>
         <div class='flexColCen2'>
           <div class="project-name">专属家庭医生服务</div>
           <div class="product-description">为家庭提供医疗保健服务，满足每一个家庭的就诊购药、健康管理需求，守护您和家人的健康。</div>
         </div>
       </div>
       <div class="bg-gray">
         <div class="view-width model-item flexRowBetCen padding-item3">
           <div class='flexColCen2'>
             <div class="project-name">全周期健康管理服务</div>
             <div class="product-description">诊后全生命周期健康管理，线上线下一体化服务，随访定时健康提醒，科普文章视频随时学习。</div>
           </div>
           <div class="product-img"><img class="img234" src="../../assets/image/product/patient/model3.png" alt="全周期健康管理服务"></div>
         </div>
       </div>
     </div>
     <My-new-footer></My-new-footer>
  </div>
</template>

<script>
import newsHeader from '@/views/newsHeader/index'
export default {
    name:'product-patient',
    components: {
        newsHeader
    },
    data(){
        return{
         
        }
    },
    mounted(){
       
    },
    methods: {
       
    }
}
</script>

<style lang="scss" scoped>
  .product-patient{
    font-family: SourceHanSansCN-Regular;
    padding-top: 74px;
    div{
      box-sizing: border-box;
    }
    .margin-top-86{
      margin-top: 86px;
    }
    .padding-item{
      padding: 25px 98px 9px;
    }
    .padding-item1{
      padding: 0 98px;
    }
    .padding-item11{
      padding: 80px 0 144px;
    }
    .padding-item2{
      padding: 114px 98px;
    }
    .padding-item3{
      padding: 144px 33px 144px 98px;
    }
    .project-name{
      position: relative;
      width: 497px;
      font-family: YouSheBiaoTiHei;
      color: #212121;
      line-height: 70px;
      margin-bottom: 22px;
      font-size: 60px;
      .small-title{
        font-size: 35px;
        line-height: 40px;
      }
    }
    .product-description{
      width: 497px;
      font-weight: 400;
      font-size: 20px;
      color: #212121;
      font-family: PingFangSC-Regular, PingFang SC;
      line-height: 32px;
      text-align: justify;
    }
    .model-item{
      .project-name{
        margin-bottom: 22px;
        width: 462px;
        text-align: left;
        font-size: 28px;
        line-height: 30px;
      }
      .product-description{
        width: 462px;
        text-align: justify;
      }
    }
    .flexRowBetCen{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .flexRowBetFir{
      display: flex;
      justify-content: space-between;
    }
    .flexColFirCen{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .flexColCen2{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .flexRowBetBase{
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
    .product-img{
      img{
        display: block;
      }
      .img1{
        width: 532px;
        height: 658px;
      }
      .img234{
        height: 412px;
      }
      
    }
    .ecn-title ::v-deep {
      .title{
        font-size: 28px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: normal;
        color: rgba(0,0,0,0.9);
        letter-spacing: 3px;
      }
    }
    .bg-gray{
      background-color: #F9F9FD;
    }
  }
</style>