<template>
  <div class="project-app"> 
     <newsHeader></newsHeader>
     <div class="">
       <div class="view-width flexRowBetFir padding-item">
         <div class="product-img"><img class="img1" src="../../assets/image/product/app/project.png" alt="领医未来"></div>
         <div class='flexColFirCen'>
           <div class="project-name margin-top-142">领医未来</div>
           <div class="product-description">「领医未来」APP帮助医生进行互联网个人形象打造及学科化专业团队建设，进行知识传播和个人学习提升，同时对患者进行全生命周期管理，通过全场景平台业务助力医生阳光化收益。</div>
         </div>
       </div>
       <div class="bg-gray">
          <div class="view-width padding-item11">
            <ecn-title title="构建学科数字化协同管理平台"></ecn-title>
            <div class="model-item flexRowBetCen padding-item1">
              <div class='flexColCen2'>
                <div class="project-name">学科化专家团队建设</div>
                <div class="product-description">组建学科化医疗团队，提供更加专业的诊疗服务；开展专科联盟化发展模式，促进学术交流和转诊互信。</div>
              </div>
              <div class="product-img"><img class="img2345" src="../../assets/image/product/app/model1.png" alt="学科化专家团队建设"></div>
            </div>
          </div>
       </div>
       <div class="view-width model-item flexRowBetCen padding-item2">
         <div class="product-img"><img class="img2345" src="../../assets/image/product/app/model2.png" alt="全生命周期患者管理"></div>
         <div class='flexColCen2'>
           <div class="project-name">全生命周期患者管理</div>
           <div class="product-description">构建患者管理团队，分类管理患者并进行随访和教育，促进患者粘性，完成医生与患者的诊后关系建立和管理。</div>
         </div>
       </div>
       <div class="bg-gray">
         <div class="view-width model-item flexRowBetCen padding-item3">
           <div class='flexColCen2'>
             <div class="project-name">全场景助力阳光化收益</div>
             <div class="product-description">全场景平台业务可自主定价，多任务模式可自主选择；平台帮助医生提供证据链，获得税后收益，放心安心省心。</div>
           </div>
           <div class="product-img"><img class="img2345" src="../../assets/image/product/app/model3.png" alt="全场景助力阳光化收益"></div>
         </div>
       </div>
       <div class="view-width model-item flexRowBetCen padding-item4">
         <div class="product-img"><img class="img2345" src="../../assets/image/product/app/model4.png" alt="教学研一体化服务"></div>
         <div class='flexColCen2'>
           <div class="project-name">教学研一体化服务</div>
           <div class="product-description">资讯文章、会议直播等多形式内容帮助医生进行知识传播、内容学习和学术研究，助力医护人员成长。</div>
         </div>
       </div>
     </div>
     <My-new-footer></My-new-footer>
  </div>
</template>

<script>
import newsHeader from '@/views/newsHeader/index'
export default {
    name:'project-app',
    components: {
        newsHeader
    },
    data(){
        return{
            
        }
    },
    mounted(){
       
    },
    methods: {
       
    } 
}
</script>

<style lang="scss" scoped>
  .project-app{
    font-family: SourceHanSansCN-Regular;
    padding-top: 74px;
    div{
      box-sizing: border-box;
    }
    .margin-top-142{
      margin-top: 142px;
    }
    .padding-item{
      padding: 25px 98px 9px;
    }
    .padding-item1{
      padding: 0 97px 0 98px;
    }
    .padding-item11{
      padding: 80px 0 152px;
    }
    .padding-item2{
      padding: 113px 98px 113px;
    }
    .padding-item3{
      padding: 133px 97px 132px 98px;
    }
    .padding-item4{
      padding: 127px 98px 128px;
    }
    .project-name{
      width: 445px;
      font-family: YouSheBiaoTiHei;
      color: #212121;
      line-height: 70px;
      margin-bottom: 22px;
      font-size: 60px;
    }
    .product-description{
      width: 445px;
      font-weight: 400;
      font-size: 20px;
      color: #212121;
      font-family: PingFangSC-Regular, PingFang SC;
      line-height: 32px;
      text-align: justify;
    }
    .model-item{
      .project-name{
        margin-bottom: 22px;
        width: 462px;
        text-align: left;
        font-size: 28px;
        line-height: 30px;
      }
      .product-description{
        width: 462px;
        text-align: justify;
      }
    }
    .flexRowBetCen{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .flexRowBetFir{
      display: flex;
      justify-content: space-between;
    }
    .flexColFirCen{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .flexColCen2{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .product-img{
      img{
        display: block;
      }
      .img1{
        width: 532px;
        height: 658px;
      }
      .img2345{
        width: 508px;
      }
      
    }
    .ecn-title ::v-deep {
      .title{
        font-size: 28px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: normal;
        color: rgba(0,0,0,0.9);
        letter-spacing: 3px;
      }
    }
    .bg-gray{
      background-color: #F9F9FD;
    }
  }
</style>