import axios from "axios";
import {sha1} from "../common/util"
const cfg = require('../public/config').default

axios.defaults.timeout = 5000; //超时时间设置
axios.defaults.withCredentials = true; //true允许跨域
//Content-Type 响应头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


let token = localStorage.getItem('token');
if (token) {
    axios.defaults.headers.post["cpptoken"] = token;
}
axios.defaults.baseURL=  cfg.baseUrl // envType == 1 ? baseUrl : '/api' //process.env.VUE_APP_BASE_API
axios.defaults.withCredentials = cfg.envType == 1

function getRandomNumber(max,min) {
    let x = Math.floor(Math.random() * (max - min + 1)) + min;
    if(x == "0"){
      getRandomNumber(max,min);
    }else{
      return x;
    }
}
// request interceptor
axios.interceptors.request.use(
   config => {
   let nonce = "";
   let nonceLength = getRandomNumber(1,32);
   let arr = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z']
   for(let i=0;i<nonceLength;i++){nonce = nonce + Math.floor(Math.random()*10) + arr[Math.floor(Math.random()*26)];}
   let appkey = "3a58b424a8ec463ba219b5286d666493";
   let curtime = Math.floor((new Date().getTime()) / 1000);

   let oldCurtime = localStorage.getItem("curtime");
   if(oldCurtime != null){
     let num = curtime - Number(oldCurtime);
     if(num > 7200){
    //    window.localStorage.clear();
    //    removeToken();
    //    router.push({
    //        path: '/login'
    //    })
    //    return;
     }
   }
   localStorage.setItem("curtime", curtime);
   let appSecret = "k297v51cyetz"
   let checksum = sha1(appSecret + nonce + curtime);
   config.headers['AppKey'] = appkey;
   config.headers['Nonce'] = nonce;
   config.headers['CurTime'] = curtime;
   config.headers['CheckSum'] = checksum;
       if (config.url == '/fastdfsServer/fileUpload') {
           config.headers['Content-Type'] = 'multipart/form-data' // 让每个请求携带自定义token 请根据实际情况自行修改
       }
       return config;
   },(error) => {
       return Promise.reject(error)
   }
)


// 响应拦截器
axios.interceptors.response.use(
    config => {
        return config;
      },
    // 服务器状态码不是2开头的的情况
    error => {
        if (error.response.status) {
            // switch (error.response.status) {
            //     // 401: 未登录
            //     case 401:
            //         this.$router.push("/login");
            //         break;
            //     case 403:
            //         this.$message("管理员权限已修改请重新登录");
            //         setTimeout(() => {
            //           this.$router.push("/login");
            //         }, 1000);
            //         break;

            //         // 404请求不存在
            //     case 404:
            //       this.$router.push("/login");
            //         break;
            // }
            return Promise.error(error)
        }
    }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
                params: params
            },)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            response => {
                resolve(response.data);
            },
            err => {
                reject(err);
            }
        );
    });
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function del(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.delete(url, {
            params: params
            },).then(
            response => {
                resolve(response.data);
            },
            err => {
                reject(err);
            }
        );
    });
}