<template>
  <div class="swiper-tab">
      <!-- <div class="list">
          <div class="item" v-for="item,index in datas " :key="index"
          :class="index % 2 ? 'red':'skyblue'" @scroll="tabScroll"
          >{{item}}</div>
      </div> -->
      <span   v-show="lfShow"  class="mobile-tab-bar-btn lf" @click="toLeft()"> &lt;</span>
      <span   v-show="rhShow"  class="mobile-tab-bar-btn rh" @click="toRight()"> &gt;</span>
       <div  class="list"  id="parent_list" @scroll.passive="tabScroll($event)" >
            <div class="item"  v-for="(item,index) in tabList" id="item_li" :key="index" :class="{'tab_backgroundA': index == tabIndex}" @click="changeTab(index)">
                <img :src="index == tabIndex?item.liveIcon : item.icon"/>
                <span>{{item.title}}</span>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    props:{
        tabList:{
            type:Array,
            default: []
        },
        tabIndex:{
            type:Number,
            default:0
        }
    },
    watch:{
        'tabIndex':function(o,n){
            console.log(this.tabIndex)
        }
    },
    data(){
        return{
            
            rhShow: true,
            lfShow: false,
        }  
    },
    created(){
    },
    watch:{
        'tabIndex':function(o,n){
            let ele = document.getElementById('parent_list')
            let item = document.querySelectorAll('#item_li')
            let left = item[0].offsetWidth * this.tabIndex
            // ele.scrollLeft = left
            if ((this.tabIndex + 1) > (ele.offsetWidth / item[0].offsetWidth)) {
                ele.scrollLeft = left
                console.log(ele.scrollLeft)
            } else if (ele.scrollLeft + ele.offsetWidth > ele.offsetWidth ){
                ele.scrollLeft = left
            }
           
        },
    },
    mounted(){
       
    },
    methods:{
        toLeft() {
            let idx = this.tabIndex - 1 > 0 ? this.tabIndex - 1 : 0;
            this.changeTab(idx)
        },
        toRight() {
            let idx = this.tabIndex + 1 < this.tabList.length ? this.tabIndex + 1 : this.tabList.length - 1;
            this.changeTab(idx)
        },
        tabScroll(e){

            if (e.target.scrollLeft == 0){
                this.rhShow = true
                this.lfShow = false
            } else {
                let ele = document.querySelectorAll('#item_li')
                let length = 0
                ele.forEach(ele => {
                    length += ele.offsetWidth
                })
                if(e.target.scrollLeft + e.target.offsetWidth < length){
                    this.rhShow = true 
                    this.lfShow = true
                }else if (e.target.scrollLeft + e.target.offsetWidth >= length){
                    this.rhShow = false 
                    this.lfShow = true
                }
            }
        },
        changeTab(index){
            this.$emit('changeTab',index)
        }
    }
}
</script>

<style lang="scss" scoped>
.swiper-tab{
    position: relative;
    display: flex;
    align-items: center;
    height: 160px;
     .mobile-tab-bar-btn{
        display: block;
        width: 64px;
        height: 100px;
        font-size: 48px;
        line-height: 100px;
        text-align: center;
        z-index: 99;
        background-color: #fff;
        color: #333;
     }
     .mobile-tab-bar-btn.lf{
        position: absolute;
        left: 0;
        border-right: 2px solid #333;
    }
    .mobile-tab-bar-btn.rh{
        position: absolute;
        right: 0;
        border-left: 2px solid #333;
    }
    ::-webkit-scrollbar {
        height: 0;
        width: 0;
        color: transparent;
    }
    ::-ms-scrollbar {
        height: 0;
        width: 0;
        color: transparent;
    }
}
.list{
    widows: 100vw;
    height: 160px;
    overflow-x: scroll;
    white-space: nowrap;
}
.item{
    display: inline-block;
    width: 300px;
    height: 156px;
    box-sizing: content-box;
    // background: pink;
    text-align: center;
    img{
        margin: 0 auto;
        padding: 20px 0;
        display: block;
    }
    span {
        font-size: 30px;
    }
}

.tab_backgroundA{
        border-bottom: 4px solid #343478;
        // background-color: red;
        span{
            color: #343478;
            font-size: 38px;
        }
    }
</style>
