<template>
  <div>
      <!-- nav -->
      <!-- <My-nav></My-nav> -->
      <!-- banner -->
      <template>
          <My-banner :bannerImg="bannerImg" v-if="!isMobile"></My-banner>
          <My-mobilBanner  :slideString="false" :bannerImg="bannerImg" id="nav" v-if="isMobile"></My-mobilBanner>
      </template>
      <!-- tab-bar -->
      
      <div :class="isMobile? 'mobile-tab-bar':'tab-bar'" >
        <div  class="tab-bar-li" v-for="(item,index) in tabListCoop" :key="index" :class="{'tab_backgroundA': index == tabIndex}" @click="tabIndex = index">
            <img :src="index == tabIndex?item.liveIcon : item.icon"/>
            <span>{{item.title}}</span>
        </div>
    </div>
    <div style="overflow:hidden;">
             <transition name="el-fade-in-linear">
            <div class="coop-box-store" v-if="tabIndex == 0">
             <div :class="isMobile? 'mobile-coop-box-main': 'coop-box-main'" >
                <div class="coop-box-main-title">
                    <h1>药房入驻上线流程</h1>
                    <p>精准营销，使药店销量提高，扩大品牌知名度，线上线下齐发展。</p>
                </div>
                <div class="coop-box-main-content" style="padding-top:105px;">
                    <div class="main-content-ul">
                        <span class="main-content-li left">单体药店</span>
                        <span class="main-content-li center">
                            <img src="../../assets/image/store_online_step1.png"/>
                            <p>1、准备资料</p>
                        </span>
                        <span class="main-content-li right">连锁药店</span>
                    </div>
                    <div class="next">
                        <img src="../../assets/image/next.png"/>
                    </div>
                </div>
                <div class="coop-box-main-content">
                    <div class="main-content-ul">
                        <span class="main-content-li left">商户资金安全</span>
                        <span class="main-content-li center">
                            <img src="../../assets/image/store_online_step1.png"/>
                            <p>2、签约银联</p>
                        </span>
                        <div class="main-content-li right" style="display:flex;flex-direction:column;line-height:40px;"><span style="margin-top:12px;">银联监管账户</span><span>T+7银联分账</span></div>
                    </div>
                    <div class="next">
                        <img src="../../assets/image/next.png"/>
                    </div>
                </div>
                <div class="coop-box-main-content">
                    <div class="main-content-ul">
                        <span class="main-content-li left">统一登录</span>
                        <span class="main-content-li center">
                            <img src="../../assets/image/store_online_step1.png"/>
                            <p>3、药店自主<br>完成注册</p>
                        </span>
                        <div class="main-content-li right" style="display:flex;flex-direction:column;line-height:40px;"><span style="margin-top:12px;">单体⼊驻平台</span><span>连锁增加分店信息</span></div>
                    </div>
                    <div class="next" style=" margin-top: 43px;">
                        <img src="../../assets/image/next.png"/>
                    </div>
                </div>
                <div class="coop-box-main-content">
                    <div class="main-content-ul">
                        <span class="main-content-li left">医智诺录⼊药品信息</span>
                        <span class="main-content-li center">
                            <img src="../../assets/image/store_online_step1.png"/>
                            <p>4、提供药品目录</p>
                        </span>
                        <span class="main-content-li right">附药品目录表格</span>
                    </div>
                    <div class="next" style="margin-top:43px;">
                        <img src="../../assets/image/next.png"/>
                    </div>
                </div>
                <div class="coop-box-main-content">
                    <div class="main-content-ul">
                        <span class="main-content-li left">输⼊价格并上架</span>
                        <span class="main-content-li center">
                            <img src="../../assets/image/store_online_step1.png"/>
                            <p>5、上架药品</p>
                        </span>
                        <span class="main-content-li right">医智诺审核</span>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <transition name="el-fade-in-linear">
            <div class="coop-box-doctor" v-if="tabIndex == 1">
                <div class="coop-box-main" >
                    <div class="coop-box-main-title">
                        <h1>医生注册上线流程</h1>
                        <p>医生入驻请扫描二维码下载“领医未来”，提交材料完成入驻申请</p>
                    </div>
                    <div :class="isMobile? 'mobile-coop-box-doctor-content':'coop-box-doctor-content'" style="background-color:#F6FAFF">
                        <div class="doctor-content-main" >
                            <div class="doctor-content-main-l">
                                <img  src="../../assets/image/doctor_step1.png" />
                            </div>
                            <div class="doctor-content-main-r">
                                <span>1</span>
                                <p>手机应用市场搜索<br>“领医未来” 完成下载</p>
                            </div>
                        </div>
                    </div>
                    <div :class="isMobile? 'mobile-coop-box-doctor-content':'coop-box-doctor-content'" style="background-color:#fff">
                        <div class="doctor-content-main" >
                            <div class="doctor-content-main-r">
                                <span>2</span>
                                <p>使用手机验证码登录，<br>并点击上传资料</p>
                            </div>
                            <div class="doctor-content-main-l">
                                <img src="../../assets/image/doctor_step2.png" />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <img src="../../assets/image/doctor_step2_1.png" />
                            </div>
                        </div>
                    </div>
                    <div :class="isMobile? 'mobile-coop-box-doctor-content':'coop-box-doctor-content'" style="background-color:#F6FAFF">
                        <div class="doctor-content-main" >
                            <div class="doctor-content-main-l">
                                <img src="../../assets/image/doctor_step3.png" />
                            </div>
                            <div class="doctor-content-main-r">
                                <span>3</span>
                                <p>完善资料-身份证正反面照片<br>执业资格证-工作证</p>
                            </div>
                        </div>
                    </div>
                    <div :class="isMobile? 'mobile-coop-box-doctor-content':'coop-box-doctor-content'" style="background-color:#fff">
                        <div class="doctor-content-main" >
                            <div class="doctor-content-main-r">
                                <span>4</span>
                                <p>手写个人电子签名<br>并点击提交认证。</p>
                            </div>
                            <div class="doctor-content-main-l">
                                <img src="../../assets/image/doctor_step4.png" />
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="tips">
                    <p>注：提交认证后，医智诺工作人员会在24小时内审核通过，通过后即可正常使用。</p>
                </div>
            </div>
            
        </transition>
    </div>
    
    <!-- tips -->
    
    <!-- footer -->
    <!-- <My-footer></My-footer> -->
  </div>
</template>

<script>
import "../../assets/styles/coop_Settlement.scss"
import { isMobile } from '../../../common/util'
export default {
    data(){
        return{
            tabIndex: 0,
            bannerImg: '',
            isMobile:isMobile(),
            tabListCoop: [
                {icon: require("../../assets/image/tab_icon3.png"),title:"药房",liveIcon: require("../../assets/image/tab_liveIcon3.png")},
                {icon: require("../../assets/image/tab_icon2d.png"),title:"医生",liveIcon: require("../../assets/image/tab_liveIcon2d.png")},
            ],
            bannerImgList: isMobile() ? [
                require("../../assets/image/banner_doctor_coop_2.png"),
                require("../../assets/image/banner_store_coop1_2.png"),
            ] : [
                require("../../assets/image/banner_doctor_coop.png"),
                require("../../assets/image/banner_store_coop1.jpg"),
            ],            
        }
    },
    watch:{
        '$route.params': function(val,oval){
           if (val.id == 1) {
               this.tabIndex = 0
               this.bannerImg = this.bannerImgList[0]

           } else if (val.id == 2) {
               this.tabIndex = 1
               this.bannerImg = this.bannerImgList[1]

           }
        },
        'tabIndex': function(val,oval){
            if (val == 0) {
               this.bannerImg = this.bannerImgList[0]

           } else if (val == 1) {
               this.bannerImg = this.bannerImgList[1]

           }
        }
    },
    mounted(){
       let m = this.$route.params
        if (m.id == 1){
            this.tabIndex = 0
            this.bannerImg = this.bannerImgList[0]
        } else if(m.id == 2){
            this.tabIndex = 1
            this.bannerImg = this.bannerImgList[1]
        }
    },
    methods:{
         _changeTab(m){
            this.tabIndex = m
        }
    }
}
</script>

<style lang="sss" scoped>

</style>