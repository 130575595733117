<template>
  <div>
       <div class="contact-main">
            <div  class="title">
                <h1>联系我们</h1>
                <p>如果您对我们有任何疑问，请联系我们，我们将竭诚为您服务。</p>
            </div>
            <ul :class="isMobile?'mobile-contact-main-ul':'contact-main-ul'">
                <li >
                    <img src="../../../assets/image/contact_position.png" />
                     <!-- <baidu-map :center="center" :zoom="zoom" @ready="handler" style="height:1080px" @click="getClickInfo" :scroll-wheel-zoom='true'></baidu-map> -->
                    <span>公司地址</span>
                    <!-- <p>西安市高新区锦业一路6号永利国际<br>金融中心1座13楼1309-1310</p> -->
                    <p>{{ $COMPANY_INFO.address }}</p>
                </li > 
                <li >
                    <img src="../../../assets/image/contact_phone.png" />
                    <span>公司电话</span>
                    <p>029-81774859</p>
                    <a v-if="isMobile" href="tel:029-81774859">点击拨打</a>
                </li>
                <li >
                    <img src="../../../assets/image/contact_email.png" />
                    <span>公司邮箱</span>
                    <p>echartnow@echartnow.com</p>
                </li>
                <li >
                    <img src="../../../assets/image/contact_internet.png" />
                    <span>公司网站</span>
                    <p>www.echartnow.com</p>
                </li>
            </ul>
            <div :class="isMobile?'mobile-contact-form' :'contact-form'">
                <div class="map" style="width:100%">
                     <baidu-map :center="center" zoomToAccuracy ='true' :zoom="zoom" @ready="handler" style="width:80%;height:500px;margin:0 auto;" @click="getClickInfo" :scroll-wheel-zoom='true'></baidu-map>
                </div>
                <div class="contact-info">
                    <p>留下您的联系信息以便我们与您联系</p>
                    <el-form  label-width="0" :model="contactForm"> 
                        <el-form-item >
                            <el-input v-model="contactForm.name" placeholder="姓名" size="medium"></el-input>
                        </el-form-item>
                        <el-form-item >
                            <el-input v-model="contactForm.phone"  placeholder="电话" size="medium"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="contactForm.email"  placeholder="邮箱" size="medium"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="contactForm.reamrk" type="textarea"  placeholder="备注" :rows="5"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button  style="width:100%;color:#999">提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import { isMobile } from '../../../../common/util'

export default {
    data(){
        return{
            contactForm: {
                name: '',
                phone: '',
                email: '',
                remark: ''
            },
            isMobile:isMobile(),
            center: {lng: 108.890299, lat: 34.196709},
            zoom: 13
        }
    },
    mounted(){
        //   //创建Map实例
        //     var map = new BMap.Map("XSDFXPage");
        //    // 初始化地图,设置中心点坐标
        //     var point = new BMap.Point(121.160724,31.173277);// 创建点坐标
        //     map.centerAndZoom(point,15);
        //     //添加鼠标滚动缩放
        //     map.enableScrollWheelZoom();

        //     //添加缩略图控件
        //     map.addControl(new BMap.OverviewMapControl({isOpen:false,anchor:BMAP_ANCHOR_BOTTOM_RIGHT}));
        //     //添加缩放平移控件
        //     map.addControl(new BMap.NavigationControl());
        //     //添加比例尺控件
        //     map.addControl(new BMap.ScaleControl());
        //     //添加地图类型控件
        //     //map.addControl(new BMap.MapTypeControl());

        //     //设置标注的图标
        //     var icon = new BMap.Icon("./static/img/map.png",new BMap.Size(100,100));
        //     //设置标注的经纬度
        //     var marker = new BMap.Marker(new BMap.Point(121.160724,31.173277),{icon:icon});
        //     //把标注添加到地图上
        //     map.addOverlay(marker);
        //     var content = "<table>";
        //     content = content + "<tr><td> 编号：001</td></tr>";
        //     content = content + "<tr><td> 地点：上海汉得信息技术股份有限公司</td></tr>";
        //     content = content + "<tr><td> 时间：2018-1-3</td></tr>";
        //     content += "</table>";
        //     var infowindow = new BMap.InfoWindow(content);
        //     marker.addEventListener("click",function(){
        //         this.openInfoWindow(infowindow);
        //     });

        //     //点击地图，获取经纬度坐标
        //    map.addEventListener("click",function(e){
        //         document.getElementById("aa").innerHTML = "经度坐标："+e.point.lng+" &nbsp;纬度坐标："+e.point.lat;
        //     });

    },
    methods: {

        handler ({BMap, map}) {
        var point = new BMap.Point(108.890299, 34.196709)
        map.centerAndZoom(point, 19)
        var marker = new BMap.Marker(point) // 创建标注
        map.addOverlay(marker) // 将标注添加到地图中
        var circle = new BMap.Circle(point, 6, { strokeColor: 'Red', strokeWeight: 6, strokeOpacity: 1, Color: 'Red', fillColor: '#f03' })
        var content = "<table>";
            // content = content + "<tr><td>&nbsp;&nbsp; </td></tr>";
            content = content + "<tr><td> 地点：陕西医智诺信息科技有限公司</td></tr>";
            // content = content + "<tr><td> &nbsp;&nbsp;</td></tr>";
            content += "</table>";
        var label = new BMap.Label(content, {       // 创建文本标注
            position: point,
            offset: new BMap.Size(-150, -80)
        })  
        map.addOverlay(label)
            label.setStyle({                              // 设置label的样式
            color: '#343478',
            fontSize: '16px',
            padding:'10px',
            border: '1px solid #ccc',
            cursor: "pointer"
        })
            map.panBy(328,250)
        },
        getClickInfo (e) {
        console.log(e.point.lng)
        console.log(e.point.lat)
        this.center.lng = e.point.lng
        this.center.lat = e.point.lat
        }
    }
}
</script>

<style lang="scss" scoped>
.XSDFXPage{
        width: 656px;
        height: 500px;
        overflow: hidden;
        margin: 0px auto;
        font-family: "微软雅黑";
    }
   // employee-welfare-main
    .contact-main{
        width: 100%;
        padding-top: 101px;
        padding-bottom: 112px;
        background:#F6FAFF;
        .mobile-contact-main-ul{
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            li{
                width:45%;
                height: 240px;
                background: #E6AA2E;
                color: #ffffff;
                margin-bottom: 30px;
                img{
                    display: block;
                    margin: 47px auto 31px;
                }
                span{
                    display: block;
                    font-size: 18px;
                    text-align: center;
                }
                p{
                    text-align: center;
                    margin-top: 26px;
                    font-size: 16px;
                }
                a{
                    display: block;
                    width:40%;
                    height:30px;
                    background-color:#fff;
                    background: rgba(225,225,225,0.5);
                    color: #fff;
                    text-align: center;
                    line-height: 30px;
                    margin: 20px auto;
                    border-radius: 25px;
                }
            }
        }
        .contact-main-ul{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            li{
                width: 298px;
                height: 240px;
                background: #E6AA2E;
                color: #ffffff;
                img{
                    display: block;
                    margin: 47px auto 31px;
                }
                span{
                    display: block;
                    font-size: 18px;
                    text-align: center;
                }
                p{
                    text-align: center;
                    margin-top: 26px;
                    font-size: 16px;
                }
            }
        }
        .mobile-contact-form{
            width: 100%;
            margin-top: 73px;
            display: flex;
            flex-wrap: wrap;
            margin:73px auto 0 ;
            .contact-info{
                width: 80%;
                margin: 20px auto;
                p{
                    color: #333333;
                    font-size: 18px;
                    margin-bottom: 27px;
                }
                ::v-deep .el-input__inner{
                    height: 61px;
                }
            }
        }
        .contact-form{
            width: 1200px;
            height:500px;
            margin-top: 73px;
            display: flex;
            justify-content: space-between;
            margin:73px auto 0 ;
            .contact-info{
                width: 475px;
                p{
                    color: #333333;
                    font-size: 18px;
                    margin-bottom: 27px;
                }
                ::v-deep .el-input__inner{
                    height: 61px;
                }
            }
        }
        
    }
     // title
    .title{
        color: #333333;
        text-align: center;
        h1{
            font-size: 45px;
            margin-bottom: 23px;
        }
        p{
            font-size: 18px;
            margin-bottom: 85px;
        }
    }
</style>