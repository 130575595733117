import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
const cfg = require('/public/config').default

import '../src/assets/icons';
import '../src/assets/css/font.css';

import footer from "../src/components/footer/index.vue"
import nav from "../src/components/nav/index.vue"
import newNav from '../src/components/new_nav/index.vue'
import newFooter from '../src/components/new_footer/index.vue'
import banner from "../src/components/banner/index.vue"
import mobilBanner from "../src/components/mobileBanner/index.vue"
import mobilFooter from "../src/components/mobileFooter/index.vue"
import mobilMenu from "../src/components/mobileMenu/index.vue"
import swiperTab from "../src/components/SwiperTab/index.vue"
import EcnTitle from "../src/components/ecn-title"
import EcnViewData from "../src/components/ecn-view-data"
import EcnSlide from "../src/components/ecn-slide"
import BaiduMap from 'vue-baidu-map'
import Moment from 'moment'
import VueTouch from 'vue-touch'
import Carousel3d from 'vue-carousel-3d'

Vue.component('My-footer',footer)
Vue.component('My-nav',nav)
Vue.component('My-banner',banner)
Vue.component('My-mobilBanner',mobilBanner)
Vue.component('My-mobilFooter',mobilFooter)
Vue.component('My-mobilMenu',mobilMenu)
Vue.component('My-swiper-tab',swiperTab)
Vue.component('My-new-nav',newNav)
Vue.component('My-new-footer',newFooter)
Vue.component('ecn-title', EcnTitle)
Vue.component('ecn-view-data', EcnViewData)
Vue.component('ecn-slide', EcnSlide)


Vue.prototype.$moment = Moment
Vue.prototype.$jumpOuter = (url) => {
  let regs =  "^((https|http|ftp|rtsp|mms)?://)"  //https、http、ftp、rtsp、mms
  + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" //ftp的user@
  + "(([0-9]{1,3}\\.){3}[0-9]{1,3}" // IP形式的URL- 例如：199.194.52.184
  + "|" // 允许IP和DOMAIN(域名)
  + "([0-9a-z_!~*'()-]+\\.)*" // 域名- www.
  + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\\." // 二级域名
  + "[a-z]{2,6})" // first level domain- .com or .museum
  + "(:[0-9]{1,5})?" // 端口号最大为65535,5位数
  + "((/?)|" // a slash isn't required if there is no file name
  + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)";

  let reg = new RegExp(regs)
  if (reg.test(url)) {
    url = url.indexOf('http:/') >= 0 || url.indexOf('https:/') >= 0 || url.indexOf('ftp:/') >= 0 ? url : 'http://' + url
  }  
  return url
}

Vue.prototype.$COMPANY_INFO = cfg.COMPANY_INFO

Vue.use(BaiduMap, {
  ak: '8124fcceba79b1fdce901124ee5ef7b4'
})
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(VueTouch, {name: 'v-touch'})
Vue.use(Carousel3d)

// import Directives from '../src/directives/index';

router.beforeEach((to, from, next) => {
  store.commit('changeActiveMenu',to.path)
  if(from.path.indexOf('/newsCenterDetail') > -1 &&  to.path.indexOf('/newsCenter') > -1){
    //添加自定义属性 来判断来自哪个页面
    to.params.isNewDetail = true
    next()
  }else{
    document.querySelector('#app').scrollTo(0, 0)
    to.params.isNewDetail = false
    next()
  }
})

new Vue({
  el: '#app',
  render: h => h(App),
  router: router,
  store,
}).$mount('#app')
