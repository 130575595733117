const scrollHeaderStyle = function (scrollTop,context, pageType) {
    //滚动条滚动的事件
    context.scrollTop = scrollTop;
    let option = {
        backgroundColor:'',
        navShow:'',
        textColor:'',
        activeColor:'',
        isTransparent:'',
    }
    if(pageType && pageType === 'positionInfoPage'){//一直显示高亮
        option.backgroundColor ='#ffffff'
        option.navShow = true
        option.textColor ='#91969c'
        option.activeColor ='#000'
        option.isTransparent = false
    }else {//滚动banner
        if(scrollTop >= 160) {
            option.navShow = true
            option.textColor ='#91969c'
            option.activeColor ='#000'
            option.isTransparent = false
        } else if(scrollTop < 160) {
            option.navShow = false
            option.textColor ='#91969c'
            option.activeColor ='#fff'
            option.isTransparent = true
        }
        option.backgroundColor = `rgba(255, 255, 255, ${scrollTop / 460})`
    }
    context.$refs.header.set(option)
}
export default scrollHeaderStyle