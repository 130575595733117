<template>
  <div class="product-cistanche"> 
     <newsHeader></newsHeader>
     <div class="">
       <div class="view-width flexRowBetFir padding-item">
         <div class='flexColFirCen'>
           <div class="project-name margin-top-87">苁蓉-数字化运营平台</div>
           <div class="product-description">「苁蓉-数字化运营平台」简单易用的医疗行业项目管理平台，全方位解决医生、服务商、药厂管理痛点，降低整合成本，合规处理业务，提高工作效率，加速项目进程。</div>
         </div>
         <div class="product-img"><img class="img1" src="../../assets/image/product/cistanche/project.png" alt="苁蓉-数字化运营平台"></div>
       </div>
       <div class="view-width padding-item11">
         <ecn-title title="构建学科数字化协同管理平台"></ecn-title>
         <div class="model-item flexRowBetCen padding-item1">
           <div class="product-img"><img class="img245" src="../../assets/image/product/cistanche/model1.png" alt="项目管理"></div>
           <div class='flexColCen2'>
             <div class="project-name">项目管理</div>
             <div class="product-description">对于项目进度进行实时跟踪，项目从立项-执行-交付-结题报告，建立全生命周期的完整证据链留存，方便审计进行穿透式核查，避免纸质资料丢失损坏的风险。</div>
           </div>
         </div>
       </div>
       <div class="bg-gray">
         <div class="view-width model-item flexRowBetCen padding-item2">
           <div class='flexColCen2'>
             <div class="project-name">结算单管理</div>
             <div class="product-description">查看电子结算单明细，交付物数量及具体金额；方便合规和财务部在线审核交付物数量和质量；实现在线审批，加快项目交付速度和进程。</div>
           </div>
           <div class="product-img"><img class="img3" src="../../assets/image/product/cistanche/model2.png" alt="结算单管理"></div>
         </div>
       </div>
       <div class="view-width model-item flexRowBetCen padding-item3">
         <div class="product-img"><img class="img245" src="../../assets/image/product/cistanche/model3.png" alt="电子签章"></div>
         <div class='flexColCen2'>
           <div class="project-name">电子签章</div>
           <div class="product-description">通过线上电子签章，缩短双方内部沟通成本，提升双方沟通效率，提升项目的交付和审核速度，实现高效合作。</div>
         </div>
       </div>
       <div class="bg-gray">
         <div class="view-width model-item flexRowBetCen padding-item4">
           <div class='flexColCen2'>
             <div class="project-name">数据看板</div>
             <div class="product-description">对于数据多维度分析，助于企业优化运营决策，提升ROI，为营销、管理、财务作强有力支撑。同时数据权限隔离，权责明确。</div>
           </div>
           <div class="product-img"><img class="img245" src="../../assets/image/product/cistanche/model4.png" alt="结算单管理"></div>
         </div>
       </div>
     </div>
     <My-new-footer></My-new-footer>
  </div>
</template>

<script>
import newsHeader from '@/views/newsHeader/index'
export default {
    name:'product-cistanche',
    components: {
        newsHeader
    },
    data(){
        return{
           
        }
    },
    mounted(){
       
    },
    methods: {
       
    } 
}
</script>

<style lang="scss" scoped>
  .product-cistanche{
    font-family: SourceHanSansCN-Regular;
    padding-top: 74px;
    div{
      box-sizing: border-box;
    }
    .margin-top-87{
      margin-top: 87px;
    }
    .padding-item{
      padding: 80px 39px 80px 72px;
    }
    .padding-item1{
      padding: 37px 72px 19px 12px;
    }
    .padding-item11{
      padding: 80px 0 19px;
    }
    .padding-item2{
      padding: 76px 14px 83px 72px;
    }
    .padding-item3{
      padding: 109px 72px 88px 12px;
    }
    .padding-item4{
      padding: 109px 33px 88px 72px;
    }
    .project-name{
      font-family: YouSheBiaoTiHei;
      color: #212121;
      line-height: 70px;
      margin-bottom: 22px;
      font-size: 54px;
    }
    .product-description{
      width: 466px;
      font-weight: 400;
      font-size: 20px;
      color: #212121;
      font-family: PingFangSC-Regular, PingFang SC;
      line-height: 32px;
      text-align: justify;
    }
    .model-item{
      .project-name{
        margin-bottom: 22px;
        width: 462px;
        text-align: left;
        font-size: 28px;
        line-height: 30px;
      }
      .product-description{
        width: 462px;
        text-align: justify;
      }
    }
    .flexRowBetCen{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .flexRowBetFir{
      display: flex;
      justify-content: space-between;
    }
    .flexColFirCen{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .flexColCen2{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .product-img{
      img{
        display: block;
      }
      .img1{
        width: 678px;
        height: 532px;
      }
      .img245{
        width: 599px;
        height: 503px;
      }
      .img3{
        width: 626px;
        height: 541px;
      }
    }
    .ecn-title ::v-deep {
      .title{
        font-size: 28px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: normal;
        color: rgba(0,0,0,0.9);
        letter-spacing: 3px;
      }
    }
    .bg-gray{
      background-color: #F9F9FD;
    }
  }
</style>