<template>
    <div class="ecn-big-data">
        <div class="cooperative content">
            <div>
                <img src="../../assets/image/cooperative-icon.png" alt="" />
                <div class="num">
                    <div>100</div>
                    <span>+</span>
                </div>
                <div class="title">合作企业</div>
            </div>
        </div>
        <div class="big-data-list">
            <div v-for="({ url, txt, num }, i) in contentList" :key="url">
                <div class="content" :class="{mask: i % 2 === 0}">
                    <div>
                        <img :src="url" alt="" />
                        <div class="num">
                            <div>{{ num }}</div>
                            <span>+</span>
                        </div>
                        <div class="title">{{ txt }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EcnViewData",
    data() {
        return {
            contentList: [
                {
                    num: 3000,
                    txt: "医院覆盖",
                    url: require("../../assets/image/hospital-icon.png"),
                },
                {
                    num: 50000,
                    txt: "医生上线",
                    url: require("../../assets/image/auscultation-icon.png"),
                },
                {
                    num: "1.5亿",
                    txt: "患者覆盖",
                    url: require("../../assets/image/facility-icon.png"),
                },
                {
                    num: "30000",
                    txt: "药房签约数",
                    url: require("../../assets/image/pharmacy-icon.png"),
                },
                {
                    num: "15万",
                    txt: "上线药品",
                    url: require("../../assets/image/drug-icon.png"),
                },
                {
                    num: "4000",
                    txt: "销售团队",
                    url: require("../../assets/image/team-icon.png"),
                },
            ],

        }
    },
};
</script>
<style lang="scss" scoped>
    .ecn-big-data {
        display: flex;
        flex-wrap: nowrap;
        justify-content: baseline;
        align-items: flex-start;
        padding-top: 43px;
        padding-bottom: 147px;

        .cooperative {
            width: 440px;
            height: 560px;
            background: no-repeat url('../../assets/image/cooperative.png');
            background-size: 100% 100%;
            background-color: linear-gradient(135deg, #0FCAA1 0%, #025884 51%, #082B8E 100%);
            img {
                width: 72px;
                height: 72px;
            }
        }
        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFFFFF;
            text-align: center;
            img {
                width: 72px;
                height: 72px;
            }
            .num {
                font-size: 52px;
                font-family: SourceHanSansCN-Bold;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 78px;
                letter-spacing: 4px;
                display: flex;
                align-items: flex-start;
                span {
                    font-size: 18px;
                    line-height: 40px;
                    font-weight: 100;
                    padding-left: 3px;
                }
            } 
            .title {
                font-size: 13px;
                font-family: SourceHanSansCN-Medium;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 4px;
            }
        }
        .big-data-list {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            background: linear-gradient(135deg, #32D0AD 0%, #0C73D5 52%, #2553D6 100%);
            .content {
                width: 280px;
                height: 280px;
                img {
                    width: 60px;
                    height: 60px;
                }
                .num {
                    font-size: 36px;
                    line-height: 54px;
                    letter-spacing: 3px;
                    span {
                        font-size: 16px;
                    }
                }
                .title {
                    letter-spacing: 1px;
                }
            }
            .mask {
                background: rgba(0, 0, 0, 0.09);
            }
        }
    }
</style>