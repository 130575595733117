<template>
  <vue-seamless-scroll
    :data="dataList"
    :class-option="classOption"
    class="warp"
    ref="txtscroll"
  >
    <ul class="ul-item">
      <li class="li-item" v-for="(item, index) in dataList" :key="index" @click="itemClick($event)">
        <div class="li-circle"></div><a :href="$jumpOuter(item.toUrl)" v-if="item.toUrl" target="_blank">{{ item.title }}</a>
        <span v-else>{{ item.title }}</span>
      </li>
    </ul>
  </vue-seamless-scroll>
</template>

<script>
  import vueSeamlessScroll from 'vue-seamless-scroll'

  export default {
    name: 'TextScroll',
    components: {
      vueSeamlessScroll
    },
    props: {
        dataList: {
            type: Array
        }
    },
    data () {
      return {
        classOption: {
          limitMoveNum: 1,
          direction: 2,
          step: 2.3
        }
      }
    },
    methods: {
      itemClick(e) {
        this.$refs.txtscroll.enter()
        setTimeout(() => {
          this.$refs.txtscroll.leave()
        }, 3000)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .warp {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      &.ul-item {
        display: flex;
        .li-item {
          width: auto;
          margin-top: 0px;
          margin-right: 150px;
          line-height: 28px;
          background-color: #F6FAFF;
          color: #333;
          text-align: center;
          font-size: 28px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }    
  }
  .li-circle{
        position: relative;
        top: -2px;
        width: 12px !important;
        height: 12px;
        border-radius: 50%;
        margin-left: 100px;
        margin-right: 10px;
        background-color: #D7D7D7 ;
        display: inline-block;
    }

    a {
			text-align: center;
			color: #343478;
		}
</style>