<template>
  <div class="about-page" @scroll="scroll">
    <My-new-nav ref="header" :isHome='false'></My-new-nav>
    <div class="page-width about-cont">
      <div class="banner">
        <div class="view-width">
          <div class="banner-title">医疗行业革命者</div>
          <div class="banner-message">让医疗价值回归，让医疗更加温暖</div>
        </div>
      </div>
      <div class="view-bg-black">
        <div class="view-width view-pt-68">
          <ecn-title title="医智诺集团" message="搭建医疗新生态"></ecn-title>
          <div class="introduce">
            <div class="message">
              <div>医智诺是香港上市公司华谊腾讯（HK00419）旗下的医疗集团，控股子公司。</div>
              <div>医智诺战略定位为医疗产业数字化运营并跑者，目前拥有实体医院、互联网诊疗资质及医药／耗材全功能牌照、医学研究和试验发展经营资质、三级等保、CA认证等国家颁发的合法资质。自主研发了医学运营管理平台、专业化药房管理平台、学科建设运营平台、数字化营销平台、患者健康服务平台，保证平台的信息安全及可靠性。医智诺凭借创新的技术能力和产品研发实力，致力于布局全产业医疗服务生态圈，搭建全场景化医疗服务平台，为健康中国助力。</div>
              <div>医智诺产品研发中心设立在陕西西安，目前已在全国建立9个（5个大区，4个合作伙伴）运营中心，业务覆盖27个省份。公司将利用雄厚的资金、技术、资源实力，顺应国家医疗政策，打造医生+医药+检验+科研+保险的新医疗服务、新健康服务的价值链。</div>
            </div>
            <div class="video">
              <video ref="video" class="video-cont" :controls="videoControls" src="https://video.echartnow.com/sv/2633573c-183545037f3/2633573c-183545037f3.mp4">
                <p class="message">您的浏览器不支持播放视频</p>
              </video>
              <div class="video-play" v-show="!isPlay">
                <img src="../../assets/image/icon-play.png" alt="" @click="operateVideo">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="view-width view-pt-68">
          <ecn-title title="医智诺集团" message="搭建医疗新生态"></ecn-title>
          <div class="intro-list">
            <div class="intro-item">
              <img src="../../assets/image/aboutUs/icon-intro-1.png" alt="">
              <div class="intro-item-title">上市公司</div>
              <div class="intro-item-message">香港上市公司<br/>华谊腾讯(HK00419)<br/>控股子公司</div>
            </div>
            <div class="intro-item">
              <img src="../../assets/image/aboutUs/icon-intro-2.png" alt="">
              <div class="intro-item-title">合法合规</div>
              <div class="intro-item-message">互联网诊疗资质<br/>医药／耗材全功能牌照<br/>CA认证在内的12+合法资质</div>
            </div>
            <div class="intro-item">
              <img src="../../assets/image/aboutUs/icon-intro-3.png" alt="">
              <div class="intro-item-title">全国布局</div>
              <div class="intro-item-message">已在全国建立9个运营中心<br/>（5个大区，4个合作伙伴）<br/>业务覆盖27个省市自治区</div>
            </div>
            <div class="intro-item">
              <img src="../../assets/image/aboutUs/icon-intro-4.png" alt="">
              <div class="intro-item-title">技术创新</div>
              <div class="intro-item-message">18+软件著作权<br/>布局全产业医疗服务生态圈<br/>搭建全场景化医疗服务平台</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="view-bg-black">
        <div class="view-width view-pt-68">
          <ecn-title title="医智诺大数据" message="海量数据 无限可能"></ecn-title>
          <ecn-view-data></ecn-view-data>
        </div>
      </div> -->
      <div class="view-qualifications">
        <div class="view-width view-qualifications-bg">
          <ecn-title class="view-pt-68" title="合法合规资质" message="高新技术企业、互联网药品信息服务企业"></ecn-title>
          <div>
            <ecn-slide></ecn-slide>
          </div>
        </div>
      </div>
    </div>
    <My-new-footer></My-new-footer>
  </div>

</template>
<script>
import scrollHeaderStyle from "../newJoinus/utils";
export default {
  components: {

  },
  data() {
    return {
      videoControls: false,
      isPlay: false,
      pageType: "ecologicalSolutionPage",
    }
  },
  activated(){
      let context = this;
      scrollHeaderStyle(0, context, this.pageType )
  },
  mounted() {
    // 监听视频播放
    // this.$refs.video.addEventListener("play", () => {
    //   this.videoControls = true;
    //   this.isPlay = true;
    // });
    // // 监听视频暂停
    // this.$refs.video.addEventListener("pause", () => {
    //   this.videoControls = false;
    //   this.isPlay = false;
    // });
  },
  methods: {
    operateVideo() {
      this.$refs.video.play()
      this.videoControls = true;
      this.isPlay = true;
    },
    scroll(e){
      //滚动条滚动的事件
      let context = this;
      scrollHeaderStyle(e.srcElement.scrollTop, context, this.pageType )
    },
  }
}
</script>
<style lang="scss" scoped>
.about-page {
  font-size: 0;
  height: 100vh;
  overflow: auto;
  .about-cont {
    background-image: url('../../assets/image/aboutUs/bg-introduce.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-attachment: fixed;
  } 
  .banner {
    height: 520px;
  }
  .banner-title {
    font-size: 60px;
    font-family: YouSheBiaoTiHei;
    color: #FFFFFF;
    line-height: 78px;
    letter-spacing: 6px;
    text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
    padding-top: 204px;
  }
  .banner-message {
    font-size: 18px;
    font-family: SourceHanSansCN-Bold;
    color: #FFFFFF;
    line-height: 27px;
    letter-spacing: 9px;
    text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
    margin-top: 19px;
  }
  .introduce {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items:flex-start;
    padding: 40px 0;
    padding-bottom: 100px;
    .message {
      width: 676px;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 30px;
      letter-spacing: 2px;
      div {
        margin-bottom: 30px;
      }
    }
    .video {
      width: 572px;
      height: 336px;
      margin-top: 8px;
      position: relative;
      .video-cont{
        width: 100%;
        height: 100%;
      }
      .video-play {
        width: 572px;
        height: 336px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        img {
          width: 120px;
          height: 120px;
          cursor: pointer;
        }
      }
    }
  }
  .intro-list {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 52px 55px;
    padding-bottom: 94px;
    .intro-item {
      width: 270px;
      height: 336px;
      background: rgba(6, 45, 103, 0.7);
      box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.04);
      text-align: center;
      img {
        width: 140px;
        height: 108px;
        margin-top: 54px;
      }
      .intro-item-title {
        font-size: 22px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 30px;
        padding-top: 16px;
        padding-bottom: 8px;
      }
      .intro-item-message {
        font-size: 16px;
        font-weight: 400;
        color: #ACC7ED;
        line-height: 26px;
      }
    }
  }
  .view-qualifications {
    background-image: url('../../assets/image/aboutUs/certification-img.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .view-qualifications-bg {
    height: 900px;
    background-image: url('../../assets/image/aboutUs/certification-bg-img.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  
}
</style>