<template>
  <div>
      <!-- employee-welfare-main -->
      <div :class="isMobile?'mobile-employee-welfare-main':'employee-welfare-main'">
            <div  class="title">
                <h1>福利这么多，真好</h1>
                <p>五险一金是标配，其他还会少？</p>
            </div>
            <ul class="welfare-main-ul">
                <li class="li-one">
                    <img src="../../../assets/image/welfare1.png" />
                    <span>带薪假期</span>
                </li > 
                <li class="li-one">
                    <img src="../../../assets/image/welfare2.png" />
                    <span>五险一金</span>
                </li>
                <li class="li-one">
                    <img src="../../../assets/image/welfare3.png" />
                    <span>笔记本补助</span>
                </li>
                <li class="li-one">
                    <img src="../../../assets/image/welfare4.png" />
                    <span>晚间加餐</span>
                </li>
                <li class="li-one" style="margin-right:0;">
                    <img src="../../../assets/image/welfare5.png" />
                    <span>零食饮料</span>
                </li>
                <li>
                    <img src="../../../assets/image/welfare6.png" />
                    <span>免费水果</span>
                </li>
                <li>
                    <img src="../../../assets/image/welfare7.png" />
                    <span>节日福利</span>
                </li>
                <li :style="isMobile ? 'display:none;':''">
                    <img src="../../../assets/image/welfare9.png" />
                    <span>红白事礼金</span>
                </li>
                <li>
                    <img src="../../../assets/image/welfare8.png" />
                    <span>加班餐补</span>
                </li>
                <li style="margin-right:0;">
                    <img src="../../../assets/image/welfare10.png" />
                    <span>年终奖</span>
                </li>
            </ul>
        </div>
         <!-- employee-welfare-main -->
        <div class="recruit" v-if="isMobile && dataList">
            <div class="recruit_title">招聘职位</div>
            <div class="recruit_tip">如果你刚好满足我们的条件，我们期待你的加入！</div>
        </div>
        <div class="content" v-if="isMobile && dataList">
            <ul>
                <li class="li_item" v-for="(item,index) in dataList" :key="index" @click="liitem(item.id)">
                    <div class="li_item_content">{{cutInfo(item.jobName)}}</div>
                    <div class="li_item_content_tip">
                        <span>{{cutInfo(item.requireInfo).replace(/<[^<>]+>/g, "").replace('...','')+'...'}}</span>
                    </div>
                </li>               
            </ul>
        </div>
      <div class="recruitment-position-main" v-else-if="dataList">
            <div  class="title">
                <h1>招聘职位</h1>
                <p>如果你刚好满足我们的条件，我们期待你的加入！</p>
            </div>
            <div class="position-main-center" >
                <ul class="position-main-center-left" style="overflow:auto;height: 100%;">                    
                    <li v-for="(item,index) in dataList" :key="index" @click="btnIndex = index" @mousemove="btnHoverIndex = index" :class="{'btnBack':index == btnIndex}" :title="item.jobName"><span>{{item.jobName}}</span></li>
                </ul>
                <div class="position-main-center-right">
                        <div v-for="(item,index) in dataList" :key="index" v-show="btnIndex == index">
                            <h2>工作职责：</h2>
                            <p v-html="item.duty.replace(new RegExp('\r\n','gm'),'<br />').replace(new RegExp('\n','gm'),'<br />')"></p>
                            <h2 style="margin-top:53px;">任职要求：</h2>
                            <p class="position" v-html="item.requireInfo.replace(new RegExp('\r\n','gm'),'<br />').replace(new RegExp('\n','gm'),'<br />')"></p>
                            <h2 style="margin-top:53px;">工作地点：</h2>
                            <p class="position" v-html="item.address"></p>
                            <h2 style="margin-top:53px;">公司信息：</h2>
                            <p>➢ 联系方式：029-81123622</p>
                            <p>➢ 简历邮箱：wangmei@echartnow.com </p>
                            <p>➢ 邮箱简历投递格式：附件名字+应聘岗位+工作地点，例：张三+陕西商务岗位+西安。 </p>
                            <p>➢ 集团办公地点：陕西省西安市高新区锦业一路6号永利金融中心14楼1401-02-03。</p>
                        </div>
                </div>
            </div>
      </div>
  </div>
</template>

<script>
import { isMobile } from '../../../../common/util'
import { selectAllPosition } from '../../../../common/api'
export default {
    data(){
        return{
            btnIndex: 0,
            btnHoverIndex: 0,
            isMobile:isMobile(),
            dataList: null
        }
    },
    mounted() {
        this.getJobList()
    },
    methods:{
        getJobList() {
            let data = {
                pageNum: 1,
                pageSize: 3,
                isOfficial: 1
            }
            selectAllPosition(data).then((res) => {
                if (res.responseCode == 0) {
                    this.dataList = res.responseBody                    
                }
            })
        },
        liitem(n){
            this.routerJump('/recruidetail',n)
        },
        routerJump(m,n){
            this.$router.push({path:m,query:{id:n}})
            this.$store.commit('changeActiveMenu',m)
            this.$emit('closemenu',{})
            window.scrollTo(0,0)
        },
        cutInfo(info) {
             info = info.replace(new RegExp('\r\n','gm'),'<br />').replace(new RegExp('\n','gm'),'<br />').replace('1、','').replace('1.','')
             if (info.length > 19)
             {
                 return info.substring(0,19) + "..."
             } else {
                 return info 
             }
        }
    }
}
</script>

<style lang="scss" scoped>
     // employee-welfare-main
    .mobile-employee-welfare-main{
        width: 100%;
        padding-top: 101px;
        padding-bottom: 112px;
        background:#F6FAFF;
        .welfare-main-ul{
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            li{
                width: 30%;
                color: #333333;
                margin-bottom: 20px;
                img{
                    display: block;
                    margin: 0 auto;
                    height: 64px;
                }
                span{
                    display: block;
                    font-size: 18px;
                    text-align: center;
                    margin-top:30px;
                }
            }
        }
        
    }
    .employee-welfare-main{
        width: 100%;
        padding-top: 101px;
        padding-bottom: 112px;
        background:#F6FAFF;
        .welfare-main-ul{
            width: 1020px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            .li-one{
                margin-top: 0;
            }
            li{
                width: 90px;
                margin-right: 135px;
                margin-top: 78px;
                color: #333333;
                img{
                    display: block;
                    margin: 0 auto;
                    height: 64px;
                }
                span{
                    display: block;
                    font-size: 18px;
                    text-align: center;
                    margin-top:30px;
                }
            }
        }
        
    }
    // recruitment-position-main
    .recruitment-position-main{
        padding-top: 102px;
        padding-bottom: 120px;
        .position-main-center{
            width: 1200px;
            margin: 0 auto;
            background-size: 100% 100%;
            background: url('../../../assets/image/position_back.png') no-repeat center center;
            background-size: 100% 100%;
            padding-top: 68px;
            padding-bottom: 68px;
            display: flex;
            justify-content: space-between;
            .position-main-center-left{
                padding-left:58px;
                background: transparent;
                height: 365px;
                color: #ffffff;
                li{
                    width: 216px;
                    height: 41px;
                    line-height: 41px;
                    border-radius: 20.5px;
                    text-align: center;
                    margin-bottom: 35px;
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
                li:hover{
                     background: rgba(255, 255, 255,0.3);
                }
                .btnBack{
                    background: #FFFFFF;
                    color: #343478 
                }
                .btnBack:hover{
                    background: #FFFFFF;
                }
            }
            .position-main-center-right{
                width: 764px;
                height: auto;
                padding: 65px 0px  65px 71px;
                background: #ffffff;
                overflow: hidden;
                overflow-y: scroll;
                // overflow-y：auto
                div{
                    h2{
                        font-size: 24px;
                        color: #333333 ;    
                        margin-bottom: 30px;
                    }
                    p{
                        color: #666666 ;    
                        font-size: 20px;
                        line-height: 38px;
                    }
                }
            }
        }
    }
     // title
    .title{
        color: #333333;
        text-align: center;
        h1{
            font-size: 45px;
            margin-bottom: 23px;
        }
        p{
            font-size: 18px;
            margin-bottom: 85px;
        }
    }
.recruit{
    width:100vw;
    height:132px;
    margin-bottom: 14px;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
    .recruit_title{
        margin: 18px auto 32px;  
        text-align: center;
        height:34px;
        font-size: 36px;
        font-weight: 900;
        font-family: Source Han Sans CN;
        color: #333333;
        line-height: 28px;
    }
    .recruit_tip {
        margin:0 auto;
        height: 30px;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        font-family: Source Han Sans CN;
        color: #333333;
        line-height: 30px;
    }
}
.content{
    widows: 100vw;
    background: #F6FAFF;
    .li_item{
     background: #ffffff;
     margin-bottom: 3px;
     width: 100%;
    }
    .li_item_content{
        height: 34px;
        font-size:30px;
        line-height: 32px;
        font-family: Source Han Sans CN;
        color: #333333;
        padding-left:123px;
        padding-top:29px;
        margin-bottom: 27px;
        font-weight: 900;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
    }
    .li_item_content_tip span{
        display:inline-block;
        margin-bottom: 30px;
        padding-left:123px;
        height:26px;
        font-size: 26px;
        font-weight: 500;
        color: #666666;
        line-height: 26px;
        font-family: Source Han Sans CN;
      
    }
}
</style>