import {get, post, del } from "../libs/axios";

// 打开就医助手小程序
export const openWelink = formData => get("miniQY/getQyHomeOpenLink", formData)
// 招聘职位
export const selectAllPosition = formData => get("/website/selectAllPosition", formData)
// 职位详情
export const queryPosition = formData => get("/website/selectById", formData)
// 企业简介
export const selectIntroduce = formData => get("/website/selectIntroduce", formData)
// 轮播文字
export const selectTextList = formData => get("/website/selectTextList", formData)
// 合作企业
export const selectEnterpriseList = formData => get("/website/selectEnterpriseList", formData)
// 首页轮播图
export const selectCarouselList = formData => get("/website/selectCarouselList", formData)
// 资讯列表
export const selectNewList = formData => get("/website/selectNewList", formData)
//资讯详情
export const selectNewsById = formData => get("/website/selectNewsById", formData)