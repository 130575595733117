<template>
  <div class="abouts_product">
    <newsHeader></newsHeader>
    <!-- <My-new-nav ref="header" :isHome='false'></My-new-nav> -->
    <upGrading></upGrading>
  </div>
</template>
<script>
import upGrading from '@/components/Upgrading/index'
import newsHeader from '@/views/newsHeader/index'
export default {
  components:{
    upGrading,
    newsHeader
  }
}
</script>
<style scoped>
.abouts_product{
  height:100%;
}
</style>