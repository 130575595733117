<template>
    <div class="culture-page" @scroll="scroll">
        <My-new-nav ref="header"></My-new-nav>
        <div class="page-width culture-cont">
            <div class="banner">
                <div class="view-width">
                    <div class="banner-title">企业文化</div>
                    <div class="banner-message">让整个产业的合作伙伴可以在医智诺搭建的平台上自由生长</div>
                </div>
            </div>
            <div class="view-bg-black">
                <div class="view-width">
                    <div class="mission">
                        <div class="left">
                            <div class="cont-flex">
                                <div class="cont-flex cont-left"></div>
                                <div class="cont-flex cont-flex-end cont-center">
                                    <div class="box box-1"></div>
                                    <div class="image-box-1">
                                        <img src="../../assets/image/culture/image-culture-4.png" alt="" srcset="">
                                    </div>
                                </div>
                                <div class="cont-right"></div>
                            </div>
                            <div class="cont-flex">
                                <div class="cont-flex cont-flex-row cont-left">
                                    <div class="image-box-2">
                                        <img src="../../assets/image/culture/image-culture-5.png" alt="" srcset="">
                                    </div>
                                    <div class="box box-2"></div>
                                </div>
                                <div class="cont-flex cont-center">
                                    <img class="image-center" src="../../assets/image/culture/image-culture-1.png" alt="" srcset="">
                                </div>
                                <div class="cont-right">
                                    <div class="box"></div>
                                    <div class="image-box-3">
                                        <img src="../../assets/image/culture/image-culture-3.png" alt="" srcset="">
                                    </div>
                                </div>
                            </div>
                            <div class="cont-flex cont-flex-start">
                                <div class="cont-left"></div>
                                <div class="cont-flex cont-flex-content-end cont-center">
                                    <div class="box"></div>
                                </div>
                                <div class="cont-flex cont-right">
                                    <div class="image-box-4">
                                        <img src="../../assets/image/culture/image-culture-2.png" alt="" srcset="">
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="right">
                            <div class="title">企业愿景</div>
                            <div class="message">让每个人拥有属于自己的医疗专「家」<br />让医疗触手可及</div>
                            <div class="line"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="view-width view-pt-80">
                <ecn-title title="核心价值观"></ecn-title>
                <div class="sense-list">
                    <div class="sense-item" v-for="({title, titleEn, icon }, index ) in senseList" :key="index">
                        <img :src="icon" alt="">
                        <div class="title">{{title}}</div>
                        <div class="title-en">{{titleEn}}</div>
                    </div>
                </div>
            </div>
            <div class="view-bg-black">
                <div class="view-width">
                    <div class="cont-flex-item sense-container">
                        <div class="sense-image-1">
                            <img src="../../assets/image/culture/image-1.png" alt="" srcset="">
                        </div>
                        <div class="cont-flex-item cont-flex-row">
                            <div class="sense-image-2">
                                <img src="../../assets/image/culture/image-2.png" alt="" srcset="">
                            </div>
                            <div class="cont-flex-item">
                                <div class="sense-image-3 ">
                                    <img src="../../assets/image/culture/image-3.png" alt="" srcset="">
                                </div>
                                <div class="sense-image-3">
                                    <img src="../../assets/image/culture/image-4.png" alt="" srcset="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <My-new-footer></My-new-footer>
    </div>

</template>
<script>
import scrollHeaderStyle from "../newJoinus/utils";
export default {
    data() {
        return {
            senseList: [
                {
                    title: '激情',
                    titleEn: 'Passion',
                    icon: require('../../assets/image/culture/icon-1.png')
                },
                {
                    title: '接受巨大的挑战',
                    titleEn: 'Take big challenge',
                    icon: require('../../assets/image/culture/icon-2.png')
                },
                {
                    title: '负责可靠',
                    titleEn: 'Accountability',
                    icon: require('../../assets/image/culture/icon-3.png')
                },
                {
                    title: '开放和尊重',
                    titleEn: 'Open&Respectful',
                    icon: require('../../assets/image/culture/icon-4.png')
                },
                {
                    title: '自我严格要求',
                    titleEn: 'Self-critical',
                    icon: require('../../assets/image/culture/icon-5.png')
                },
                {
                    title: '正直和诚实',
                    titleEn: 'Integrity&Honesty',
                    icon: require('../../assets/image/culture/icon-6.png')
                }
            ],
            pageType: "ecologicalSolutionPage",
        }
    },
    activated(){
        let context = this;
        scrollHeaderStyle(0, context, this.pageType )
    },
    methods: {
        scroll(e){
            //滚动条滚动的事件
            let context = this;
            scrollHeaderStyle(e.srcElement.scrollTop ,context, this.pageType )
        },
    }
}
</script>
<style lang="scss" scoped>
.culture-page {
    font-size: 0;
    height: 100vh;
    overflow: auto;
    .culture-cont {
        background-image: url('../../assets/image/aboutUs/bg-culture.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-attachment: fixed;
    }

    .banner {
        height: 520px;
    }
    
    .banner-title {
        font-size: 60px;
        font-family: YouSheBiaoTiHei;
        color: #FFFFFF;
        line-height: 78px;
        letter-spacing: 6px;
        text-shadow: 0px 0px 6px rgba(6, 58, 148, 0.5);
        padding-top: 204px;
    }

    .banner-message {
        font-size: 18px;
        font-family: SourceHanSansCN-Bold;
        color: #FFFFFF;
        line-height: 27px;
        letter-spacing: 9px;
        text-shadow: 0px 0px 6px rgba(6, 58, 148, 0.5);
        margin-top: 19px;
    }
    .cont-flex {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
        justify-content: space-between;
    }
    .cont-flex-item {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .cont-flex-row {
        flex-direction: column;
    }
    .cont-flex-end {
        justify-content: end;
    }
    .cont-flex-start {
        align-items: flex-start;
    }
    .cont-flex-content-end {
        justify-content: flex-end;
    }
    .mission {
        display: flex;
        flex-wrap: nowrap;
        padding: 102px 50px;
        .left {
            flex: 1;
            padding-right: 47px;
            >div {
                margin-top: 18px;
            }
            >div:nth-child(1) {
                margin-top: 0;
            }
            img {
                width: 100%;
                height: 100%;
            }
            .image-center {
                width: 308px;
                height: 308px;
            }
            .mission-center {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: flex-end;
            }

            .cont-left {
                width: 164px;
            }
            .cont-center {
                width: 308px;
            }
            .cont-right {
                width: 200px;
            }
            .box {
                width: 50px;
                height: 50px;
                background: #0D6EFD;
            }
            .box-1 {
                width: 102px;
                height: 102px;
                margin-right: 18px;
            }
            .image-box-1 {
                width: 152px;
                height: 152px;  
            }
            .box-2 {
                width: 60px;
                height: 60px;
                margin-top: 18px;
            }
            .image-box-2 {
                width: 164px;
                height: 164px;  
            }
            .image-box-3 {
                width: 200px;
                height: 200px;
                margin-top: 18px;
            }
            .image-box-4 {
                width: 100px;
                height: 100px;
            }
        }

        .right {
            width: 424px;
            padding-top: 142px;
            .title {
                font-size: 52px;
                font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 52px;
            }

            .message {
                font-size: 22px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 32px;
                letter-spacing: 1px;
                margin-top: 24px;
            }

            .line {
                display: inline-block;
                width: 56px;
                height: 12px;
                background: rgba(13, 110, 253, 0.9);
                margin-top: 20px;
            }
        }
    }

    .sense-list {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 120px 4px;
        padding-bottom: 196px;
        .sense-item {
            width: 192px;
            height: 192px;
            background: rgba(6, 45, 103, 1);
            opacity: 0.7;
            box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.04);
            text-align: center;
            font-family: PingFangSC-Regular, PingFangSC;
            img {
                padding-top: 26px;
                width: 100px;
                height: 80px;
            }
            .title {
                font-size: 20px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 28px;
            }
            .title-en {
                font-size: 16px;
                font-weight: 400;
                color: #ACC7ED;
                line-height: 26px;
            }
        }

    }
    .sense-container {
        padding: 175px 55px;
        padding-bottom: 151px;
        .sense-image-1 {
            width: 570px;
            height: 576px;
        }
        .sense-image-2 {
            width: 570px;
            height: 272px;
        }
        .sense-image-3 {
            width: 270px;
            height: 272px;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }

}
</style>