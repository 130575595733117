<template>
  <div>
        <div :class="isMobile?'mobile-company-culture-main':'company-culture-main'">
            <div class="title">
                <h1>企业文化</h1>
                <p>让整个产业的合作伙伴可以在医智诺搭建的平台上自由生长</p>
            </div>
            <div class="culture-main-ul">
                <div class="culture-main-li left" style=" margin-bottom: 50px;">
                    <img src="../../../assets/image/company_culture1.png"/>
                    <p>
                        <span>我们的使命</span><br>
                        搭建医疗新生态，让医疗价值回归，让医疗更加温暖！
                    </p>
                </div>
                <div class="culture-main-li right">
                    <img src="../../../assets/image/company_culture2.png"/>
                     <p>
                        <span>我们的愿景</span><br>
                        让每个人拥有属于自己的医疗专「家」, 让医疗触手可及。
                    </p>
                </div>
            </div>
        </div>
        <div :class="isMobile? 'mobile-company-core-values-main':'company-core-values-main'">
            <div  class="title">
                <h1>核心价值观</h1>
            </div>
            <ul class="core-values-main">
                <li>
                    <img src="../../../assets/image/company_corevalue1.png" />
                    <p>激情</p>
                    <span>Passion</span>
                </li>
                <li>
                     <img src="../../../assets/image/company_corevalue2.png" />
                    <p>接受巨大的挑战</p>
                    <span>Take big challenge</span>
                </li>
                <li>
                     <img src="../../../assets/image/company_corevalue3.png" />
                    <p>负责可靠</p>
                    <span>Accountability</span>
                </li>
                <li>
                     <img src="../../../assets/image/company_corevalue4.png" />
                    <p>开放和尊重</p>
                    <span>Open&Respectful</span>
                </li>
                <li>
                     <img src="../../../assets/image/company_corevalue5.png" />
                    <p>自我严格要求</p>
                    <span>Self-critical</span>
                </li>
                <li>
                     <img src="../../../assets/image/company_corevalue6.png" />
                    <p>正直和诚实</p>
                    <span>Integrity&Honesty</span>
                </li>
            </ul>
        </div>
        <div :class="isMobile?'mobile-company-concept-main': 'company-concept-main'">
             <div  class="title" style="color:#fff;">
                <h1>企业理念</h1>
                <p>坚持以人为本，为客户创造价值，为员工创造未来</p>
            </div>
            <ul class="concept-main-ul">
                <li>
                  <img src="../../../assets/image/adv-icon1.png">
                  <p>对用户好</p>
                  <span>服务品质需求</span>
                  <span class="mark"></span>
                </li>
                <li>
                  <img src="../../../assets/image/adv-icon2.png">
                  <p>对客户好</p>
                  <span>服务价值提升</span>
                  <span class="mark"></span>
                </li>
                <li>
                  <img src="../../../assets/image/company_concept1.png">
                  <p>线上化</p>
                  <span>数据提高效率与体验</span>
                  <span class="mark"></span>
                </li>
                <li>
                  <img src="../../../assets/image/company_concept2.png">
                  <p>合作共赢</p>
                  <span>1+1>4</span>
                  <span class="mark"></span>
                </li>
            </ul>
        </div>
        <div :class="isMobile?'mobile-CEO-message':'CEO-message'">
            <div class="CEO-message-main">
                <p>天下事，在局外呐喊议论，总是无益，必须躬身入局，挺膺负责，乃有成事之可翼。</p>
                <span>——&nbsp;CEO寄语</span>
            </div>
        </div>
  </div>
</template>

<script>
import { isMobile } from '../../../../common/util'
export default {
    data(){
        return{
            isMobile:isMobile()
        }
    }
}
</script>

<style lang="scss" scoped>

    // company-culture
    .mobile-company-culture-main{
        width: 100%;
        padding-top: 101px;
        padding-bottom: 112px;
        background:#F6FAFF;
        .culture-main-ul{
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            .culture-main-li{
                width: 90%;
                height: 107px;
                border-radius: 10px;
                padding-left: 19px;
                background: #fff;
                box-shadow: 0 0 10px #ccc;
                display: flex;
                align-items: center;
                p{
                    color: #333333;
                    font-size: 24px;
                    margin-left: 6px;
                    line-height: 36px;
                    span{
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .company-culture-main{
        width: 100%;
        padding-top: 101px;
        padding-bottom: 112px;
        background:#F6FAFF;
        .culture-main-ul{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            .culture-main-li{
                width: 561px;
                height: 107px;
                border-radius: 10px;
                padding-left: 19px;
                background: #fff;
                box-shadow: 0 0 10px #ccc;
                display: flex;
                align-items: center;
                p{
                    color: #333333;
                    font-size: 18px;
                    margin-left: 6px;
                    line-height: 36px;
                    span{
                        font-weight: bold;
                    }
                }
            }
        }
        
    }
    // company-core-values-main
    .mobile-company-core-values-main{
        width: 100%;
        padding-bottom: 112px;
        background:#F6FAFF;
        .core-values-main{
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            margin-top: 30px;
            li{
                width: 28%;
                background:#fff;
                box-shadow: 0 0 10px #ccc;
                border-radius: 10px;
                color: #333333;
                margin-bottom: 20px;
                img{
                    display: block;
                    width: 65;
                    height: 65px;
                    margin: 31px auto 25px;
                }
                p{
                    text-align: center;
                    font-size: 24px;
                }
                span{
                    display: block;
                    text-align: center;
                    font-size: 18px;
                    margin-top: 16px;
                    margin-bottom: 27px;
                }
            }
        }
    } 
    .mobile-company-concept-main{
        width: 100%;
        padding-top: 113px;
        padding-bottom: 112px;
        background: url('../../../assets/image/ecn-adv.png') no-repeat center center;
        background-size:100% 100% ;
        .concept-main-ul{
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            margin-top: 74px;
            li{
                width:40%;
                height: 380px;
                border-radius: 20px;
                color: #fff;
                position: relative;
                margin-bottom: 60px;
                img{
                    display: block;
                    margin: 53px auto 36px;
                }
                p{
                    text-align: center;
                    font-size: 26px;
                }
                span{
                    display: block;
                    text-align: center;
                    font-size: 16px;
                    margin-top: 31px;
                    margin-bottom: 52px;
                }
                .mark{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: #fff;
                    opacity: 0.1;
                    border-radius: 20px;
                    margin: 0;
                }
            }
        }
    }
    //company-concept-main
    .company-concept-main{
        width: 100%;
        padding-top: 113px;
        padding-bottom: 112px;
        background: url('../../../assets/image/ecn-adv.png') no-repeat center center;
        background-size:100% 100% ;
        .concept-main-ul{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            margin-top: 74px;
            li{
                width: 265px;
                height: 287px;
                border-radius: 20px;
                color: #fff;
                position: relative;
                img{
                    display: block;
                    margin: 53px auto 36px;
                }
                p{
                    text-align: center;
                    font-size: 26px;
                }
                span{
                    display: block;
                    text-align: center;
                    font-size: 16px;
                    margin-top: 31px;
                    margin-bottom: 52px;
                }
                .mark{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: #fff;
                    opacity: 0.1;
                    border-radius: 20px;
                    margin: 0;
                }
            }
        }
    }
    .company-core-values-main{
        width: 100%;
        padding-bottom: 112px;
        background:#F6FAFF;
        .core-values-main{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            margin-top: 30px;
            li{
                width: 180px;
                background:#fff;
                box-shadow: 0 0 10px #ccc;
                border-radius: 10px;
                color: #333333;
                img{
                    display: block;
                    width: 65;
                    height: 65px;
                    margin: 31px auto 25px;
                }
                p{
                    text-align: center;
                    font-size: 18px;
                }
                span{
                    display: block;
                    text-align: center;
                    font-size: 16px;
                    margin-top: 16px;
                    margin-bottom: 27px;
                }
            }
        }
    } 
    //company-concept-main
    .company-concept-main{
        width: 100%;
        padding-top: 113px;
        padding-bottom: 112px;
        background: url('../../../assets/image/ecn-adv.png') no-repeat center center;
        background-size:100% 100% ;
        .concept-main-ul{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            margin-top: 74px;
            li{
                width: 265px;
                height: 287px;
                border-radius: 20px;
                color: #fff;
                position: relative;
                img{
                    display: block;
                    margin: 53px auto 36px;
                }
                p{
                    text-align: center;
                    font-size: 26px;
                }
                span{
                    display: block;
                    text-align: center;
                    font-size: 16px;
                    margin-top: 31px;
                    margin-bottom: 52px;
                }
                .mark{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: #fff;
                    opacity: 0.1;
                    border-radius: 20px;
                    margin: 0;
                }
            }
        }
    } 
    .mobile-CEO-message{
        width: 100%;
        padding: 112px 0;
        .CEO-message-main{
            width: 100%;
            margin: 0 auto;
            color: #333333;
            p{
                // text-align: center;
                padding: 20px;
                font-size: 26px;
            }
            span{
                display: flex;
                flex-direction: row-reverse;
                margin-top: 42px;
                padding-right: 20px;
                font-size: 28px;
                font-weight: bold;
            }
        }
    }
    // CEO-message
    .CEO-message{
        width: 100%;
        padding: 112px 0;
        .CEO-message-main{
            width: 1200px;
            margin: 0 auto;
            color: #333333;
            p{
                text-align: center;
                font-size: 26px;
            }
            span{
                display: flex;
                flex-direction: row-reverse;
                margin-top: 42px;
                font-size: 30px;
                font-weight: bold;
            }
        }
    }  
   
    .title{
        color: #333333;
        text-align: center;
        h1{
            font-size: 45px;
            margin-bottom: 23px;
        }
        p{
            font-size: 24px;
            margin-bottom: 85px;
        }
    }
</style>