<template>
    <div>
        <div class="ecn-box" v-if="!isMobile && newsList.length > 0" >
        <div class="ecn-news-box">
            <div v-for="(item, index) in newsList" :key="index">
            <div class="ecn-news">
                <div class="left">
                <img :src="item.imgUrl" />
                </div>
                <div class="right">
                <p class="news-title">{{item.title}}</p>
                <span>更新时间：{{$moment(item.createTime).format('YYYY-MM-DD')}}</span>
                <p class="news-content">{{item.content.replace(/<[^<>]+>/g, "")}}</p>
                <button @click="$router.push({path:'/newsdetail/'+item.id})">查看详情</button>
                </div>
            </div>
            </div>
            <div class="ecn-more" v-if="hasMore" @click="getNews()" style="cursor: pointer;">
            更多...
            </div>
        </div>
        </div>
        <div class="mobile-news" v-else-if="newsList.length > 0">
            <div v-for="(item, index) in newsList" :key="index" class="mobile-news-li">
                <div class="mobile-ecn-news">
                    <div class="mobile-news-left">
                        <img :src="item.imgUrl" />
                    </div>
                    <div class="mobile-news-right">
                        <p class="news-title">{{item.title}}</p>
                        <span style="color:#999;font-size:20px;position: relative;margin-top: 20px;margin-bottom: -30px;">更新时间：{{$moment(item.createTime).format('YYYY-MM-DD')}}</span>
                        <button @click="$router.push({path:'/newsdetail/'+item.id})">查看详情</button>
                    </div>
                </div>
            </div>
            <div class="ecn-more" v-if="hasMore" @click="getNews()">
            更多...
            </div>
        </div>
        <div class="mobile-news" v-else>
            <div class="mobile-news-li" style="text-align: center;margin: 100px;">
                <h2>暂无更多内容...</h2>
            </div>
        </div>
    </div>
    
</template>

<script>
import "../../../assets/styles/index.scss"
import { isMobile } from '../../../../common/util'
import { selectNewList } from '../../../../common/api'
export default {
    data() {
        return {
            pageNum: 1,
            pageSize: 3,
            count: 0,
            newsList: [],
            isMobile:isMobile(),
            hasMore: true
        }
    },
    mounted() {
        this.getNews()
    },
    methods: {        
        getNews() {
            let data = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                isOfficial: 1
            }
            selectNewList(data).then(res => {
                if (res.responseCode == 0) {
                    let resData = res.responseBody
                    if (this.newsList.length < res.count) {
                        this.newsList = this.newsList.concat(resData)
                        if (this.newsList.length < res.count)
                        {
                            this.pageNum++;
                            this.hasMore = true
                        } else {
                            this.hasMore = false
                        }
                    } else {
                        this.hasMore = false
                    }
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>    
    .mobile-news{
        margin-top: 40px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .mobile-news-li{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 320px;
            padding: 40px 0;
            border-bottom: 1px solid #D6F1FF;
            .mobile-ecn-news{
                margin: 0 40px;
                width: 100%;
                height: 100%;
                max-height: 320px;
                display: flex;
                justify-content: space-between;
            }
            .mobile-news-left{
                width: 45%;
                max-height: 100%;
                img{
                    width: 100%;
                    max-height: 320px;
                }
            }
            .mobile-news-right{
                display: flex;
                flex-direction: column;
                width: 50%;
                margin-right: 20px;
                max-height: 100%;
                margin-top: -5px;
                .news-title{
                    font-size: 28px;
                    color: #333;
                }
                button{
                    color: #343478;
                    position: relative;
                    margin:auto 0 auto 0;
                    width: 95px;
                    height: 39px;
                    font-size: 18px;
                    border: 1px solid #343478;
                    text-align: center;
                    line-height: 39px;
                    border-radius: 5px;
                    background: #F6FAFF;
                }
                
            }
            
        }
        .ecn-more{
            width: 100%;
            text-align: center;
            color: #37367E;
            font-size: 40px;
            font-weight: bold;
            padding: 65px;
        }        
    }
</style>