<template>
    <div>
        <div v-if="isMobile" class="mobile-header-box" id="nav">
            <div  class="mobile-header-box-log"><img :src="navShow?logo1:logo"></div>
            <div class="mobile-header-box-menu"><img :src="navShow ? menubtn2 : menubtn1"  @click="showMobilMenu = true"/></div>
        </div>
        <div v-else class="header-box" id="nav" @mouseenter.stop="changeColor(1)" @mouseleave="changeColor(2)" >
        <div style="display:flex;align-items:center;position:relative;" class="menu">
            <div :class="isMobile ? 'logo mobile':'logo'"><img :src="navShow?logo1:logo"></div>
            <el-menu  
            class="el-menu-demo hidden-md-and-down"
            style="background-color:transparent;border-bottom:none;"
            :text-color="textColor"
            :active-text-color="activeColor"
            :default-active="activeMenu"
            router
            @open="change"
            @select="selectMenu"
            mode="horizontal"
            v-if="!isMobile">
            <el-menu-item index="/" style="background-color:transparent;">首页</el-menu-item>
            <el-submenu index="/">
                <template slot="title" >产品服务</template>
                <el-menu-item style="color:#000" index="/product/1">医智诺平台生态圈</el-menu-item>
                <el-menu-item style="color:#000" index="/product/2">工业</el-menu-item>
                <el-menu-item style="color:#000" index="/product/3">药房</el-menu-item>
                <el-menu-item style="color:#000" index="/product/4">医生</el-menu-item>
                <el-menu-item style="color:#000" index="/product/5">医学运营专员</el-menu-item>
                <el-menu-item style="color:#000" index="/product/6">患者</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
                <template slot="title">App下载</template>
                <el-menu-item  style="color:#000" index="/app/1">医智诺就医助手</el-menu-item>
                <el-menu-item  style="color:#000" index="/app/2">医智诺健康管家</el-menu-item>
                <el-menu-item  style="color:#000" index="/app/3">领医未来</el-menu-item>
            </el-submenu>
            <el-submenu index="4">
                <template slot="title">合作入驻</template>
                <el-menu-item style="color:#000" index="/coopSettlement/1">药房入驻</el-menu-item>
                <el-menu-item style="color:#000" index="/coopSettlement/2">医生入驻</el-menu-item>
            </el-submenu>
            <el-submenu index="5">
                <template slot="title">关于我们</template>
                <el-menu-item style="color:#000" index="/aboutUs/1">企业简介</el-menu-item>
                <el-menu-item style="color:#000" index="/aboutUs/2">企业文化</el-menu-item>
                <el-menu-item style="color:#000" index="/aboutUs/3">新闻动态</el-menu-item>
                <el-menu-item style="color:#000" index="/aboutUs/4">人才招聘</el-menu-item>
                <el-menu-item style="color:#000" index="/aboutUs/5">联系我们</el-menu-item>
            </el-submenu>
            </el-menu>        
            <!-- <div  v-if="!showMobilMenu || isMobile"><img :src="navShow ? menubtn2 : menubtn1" style="width:28px;height: 16px;position: fixed;right: 32px;color: #fff;top: 25px;" @click="showMobilMenu = true"/></div> -->
        </div>
        </div>
        <My-mobilMenu v-if="showMobilMenu" @closemenu="closeMenu()"></My-mobilMenu>
        <!-- <template>
        <el-carousel trigger="click"  class="banner-box" loop :height="bannerHeight + 'px'">
            <el-carousel-item v-for="item in homeSlide" :key="item.imgUrl">
            <img ref="bannerHeight" :src="item.imgUrl" class="image" @load="imgLoad">
            </el-carousel-item>
        </el-carousel>
        </template> -->
    </div>
</template>

<script>
import "../../assets/styles/index.scss"
import "../../assets/styles/public.scss"
import "../../assets/styles/mobile.scss"
import 'element-ui/lib/theme-chalk/display.css';
import logo from "../../assets/image/Whiteninglogo.svg"
import logo1 from "../../assets/image/colorlogo.svg"
import menubtn1 from "../../assets/image/menu-btn.png"
import menubtn2 from "../../assets/image/menu-btn-b.png"
import { isMobile } from '../../../common/util'

export default {
    name: 'myheader',
    data() {
        return {
            isMobile: isMobile(),
            showMobilMenu: false,
            logo,
            logo1,
            menubtn1,
            menubtn2,
            bannerHeight: '',
            navShow: false,
            isTransparent: false,
            textColor: '#fff',
            activeColor: "#fff",
            homeSlide: [
            {imgUrl:require('../../assets/image/banner.png')},
            {imgUrl:require('../../assets/image/banner2.png')},
            {imgUrl:require('../../assets/image/banner3.png')},
            {imgUrl:require('../../assets/image/banner4.png')}],
        }
    },
    computed:{
      activeMenu() {          
          return this.$store.state.activeMenu
      },
    },
    methods: {
        checkTransparent() {
            if (this.$route.path.indexOf('newsdetail') > 0) {
                return false
            } else {
                return true
            }
        },
        scollList(e){            
            if (e.srcElement.scrollTop > 200) {
                document.getElementById('nav').style.backgroundColor = '#ffffff'
                this.navShow = true
                this.textColor = '#000'
                this.activeColor = '#343478'
                this.isTransparent = false
            } else {
                if (this.checkTransparent()) {
                    document.getElementById('nav').style.backgroundColor = 'transparent'
                    this.navShow = false
                    this.textColor = '#fff'
                    this.activeColor = '#fff'
                    this.isTransparent = true
                }                
            }
        },
        changeColor(m){
            if (isMobile) {
                return;
            }
            if(this.isTransparent){
                if (m == 1) {
                    document.getElementById('nav').style.backgroundColor = '#ffffff'
                    this.navShow = true
                    this.textColor = '#000'
                    this.activeColor = "#343478"
                    
                } else if(m == 2) {
                    document.getElementById('nav').style.backgroundColor = 'transparent'
                    this.navShow = false
                    this.textColor = '#fff'
                    this.activeColor = "#fff"
                }
                
            }
        },
        change(index){
            console.log(index)
        },
        closeMenu() {
            this.showMobilMenu = false
        },
        selectMenu(index,path){
            console.log(index,path)
            this.$store.commit('changeActiveMenu',index)
        }
    }
}
</script>

<style scoped>

</style>