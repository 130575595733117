<template>
  <div class="ecn-title">
    <div class="title">{{title}}</div>
    <div v-if="message" class="message">{{message}}</div>
    <div class="line"></div>
  </div>
</template>

<script>


export default {
  name: "EcnTitle",
  props:{
    title: {
      require,
      default: '',
      type: String
    },
    message: {
      default: '',
      type: String
    }
  },

};
</script>
<style lang="scss" scoped>
  .ecn-title {
    .title {
      font-size: 28px;
      font-family: SourceHanSansCN-Bold;
      color: #FFFFFF;
      line-height: 28px;
      letter-spacing: 3px;
    }
    .message {
      font-size: 14px;

      color: #FFFFFF;
      line-height: 14px;
      letter-spacing: 4px;
      margin-top: 12px;
    }
    .line {
      display: inline-block;
      width: 56px;
      height: 12px;
      background: rgba(13, 110, 253, 0.9);
      margin-top: 12px;
    }
  }

</style>