<template>
  <div>
      <!-- main部分 -->
      <!-- <My-nav></My-nav> -->
        <!-- main部分 -->
    <div :class="isMobile ? 'main-newsdate mobile':'main-newsdate'">
        <p class="news"><a @click="routerJump('/aboutUs/3')" >新闻动态</a> - 新闻详情</p>
        <h1 style="margin-bottom: 30px;text-align:center;font-size:34px;">{{news.title}}</h1>
        <hr>
        <p style="color: #999;font-size: 16px;margin:20px auto;text-align:center;">更新时间：{{$moment(news.createTime).format('YYYY-MM-DD HH:mm')}}</p>
        <div><img src="../../assets/image/news1/ecn.gif" alt="新闻详情图"></div>
        <br>
        <div><img :src="news.imgUrl" alt="新闻详情图"></div>
        <br>
        <div v-html="news.content" class="content"></div>       
    </div>
  </div>

</template>

<script>
import { selectNewsById } from '../../../common/api'
import { isMobile } from '../../../common/util'

export default {
    data() {
      return {
        id: 0,
        news: {},
        isMobile: isMobile()
      }
    },
    mounted() {
      this.id = this.$route.params.id
      this.getNewsDetail()
    },
    methods:{
      routerJump(m){
        this.$router.push({path:m})
        this.$store.commit('changeActiveMenu',m)
        window.scrollTo(0,0)
      },
      getNewsDetail() {
        let data = {
          id : this.id
        }
        selectNewsById(data).then(res => {
          if (res.responseCode == 0) {
            this.news = res.responseBody            
          }
        })
      }
    },
  
}
</script>

<style lang="scss">
    /*动态详情页*/
.main-newsdate{
  max-width: 1200px;
  margin: 60px auto;
  padding: 0 20px;
  font-size: 20px;
  line-height: 36px;    
}
.main-newsdate.mobile {
  font-size: 28px;
  padding: 2rem;
  line-height: 40px;    
}
.news a{
  cursor: pointer;
}
.news a:hover{
    color: #999;
}
.main-newsdate .news{text-align: left; line-height: 104px;}
.main-newsdate h1{text-align: left;font-size: 32px;line-height: 50px;}
.main-newsdate h2{text-align: left;font-size: 32px;line-height: 50px;}
.main-newsdate .pushtime{color: #686868; font-size: 18px; line-height: 82px;}
.main-newsdate img{max-width: 100%;width: 100%;}
.main-newsdate .ql-align-center {
  text-align: center !important;
  font-size: 20px;
  img {
    margin: 0 auto;
  }
}
</style>