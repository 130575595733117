
let envType=0//0生产 1废弃 2开发 3测试 4阿里云测试
let baseUrl=''
if(envType==0) {
 baseUrl = "/api"
} else if(envType==1){ // 生产顺序不要调整
 baseUrl = "https://guard.echartnow.com/" // 生产
}else if(envType==2){
 baseUrl = "http://192.168.1.112:9090" // 开发	
}else if(envType==3){
 baseUrl = "http://192.168.1.199:9090" // "https://ltestguard.echartnow.com/"// 测试199
}else if(envType==4){
 baseUrl = "http://47.104.71.25:30"  //阿里云测试
}else if(envType==5){//port : 冯聪
 baseUrl = "http://47.104.71.25:909" //47测试环境
}

const COMPANY_INFO = {
  address: '江苏省苏州市张家港市塘桥镇南京路525号',
  location: { lng: 120.64280, lat: 31.82350 }, // { lng: 108.890299, lat: 34.196709 }
  icp: '苏ICP备2023036865号-1'
}
exports.default = {  
    baseUrl,
    envType,
    COMPANY_INFO
}
