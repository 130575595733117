<template>
  <div class="developing">
    <My-new-nav ref="header"></My-new-nav>
    <div class="developing-info">
      <div class="info-banner">
        <img src="../../assets/image/Mobile/developing-banner.png" alt="">
      </div>
      <div class="info-tips">医智诺官网正在优化升级中</div>
      <div class="info-tips">敬请期待…</div>
      <div class="info-qrcode">
        <img src="../../assets/image/code.png" alt="">
      </div>
      <div class="info-other">您也可以扫描上方二维码</div>
      <div class="info-other">关注医智诺微信官方公众号</div>
      <div class="info-other">获取更多新鲜资讯</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.developing-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 192px 0 230px;
  .info-banner{
    width: calc(100% - 210px);
    margin-bottom: 69px;
    img{
      width: 100%;
    }
  }
  .info-tips{
    font-size: 48px;
    line-height: 64px;
    color: #888888;
  }
  .info-qrcode{
    width: 270px;
    height: 270px;
    margin: 282px 0 36px;
    img{
      width: 100%;
    }
  }
  .info-other{
    font-size: 39px;
    line-height: 54px;
    color: #212121;
  }
}
</style>