<template>
   <div class="banner" :height="bannerHeight + 'px'">
        <img ref="bannerHeight" :src="bannerImg" v-show="bannerImg"  @load="imgLoad">
    </div>
</template>

<script>
// import "../../assets/styles/public.scss"
export default {
    props:{
        bannerImg: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            bannerHeight: "",
        }
    },
    mounted(){
        this.imgLoad()
        window.addEventListener('resize',()=>{
        this.bannerHeight = this.$refs.bannerHeight.height
        this.imgLoad()
      })
    },
    methods:{
        imgLoad(){
        this.$nextTick(() => {
          //if (this.$refs.bannerHeight[0]) this.bannerHeight = this.$refs.bannerHeight[0].height
        })
      },
    }
}
</script>

<style lang="scss" scoped>
.banner{
    max-width: 1920px;
    margin-top: 60px;
    margin: 0 auto;
    // overflow: hidden;
    img{
        width: 100%;
    }
}
</style>
