<template>
  <div class="mobile-footer" :style="{'--backgroundColor': backgroundColor, '--fontColor': fontColor, '--borderColor': borderColor}" @touchmove.stop @touch.stop>
      <div class="op-bar">
        <div class="logo"><img :src="menuTheme === 'dark' ? logo : logo1"></div>
        <div class="close">
          <i class="el-icon-close" @click="closeMenu()"></i>
        </div>
      </div>
       <div class="footer-link">
         <el-collapse  v-model="activeNames"  @change="toHome">
          <el-collapse-item title="首页" name="/" ></el-collapse-item>
          <el-collapse-item title="产品" name="1">
            <div class="title top" @click="routerJump('/developing_mobile')">苁蓉-数字化运营平台</div>
            <div class="title" @click="routerJump('/developing_mobile')">领医未来</div>
            <div class="title" @click="routerJump('/developing_mobile')">医智诺就医助手</div>
            <div class="title bottom" @click="routerJump('/developing_mobile')">专业化药房</div>
          </el-collapse-item>
          <el-collapse-item title="解决方案"  name="2">
            <div class="title top" @click="routerJump('/developing_mobile')">数字化运营解决方案</div>
            <div class="title bottom" @click="routerJump('/developing_mobile')">数字化项目管理解决方案</div>
            <div class="title bottom" @click="routerJump('/developing_mobile')">数字化运营生态解决方案</div>
          </el-collapse-item>
          <el-collapse-item title="新闻中心" name="3">
            <div class="title bottom" @click="routerJump('/developing_mobile')">医智诺新闻动态</div>
          </el-collapse-item>
          <el-collapse-item title="关于我们" name="4">
            <div class="title top" @click="routerJump('/aboutUs_mobile')">集团介绍</div>
            <div class="title" @click="routerJump('/culture_mobile')">企业文化</div>
            <div class="title bottom" @click="routerJump('/contact_mobile')">联系我们</div>
          </el-collapse-item>
          <el-collapse-item title="加入我们" name="5" class='icon-no'>
<!--            <div class="title top" @click="routerJump('/developing_mobile')">校园招聘</div>-->
<!--            <div class="title bottom" @click="routerJump('/developing_mobile')">社会招聘</div>-->
          </el-collapse-item>
        </el-collapse>
       </div>
    </div>
</template>

<script>
import logo from "../../assets/image/Whiteninglogo.svg"
import logo1 from "../../assets/image/colorlogo.svg"
export default {
  props: {
    menuTheme: {
      type: String,
      default: 'light'
    }
  },
  data(){
    return{
      logo,
      logo1,
      activeNames:[],
      backgroundColor: this.menuTheme === 'dark' ? '#000' : '#fff',
      fontColor: this.menuTheme === 'dark' ? '#ccc' : '#333',
      borderColor: this.menuTheme === 'dark' ? '#333' : '#f3f3f3'
    }
  },
  methods:{
    routerJump(m){
      let header = document.getElementById('app')
      header.scrollTop = 0
      this.$store.commit('changeActiveMenu',m)
      this.$emit('closemenu',{})
      window.scrollTo(0,0)
      this.$router.push({path:m})
    },
    closeMenu() {
      this.$emit('closemenu',{})
    },
    toHome($event){
      if ($event[$event.length - 1] == '/'){
        this.routerJump('/')
      }
    }
  },
}
</script>

<style lang="scss" scoped>
$fontsize: 39px;
$itemHeight: 120px;
$backgroundColor: var(--backgroundColor);
$fontColor: var(--fontColor);
$borderColor:var(--borderColor);

.op-bar {
  height: $itemHeight;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img{
    width: 180px;
  }
  .close{
    display:flex;
    align-items:center;
    justify-content:center;
    position: absolute;
    right: 0;
    i{
      margin-right:60px;
      font-size: 50px;
      color: $fontColor;
    }
    // img{
    //   width:34px;
    //   margin-right:40px;
    // }

  }
}
.logo {
  margin: 22px 32px;
}
.mobile-footer {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10000;
  height: calc(100vh);
  background-color: $backgroundColor;
}

.mobile-footer-left-address{
  color: $fontColor;

}

.footer-link{
  position: fixed;
  height: 100%;
  width: 100%;
  padding: 0 0;
  background-color: $backgroundColor;
  color: $fontColor;
  font-size: $fontsize;
  overflow-y: auto;
}

.el-collapse-item {
  padding: 0 0;
}

.el-collapse-item:first-child {
  border-top: 1px #333 solid;
  border-color: $borderColor;
}

::v-deep .el-collapse-item__header{
  background-color: $backgroundColor;
  color: $fontColor;
  font-size: $fontsize;
  font-weight: 600;
  height: $itemHeight;
  margin: 0 61px;
  border-bottom: 1px #333 solid;
  border-color: $borderColor;
}
::v-deep .el-collapse-item__header.is-active {
  background-color: $backgroundColor;
  color: $fontColor;

}
::v-deep .el-collapse-item__wrap {
  background-color: $backgroundColor;
  color: $fontColor;
  padding: 0 61px;
}
::v-deep .el-collapse-item__wrap:last-child {
  border-bottom: 0;
}

.title{
  background: $backgroundColor;
  color: $fontColor;
  font-size: $fontsize;
  height: $itemHeight;
  line-height: $itemHeight;
  margin-left: 45px;
}

::v-deep .el-collapse-item__content {
  padding-bottom: 0;
  div {
    border-bottom: 1px #333 solid;
    border-color: $borderColor;
    padding-bottom: 0;
  }
  div:last-child {
    border-bottom: 0;
  }
}

// ::v-deep .el-collapse-item__header:last-child {
//   border-bottom: 0;
// }


.el-collapse {
  // padding-top: 100px;
  width: 100%;
  border-bottom: 0;
  margin-bottom: 150px;
  border-top: 1px solid #333;
  transition: transform .8s ease-out, top .2s ease-out;
}

::v-deep .el-collapse-item:first-child {
  i {
    display: none;
  }
}
::v-deep .icon-no .el-icon-arrow-right {
  display: none;
}
</style>
