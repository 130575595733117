<template>
  <div>
    <div
      class="header-box no-transparent-nav"
      id="nav"
      style="background-color:#fff"
    >
      <div class="nav-center">
        <div class="logo">
        <img src="../../assets/image/colorlogo.svg">
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          position: relative;
        "
        class="menu"
      >
        <el-menu
          class="el-menu-demo hidden-md-and-down new-menu"
          style="border-bottom:none"
          :text-color="textColor"
          :active-text-color="activeColor"
          :default-active="activeMenu"
          router
          ref="newMenu"
          @select="selectMenu"
          mode="horizontal"
        >
          <el-menu-item index="/" style="border:0!important"
            >首页</el-menu-item
          >
          <!-- <el-menu-item index="/product" style="border:0!important"
            >产品</el-menu-item
          > -->
          <el-submenu index="/product" popper-class="ecn-submenu-popover" >
            <template slot="title">产品</template>
            <el-menu-item  index="/product">苁蓉-数字化运营平台</el-menu-item>
            <el-menu-item  index="/projectApp">领医未来</el-menu-item>
            <el-menu-item  index="/productPatient">医智诺就医助手</el-menu-item>
            <el-menu-item  index="/productPharmacy">专业化药房</el-menu-item>
          </el-submenu>
          <el-submenu index="/ecologicalSolution" popper-class="ecn-submenu-popover" >
            <template slot="title">解决方案</template>
            <el-menu-item  index="/operationSolution">数字化运营解决方案</el-menu-item>
            <el-menu-item  index="/projectSolution">数字化项目管理解决方案</el-menu-item>
            <el-menu-item  index="/ecologicalSolution">数字化运营生态解决方案</el-menu-item>
          </el-submenu>
          <!-- <el-menu-item index="/newsCenter" style="border:0!important"
            >新闻中心</el-menu-item
          > -->
          <el-submenu index="/aboutUs" popper-class="ecn-submenu-popover">
            <template slot="title">关于我们</template>
            <el-menu-item  index="/aboutUs">集团介绍</el-menu-item>
            <el-menu-item  index="/culture">企业文化</el-menu-item>
            <el-menu-item  index="/contact">联系我们</el-menu-item>
          </el-submenu>
          <el-menu-item index="/joinUs" style="border:0!important"
            >加入我们</el-menu-item
          >
        </el-menu>
      </div>
      </div>
      
    </div>
  </div>
</template>

<script>
// import "../../assets/styles/index.scss";
// import "../../assets/styles/public.scss";
// import "../../assets/styles/mobile.scss";
// import "element-ui/lib/theme-chalk/display.css";
export default {
  name: "myheader",
  data() {
    return {
      showMobilMenu: false,
      bannerHeight: "",
      navShow: false,
      isTransparent: false,
      textColor: "#91969c",
      activeColor: "#333333",
      homeSlide: [
        { imgUrl: require("../../assets/image/banner.png") },
        { imgUrl: require("../../assets/image/banner2.png") },
        { imgUrl: require("../../assets/image/banner3.png") },
        { imgUrl: require("../../assets/image/banner4.png") },
      ],
    };
  },
  computed: {
    activeMenu() {
      return this.$store.state.activeMenu;
    },
  },
  methods: {
    checkTransparent() {
      if (this.$route.path.indexOf("newsdetail") > 0) {
        return false;
      } else {
        return true;
      }
    },
    changeColor(m) {
      // console.log(m,'m====')
      // if (isMobile) {
      //   return;
      // }
      // if (m == 1) {
      //   document.getElementById("nav").style.backgroundColor = "#ffffff";
      //   this.textColor = "#333333";
      //   this.activeColor = "#333333";
      // } else if (m == 2) {
      //   document.getElementById("nav").style.backgroundColor = "#ffffff";
      //   this.textColor = "#333333";
      //   this.activeColor = "#333333";
      // }
    },
    // change(index) {
    //   console.log(index);
    // },
    closeMenu() {
      this.showMobilMenu = false;
    },
    selectMenu(index, path) {
      console.log(index, path);
      this.$store.commit("changeActiveMenu", index);
    },
  },
};
</script>

<style lang="scss" scoped>
  .header-box {
    ::v-deep .el-submenu__title {
      border: 0px !important;
    }

  }
  .ecn-submenu-popover {
    .el-menu-item:hover {
     outline: 0;
     color: #333 !important;
     background-color: #e9e9e9 !important;
   }
  }
</style>