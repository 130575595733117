<template style="height:100%">
  <div class="main_content">
    <div class="image_content">
      <img src="../../assets/image/Upgrading.png">
    </div>
    <div class="footer">
      <p>备案号：<a class="footer-icp" href="https://beian.miit.gov.cn" target="_blank">{{ $COMPANY_INFO.icp }}</a></p>
      <!-- 备案号：<a class="footer-icp" href="https://beian.miit.gov.cn" target="_blank">陕ICP备18017834号-1</a> | (陕)网械平台备字〔2019〕第00002号 互联网药品信息服务资格证书编号：（陕)-经营性-2018-0003   -->
    </div>
  </div>
</template>
<script>
// import newsHeader from '@/views/newsHeader/index'
export default {
  components:{
    // newsHeader
  }
}
</script>
<style lang="scss">
.main_content{
  width:100%;
  height:100%;
  position:relative;
  flex-direction:column;
  display:flex;
  justify-content:center;
  align-items:center;
  background:#FFFFFF;
  .image_content{
    width:1112px;
    margin:0 auto;
    display:flex;
    justify-content:center;
    align-items:center;
    img{
      width:100%;
    }
  }
  .footer{
    position:absolute;
    width:100%;
    bottom:0;
    height:72px;
    line-height:72px;
    box-sizing: border-box;
    text-align:center;
    border-top:1px solid #EAEAEA;
    color: #807E7E;
    .footer-icp{
      color: inherit;
      &:hover{
        color: rgba(13, 110, 253, 0.9);
      }
    }
  }
}
</style>