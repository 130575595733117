<template>
  <div>
    <My-new-nav ref="header" menuTheme="dark"></My-new-nav>
    <div class="topBanner">
      <div class="pageTitle">
        <div class="title">医疗行业革命者</div>
        <div class="subTitle">让医疗价值回归，让医疗更加温暖</div>
      </div>
    </div>
    <div class="card">
      <div class="cardTop">
        <div class="cardTitle">医智诺集团</div>
        <div class="cardDesc">搭建医疗新生态</div>
      </div>
      <p>医智诺是香港上市公司华谊腾讯（HK00419）旗下的医疗集团，控股子公司。华谊腾讯股东包括腾讯以及云锋基金等知名企业。</p>
      <p>医智诺战略定位为医疗产业数字化运营并跑者，目前拥有实体医院、互联网诊疗资质及医药／耗材全功能牌照、医学研究和试验发展经营资质、三级等保、CA认证等国家颁发的合法资质。自主研发了医学运营管理平台、专业化药房管理平台、学科建设运营平台、数字化营销平台、患者健康服务平台，保证平台的信息安全及可靠性。医智诺凭借创新的技术能力和产品研发实力，致力于布局全产业医疗服务生态圈，搭建全场景化医疗服务平台，为健康中国助力。</p>
      <p>医智诺产品研发中心设立在陕西西安，目前已在全国建立9个（5个大区，4个合作伙伴）运营中心，业务覆盖27个省市自治区。公司将利用雄厚的资金、技术、资源实力，顺应国家医疗政策，打造医生+医药+检验+科研+保险的新医疗服务、新健康服务的价值链。</p>
      <div class="video">
        <video ref="video" class="video-cont" :controls="videoControls" src="https://video.echartnow.com/sv/2633573c-183545037f3/2633573c-183545037f3.mp4">
          <p class="message">您的浏览器不支持播放视频</p>
        </video>
        <div class="video-play" v-show="!isPlay">
          <img src="../../assets/image/icon-play.png" alt="" @click="operateVideo">
        </div>
      </div>
    </div>
    <div class="card img_bg">
      <div class="cardTop">
        <div class="cardTitle">医智诺集团</div>
        <div class="cardDesc">搭建医疗新生态</div>
      </div>
      <div class="fourSub">
        <div class="subCard">
          <div class="listPic num1"></div>
          <div class="listTitle">上市公司</div>
          <div class="listDesc">香港上市公司</div>
          <div class="listDesc">华谊腾讯(HK00419)</div>
          <div class="listDesc">控股子公司</div>
        </div>
        <div class="subCard">
          <div class="listPic num2"></div>
          <div class="listTitle">合法合规</div>
          <div class="listDesc">互联网诊疗资质</div>
          <div class="listDesc">医药／耗材全功能牌照</div>
          <div class="listDesc">CA认证在内的12+合法资质</div>
        </div>
        <div class="subCard">
          <div class="listPic num3"></div>
          <div class="listTitle">全国布局</div>
          <div class="listDesc">已在全国建立9个运营中心</div>
          <div class="listDesc">（5个大区，4个合作伙伴）</div>
          <div class="listDesc">业务覆盖27个省市自治区</div>
        </div>
        <div class="subCard">
          <div class="listPic num4"></div>
          <div class="listTitle">技术创新</div>
          <div class="listDesc">18+软件著作权</div>
          <div class="listDesc">布局全产业医疗服务生态圈</div>
          <div class="listDesc">搭建全场景化医疗服务平台</div>
        </div>
      </div>
    </div>
    <Information />
  </div>
</template>

<script>
import Information from "@/views/mobile/Information.vue";

export default {
  components: {Information},
  data() {
    return {
      videoControls: false,
      isPlay: false,
    }
  },
  methods: {
    operateVideo() {
      this.$refs.video.play()
      this.videoControls = true;
      this.isPlay = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.topBanner{
  padding-bottom: 53.33%;
  position: relative;
  background-image: url("../../assets/image/Mobile/groupIntroduce/top_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  .pageTitle{
    padding-left: 36px;
    position: absolute;
    top: 40%;
    .title{
      font-size: 72px;
      font-family: YouSheBiaoTiHei;
      color: #FFFFFF;
      line-height: 93px;
      letter-spacing: 15px;
      text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
    }
    .subTitle{
      font-size: 30px;
      line-height: 45px;
      font-weight: bold;
      color: #FFFFFF;
      letter-spacing: 12px;
      text-shadow: 0px 0px 6px rgba(6,58,148,0.5);
    }
  }
}
.card{
  padding: 80px 36px;
  background-color: black;
  color: #FFFFFF;
  .cardTop{
    position: relative;
    margin-bottom: 60px;
    &::after{
      content: '';
      display: block;
      position: absolute;
      width: 84px;
      height: 18px;
      background: #0D6EFD;
      opacity: 0.9;
    }
  }
  .cardTitle{
    font-size: 54px;
    font-weight: bold;
    line-height: 69px;
    letter-spacing: 6px;
  }
  .cardDesc{
    font-size: 30px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 8px;
  }
  & > p{
    font-size: 42px;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 4px;
    margin-bottom: 60px;
  }
  .video {
    width: 100%;
    position: relative;
    .video-cont{
      width: 100%;
      height: 100%;
    }
    .video-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      img {
        width: 210px;
        height: 210px;
        cursor: pointer;
      }
    }
  }
  &.img_bg{
    background-image: url("../../assets/image/Mobile/groupIntroduce/mid_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .fourSub{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .subCard{
      width: 41%;
      padding-bottom: 50px;
      background: #062D67;
      box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.04);
      opacity: 0.7;
      margin: 30px 38px;
      text-align: center;
      .listPic{
        margin: 60px auto 0;
        width: 49.29%;
        padding-bottom: 40.14%;
        background-repeat: no-repeat;
        background-size: contain;
        &.num1{
          background-image: url('../../assets/image/Mobile/groupIntroduce/list_bg1.png');
        }
        &.num2{
          background-image: url('../../assets/image/Mobile/groupIntroduce/list_bg2.png');
        }
        &.num3{
          background-image: url('../../assets/image/Mobile/groupIntroduce/list_bg3.png');
        }
        &.num4{
          background-image: url('../../assets/image/Mobile/groupIntroduce/list_bg4.png');
        }
      }
      .listTitle{
        font-size: 42px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 80px;
      }
      .listDesc{
        font-size: 30px;
        font-weight: 400;
        color: #ACC7ED;
        line-height: 45px;
      }
    }
  }
}
</style>
