<template>
  <div>
      <!-- company_profile -->
      <div class="company_profile" v-if="data.name">
          <div :class="isMobile?'mobile_company_profile_main':'company_profile_main'">
                <div class="company_profile_l">
                    <h3 v-if="!isMobile">{{data.name}}</h3>
                    <h3 v-if="isMobile">公司简介</h3>
                    <div v-if="!isMobile"></div>
                    <p v-html="data.introduce" style="width：100%;"></p>
                    <!-- <p>陕西医智诺信息科技有限公司是香港上市公司华谊腾讯（HK00419）旗下的医疗集团，控股子公司。</p>
                    <p>医智诺目前拥有实体医院、互联网诊疗资质及医药／耗材全功能牌照、三级等保、CA认证等国家颁发的合法资质。自主研发了医学运营管理平台、专业化药房管理平台、学科建设运营平台、数字化营销平台、患者健康服务平台。医智诺凭借创新的技术能力和产品研发实力，致力于布局全产业医疗服务生态圈，搭建全场景化医疗服务平台，为健康中国助力。</p>
                    <p style=" margin-bottom: 0;">医智诺产品研发中心设立在陕西西安，目前已在全国建立七个运营中心，业务覆盖25个省份。公司将利用雄厚的资金、技术、资源实力，顺应国家医疗政策，打造新医药服务、新医疗服务、新健康服务的价值链。</p> -->
                    </div>
                <div class="company_profile_r">
                    <img :src="data.imgUrl" style="width: 450px; height:320px;">
                </div>
            </div>
        </div>
        <!-- company_revolutionaries -->
      <div :class="isMobile?'mobile_company_revolutionaries': 'company_revolutionaries'">
          <div class="company_revolutionaries_main">
              <div  :class="isMobile?'mobile-title': 'title'">
                    <h1>医疗产业的革命者</h1>
                    <p>重构医疗产业价值服务链</p>
                </div>
                <ul class="company_revolutionaries_main_ul">
                    <li>
                        <img src="../../../assets/image/company_icon1.png" />
                        <span>上市公司</span>
                    </li>
                    <li>
                        <img src="../../../assets/image/company_icon2.png" />
                        <span>合法合规</span>
                    </li>
                    <li class="li-bottom">
                        <img src="../../../assets/image/company_icon3.png" />
                        <span>全国布局</span>
                    </li>
                    <li class="li-bottom">
                        <img src="../../../assets/image/company_icon4.png" />
                        <span>技术创新</span>
                    </li>
                </ul>
            </div>
      </div>
       <div :class="isMobile?'mobile_company_data': 'company_data'" style="display: none;">
        <!-- <div style="width:100%;height:2px;background:#fff;opacity:0.2;position:absolute;left:0;top:51.3%;"></div> -->
          <div class="company_data_main">
              <div  class="title" style="color:#fff;">
                    <h1>医智诺大数据</h1>
                    <p>海量数据 无限可能</p>
                </div>
                <ul class="company_data_main_ul">
                    <li>
                        <img src="../../../assets/image/company_hospital.png" />
                        <div class="circls-lines">
                            <span class="circle"></span>
                            <span class="circl-line"></span>
                        </div>
                        <span>医院覆盖</span>
                        <div class="data_num">
                            {{data.hospitalNum}}
                            <span>+</span>
                            <div class="mark"></div>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/image/company_doctor.png" />
                         <div class="circls-lines">
                            <span class="circle"></span>
                            <span class="circl-line"></span>
                        </div>
                        <span>医生上线</span>
                        <div class="data_num">
                            {{data.doctorNum}}
                            <span>+</span>
                            <div class="mark"></div>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/image/company_patient.png" />
                         <div class="circls-lines">
                            <span class="circle"></span>
                            <span class="circl-line"></span>
                        </div>
                        <span>患者覆盖</span>
                        <div class="data_num">
                            {{data.patientNum}}
                            <span>+</span>
                            <div class="mark"></div>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/image/company_store.png" />
                         <div class="circls-lines">
                            <span class="circle"></span>
                            <span class="circl-line"></span>
                        </div>
                        <span>药房签约数</span>
                        <div class="data_num">
                            {{data.pharmacyNum}}
                            <span>+</span>
                            <div class="mark"></div>
                        </div>
                    </li>
                     <li>
                        <img src="../../../assets/image/company_drug.png" />
                         <div class="circls-lines">
                            <span class="circle"></span>
                            <span class="circl-line"></span>
                        </div>
                        <span>上线药品</span>
                        <div class="data_num">
                            {{data.drugNum}}
                            <span>+</span>
                            <div class="mark"></div>
                        </div>
                    </li>
                     <li>
                        <img src="../../../assets/image/company_team.png" />
                         <div class="circls-lines">
                            <span class="circle"></span>
                            <span class="circl-line"></span>
                        </div>
                        <span>销售团队</span>
                       <div class="data_num">
                            {{data.saleTeam}}
                            <span>+</span>
                            <div class="mark"></div>
                        </div>
                    </li>
                </ul>
            </div>
      </div>
      <div  class="company_qualification">
           <div class="company_ualification_main">
              <div  class="title">
                    <h1>合法资质</h1>
                    <p>高新技术企业、互联网药品信息服务企业</p>
                </div>
            </div>
            <img v-if="!isMobile" src="../../../assets/image/company_qualification.png"/>
            <!-- <img v-else width="100%" src="../../../assets/image/Mobile/businesLicense.png"/> -->
            <div v-if="isMobile" class="mobile-qulification">
                <div class="mobile-qulification-li">
                    <img src="../../../assets/image/Mobile/businesLicense.png">
                    <span>互联网医院</span>
                </div>
                 <div class="mobile-qulification-li">
                    <img src="../../../assets/image/Mobile/businesLicense1.png">
                    <span>高新技术企业</span>
                    
                </div>
                 <div class="mobile-qulification-li">
                    <img src="../../../assets/image/Mobile/businesLicese2.png">
                    <span>互联网药品信息服务资格证</span>
                    
                </div>
                 <div class="mobile-qulification-li">
                    <img src="../../../assets/image/Mobile/businesLicese3.png">
                    <span>信息安全三级等保</span>
                </div>
                 <div class="mobile-qulification-li">
                    <img src="../../../assets/image/Mobile/businesLicese4.png">
                    <span>公司营业执照</span>
                    
                </div>
                 <div class="mobile-qulification-li">
                    <img style="width:40%" src="../../../assets/image/Mobile/businesLicese5.png">
                    <span>CA数字证书认证</span>
                </div>

            </div>
            
      </div>
  </div>
</template>

<script>
import { isMobile } from '../../../../common/util'
import { selectIntroduce } from '../../../../common/api'
export default {
    data(){
        return{
            isMobile: isMobile(),
            data: {}
        }
    },
    mounted() {
        this.getCompanyInfo()
    },
    methods: {
        getCompanyInfo() {
            selectIntroduce({}).then((res) => {
                if (res.responseCode == 0) {
                    this.data = res.responseBody
                    this.data.introduce = this.data.introduce.replace(new RegExp('\r\n','gm'),'<br />').replace(new RegExp('\n','gm'),'<br />')
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>

//company_profil 
.company_profile{
    width: 100%;
    padding-top: 96px;
    padding-bottom:112px ;
    // background: #F6FAFF;
    .mobile_company_profile_main{
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        .company_profile_l{
            width: 100%;
            h3{
                font-size: 40px;
               text-align: center;
               padding: 20px 0;
            }
            p{
                color: #333333; 
                font-size: 32px;
                margin-bottom: 23px;
                padding:10px 30px;
                line-height: 48px;
            }
        }
        .company_profile_r{
            width: 100%;
            height: 100%;
            padding:0 30px;
            img{
                width: 100%;
                display: block;
            }
        }
    }
    .company_profile_main{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .company_profile_l{
            width: 704px;
            h3{
                font-size: 28px;
                padding: 18px 0;
                color: #343478;
            }
            div{
                width: 96px;
                height: 4px;
                background: #343478;
                margin-bottom: 33px;
            }
            p{
                color: #333333; 
                font-size: 18px;
                margin-bottom: 23px;
                line-height: 28px;
                // font-weight: bold;
            }
        }
        .company_profile_r{
            width: 450px;
            height: 100%;
            img{
                display: block;
                margin-top: 103px;
            }
        }
    }
    
}

// title
.title{
    color: #333333;
    text-align: center;
    h1{
        font-size: 45px;
        margin-bottom: 23px;
    }
    p{
        font-size: 18px;
        margin-bottom: 85px;
    }
}
.mobile-title{
    color: #333333;
    text-align: center;
    h1{
        font-size: 38px;
        margin-bottom: 23px;
    }
    p{
        font-size: 24px;
        margin-bottom: 85px;
    }
}
.mobile_company_revolutionaries{
    width: 100%;
    background: #fff;
    padding-top: 111px;
    padding-bottom: 114px;
    .company_revolutionaries_main{
        width: 100%;
        margin: 0 auto;
        .company_revolutionaries_main_ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            li{
                width: 45%;
                height: 285px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                color: #333333;
                font-size: 24px;
                box-shadow: 0 0 20px #eee;
                span{
                    margin-top:38px;
                }
            }
            .li-bottom{
                margin-top: 25px;
            }

        }
    }
}
// company_revolutionaries
.company_revolutionaries{
    width: 100%;
    background: #fff;
    padding-top: 111px;
    padding-bottom: 114px;
    .company_revolutionaries_main{
        width: 1200px;
        margin: 0 auto;
        .company_revolutionaries_main_ul{
            display: flex;
            justify-content: space-around;
            li{
                width: 285px;
                height: 285px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #333333;
                font-size: 24px;
                box-shadow: 0 0 20px #eee;
                span{
                    margin-top:38px;
                }
            }
        }
    }
}
// company_data
.mobile_company_data{
    width: 100%;
    background: #fff;
    padding-top: 111px;
    padding-bottom: 114px;
    position: relative;
    background: url('../../../assets/image/ecn-adv.png') no-repeat center center;
    background-size:100% 100% ;
    
    .company_data_main{
        width: 100%;
        margin: 0 auto;
        .company_data_main_ul{
            display: flex;
            flex-wrap:wrap ;
            li{
                width: 33%;
                height: 400px;
                text-align: center;
                .circls-lines{
                    width: 100%;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
                    height:16px;
                    margin: 10px 0;
                    position: relative;
                    .circle{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        width: 16px;
                        height: 16px;
                        background: #fff;
                        border-radius: 50%;
                    }
                    .circl-line{
                        width: 100%;
                        height: 4px;
                        background-color: #fff;
                        opacity: 0.2;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }
                }
                span{
                    display: block;
                    text-align: center;
                    color: #fff;
                    margin-bottom:38px;
                }
                .data_num{
                    width: 175px;
                    height: 175px;
                    margin: 0 auto;
                    text-align: center;
                    line-height: 175px;
                    font-size: 46px;
                    position: relative;
                    color: #fff;
                    .mark{
                        width: 100%;
                        height: 100%;
                        background:#fff;
                       filter:alpha(opacity=20);   
                        -moz-opacity:0.2;   
                        -khtml-opacity: 0.2;   
                        opacity: 0.2;   
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    span{
                        position: absolute;
                        top: -30px;
                        right: 17px;
                        width: 14px;
                        height: 14px;
                        font-size: 26px;
                        margin: 0;
                    }
                //    filter:alpha(opacity=20);   
                //     -moz-opacity:0.2;   
                //     -khtml-opacity: 0.2;   
                //     opacity: 0.2;   
                }
            }
        }
    }
}
.company_data{
    width: 100%;
    background: #fff;
    padding-top: 111px;
    padding-bottom: 114px;
    position: relative;
    background: url('../../../assets/image/ecn-adv.png') no-repeat center center;
    background-size:100% 100% ;
    
    .company_data_main{
        width: 1200px;
        margin: 0 auto;
        .company_data_main_ul{
            display: flex;
            justify-content: space-around;
            li{
                width: 175px;
                text-align: center;
                .circle{
                    width:16px;
                    height: 16px;
                    background: #fff;
                    margin: 31px auto;
                    border-radius: 50%;
                }
                span{
                    display: block;
                    text-align: center;
                    color: #fff;
                    margin-bottom:38px;
                }
                .data_num{
                    width: 175px;
                    height: 175px;
                    margin: 0 auto;
                    text-align: center;
                    line-height: 175px;
                    font-size: 46px;
                    position: relative;
                    color: #fff;
                    .mark{
                        width: 100%;
                        height: 100%;
                        background:#fff;
                       filter:alpha(opacity=20);   
                        -moz-opacity:0.2;   
                        -khtml-opacity: 0.2;   
                        opacity: 0.2;   
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    span{
                        position: absolute;
                        top: -30px;
                        right: 17px;
                        width: 14px;
                        height: 14px;
                        font-size: 26px;
                        margin: 0;
                    }
                //    filter:alpha(opacity=20);   
                //     -moz-opacity:0.2;   
                //     -khtml-opacity: 0.2;   
                //     opacity: 0.2;   
                }
            }
        }
    }
}
.mobile-qulification{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .mobile-qulification-li{
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        img{
            display: block;
            width: 80%;
        }
        span{
            width: 100%;
            display: block;
            height: 100px;
            line-height: 100px;
            font-size: 2rem;
            text-align: center;
        }
    }
}
// company_qualification
.company_qualification{
    padding-top:112px;
    text-align: center;
}
</style>
