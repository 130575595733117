import Vue from "vue";// 引入vue
import Router from "vue-router";// 引入vue-router
Vue.use(Router);// 使用路由
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// import home from "@/views/home/index"
import newHome from "@/views/new_home/index"
import product from "@/views/product/index"
import app from "@/views/app_dowload/index"
import coopSettlement from "@/views/coop_Settlement/index"
import aboutUs from "@/views/about_us/index"
import newsDetail from "@/views/newsDetail/news.vue"
import brand from "@/views/brand/index"
import recruitDetail from "@/views/about_us/components/RecruitDetail"
//引入新闻中心页面
import newsCenter from "@/views/newsCenter"
//引入新闻中心新闻的详情页面
import newsCenterDetail from "@/views/newsCenterDetail/index"
import newaboutus from "@/views/newaboutus/index"
import culture from "@/views/newaboutus/culture"
import contact from "@/views/newaboutus/contact"
// import newSolution from "@/views/newSolution/index"
import newProduct from "@/views/newProduct/index"
import newJoinus from "@/views/newJoinus/index"
import newJoinusSocialRecruit from "@/views/newJoinus/socialRecruit/index"
import positionInfo from "@/views/newJoinus/positionInfo/index"
import ecologicalSolution from "@/views/newSolution/ecologicalSolution"
import operationSolution from "@/views/newSolution/operationSolution"
import projectSolution from "@/views/newSolution/projectSolution"

//产品模块页面
import productCistanche from "@/views/product/productCistanche"
import projectApp from "@/views/product/projectApp"
import productPatient from "@/views/product/productPatient"
import productPharmacy from "@/views/product/productPharmacy"

import mobilehome from "@/views/mobile/home"
import mobileaboutUs from "@/views/mobile/groupIntroduce"
import mobileculture from "@/views/mobile/culture"
import mobileContact from "@/views/mobile/contact"
import developing from '@/views/mobile/developing'



//引入升级中页面
// import upGrading from "@/views/Upgrading/index"


const router = new Router({// 实例化router对象
    mode:"history",// 使用hash路由，带#号
    routes:[// 路由的配置项
        {
            path: '/',
            redirect: '/home'
        },// 首页组件
        {
            path:"/home",
            component: newHome
        },// 首页组件
        {
            path:"/product/:id",
            component:product
            // 产品组件
        },
        {
            path:"/app/:id",
            component:app
            // app下载组件
        },
        {
            path:"/coopSettlement/:id",
            component:coopSettlement
            // 合作入驻组件
        },
        {
            path:"/aboutUs/:id",
            component:aboutUs,
            // 合作入驻组件
        },
        {
            path:"/newsdetail/:id",
            component:newsDetail 
        },
        {
            path:"/brand",
            component:brand
        },
        {
            path:'/recruidetail',
            component:recruitDetail
        },
        {
            path:'/newsCenter',
            component:newsCenter
        },
        {
            path:'/newsCenterDetail/:id',
            component:newsCenterDetail
        },
        {
            path:'/product',
            component:productCistanche
        },
        {
            path:'/projectApp',
            component:projectApp
        },
        {
            path:'/productPatient',
            component:productPatient
        },
        {
            path:'/productPharmacy',
            component:productPharmacy
        },
        // {
        //     path:'/Solution',
        //     component:newSolution
        // },
        {
            path: '/aboutUs',
            component: newaboutus,
        },
        {
            path:'/culture',
            component: culture,
        },
        {
            path: '/contact',
            component: contact,
        },
        {
            path:'/joinUs',
            component: newJoinus
        },
        {
            path:'/socialRecruit',//社招
            component: newJoinusSocialRecruit
        },
        {
            path:'/positionInfo',//职位详情
            component: positionInfo
        },
        {
            path:'/ecologicalSolution',
            component:ecologicalSolution
        },
        {
            path:'/operationSolution',
            component:operationSolution
        },
        {
            path:'/projectSolution',
            component:projectSolution
        },

        {
            path:'/aboutUs_mobile',
            component: mobileaboutUs
        },
        {
            path:'/culture_mobile',
            component: mobileculture
        },
        {
            path:'/home_mobile',
            component: mobilehome
        },
        {
            path: '/contact_mobile',
            component: mobileContact
        },
        {
            path: '/developing_mobile',
            component: developing
        },
        
    ]
});
const isMobile = () => {
	if (document.documentElement.clientWidth / window.devicePixelRatio >= 640) {
		return false
	}
	// console.log(navigator.userAgent)
	if (navigator.userAgent.indexOf('Mobile')>-1) {
		// console.log('is Mobile')
		return true
	}
	if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
		// console.log('is Mobile')
		return true // 手机端
	} else {
		// console.log('is NOT Mobile') 
		return false // alert('PC端')
	}
}
router.beforeEach((to, from, next) => {
    if(isMobile()) {
        let pathArr = to.path.split('_')
        if(pathArr.length > 1) {
            next()
        } else {
            next(`${to.path}_mobile`)
        }
    } else {
        let pathArr = to.path.split('_')
        if(pathArr.length > 1) {
            next(pathArr[0])
        } else {
            next()
        }
    }
})
export default router;// 抛出路由