<template>
    <div class="ecn-slide">
        <carousel-3d :perspective="0" :border="0" :display="5" :width="420" :height="319">
            <slide v-for="(item, i) in slideList" :index="i" :key="item">
                <div class="ecn-slide-item">
                    <div class="ecn-slide-img">
                        <img :src="item" alt="" />
                    </div>
                </div>
                <div class="overeasy"></div>
            </slide>
        </carousel-3d>
    </div>
</template>

<script>
export default {
    name: "EcnSlide",
    data() {
        return {
            slideList: [
                require("../../assets/image/certificate/businessLicense-certificate.jpg"),
                // require("../../assets/image/certificate/licence-certificate.jpg"),
                // require("../../assets/image/certificate/drug-certificate.jpg"),
                // require("../../assets/image/certificate/information-certificate.jpg"),
                // require("../../assets/image/certificate/software-certificate.jpg"),
            ],

        }
    },
};
</script>
<style lang="scss" scoped>
.ecn-slide {
    padding-top: 134px;

    .carousel-3d-slide.current .overeasy {
        display: none;
    }

    .carousel-3d-container {
        width: 100% !important;
        height: 319px !important;
        margin: 0;

        .carousel-3d-slide {
            background: linear-gradient(135deg, #404040 0%, #121212 50%, #2f2f2f 100%);
            box-shadow: 0px 2px 40px 0px rgba(1, 14, 68, 0.69);
        }

        .ecn-slide-item {
            position: relative;
            background: linear-gradient(135deg, #404040 0%, #121212 50%, #2F2F2F 100%);
            box-shadow: 0px 2px 40px 0px rgba(1, 14, 68, 0.69);
            padding: 29px;

            .ecn-slide-img {
                box-shadow: 0px 2px 40px 0px rgba(1, 14, 68, 0.69);
                border: 1px solid;
                border-image: linear-gradient(136deg, rgba(230, 206, 176, 1), rgba(226, 198, 163, 1), rgba(195, 157, 120, 1)) 1 1;
                padding: 15px;

                img {
                    width: 333px;
                    height: 232px;
                }
            }
        }

    }


    .overeasy {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000;
        opacity: 0.3;
    }

    .carousel-3d-slide.current .overeasy {
        display: none;
    }

    .carousel-3d-slide.right-1 {
        transform: translateX(350px) translateZ(-275px) rotateY(0deg) !important;
    }

    .carousel-3d-slide.right-1 .overeasy {
        opacity: 0.3;
    }

    .carousel-3d-slide.left-1 {
        transform: translateX(-350px) translateZ(-275px) rotateY(0deg) !important;
    }

    .carousel-3d-slide.left-1 .overeasy {
        opacity: 0.3;
    }

    .carousel-3d-slide.right-2 {
        transform: translateX(820px) translateZ(-630px) rotateY(0deg) !important;
    }

    .carousel-3d-slide.right-2 .overeasy {
        opacity: 0.6;
    }

    .carousel-3d-slide.left-2 {
        transform: translateX(-820px) translateZ(-630px) rotateY(0deg) !important;
    }

    .carousel-3d-slide.left-2 .overeasy {
        opacity: 0.6;
    }
}
</style>
